import React from "react"
import { CsvToHtmlTable } from "react-csv-to-table"
import * as csv from "csv-string"
import { Modal, ModalHeader } from "reactstrap"
import "./preview.css"


const Preview = ({ data, modal, setModal }) => {
  const toggle = () => setModal(!modal)

  const fileDelimiter = csv.detect(data)
  if (data.startsWith(fileDelimiter)) {
    data = "_" + data
  }
  const CSVToArray =(strData, strDelimiter) => {
    strDelimiter = (strDelimiter || ",")
  
    let objPattern = new RegExp(
      (
        // Delimiters.
        "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
  
        // Quoted fields.
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
  
        // Standard fields.
        "([^\"\\" + strDelimiter + "\\r\\n]*))"
      ),
      "gi"
    )
  
    const arrData = [[]]
  
    let arrMatches = null
  
    while ((arrMatches = objPattern.exec(strData))) {
      let strMatchedDelimiter = arrMatches[1]
      if (
        strMatchedDelimiter.length &&
        strMatchedDelimiter !== strDelimiter
      ) {
        arrData.push([])
      }
  
      let strMatchedValue
      if (arrMatches[2]) {

        strMatchedValue = arrMatches[2].replace(
          new RegExp("\"\"", "g"),
          "\""
        )
      } else {
        strMatchedValue = arrMatches[3]
      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }
    return (arrData)
  }

  const refinedArray = CSVToArray(data, fileDelimiter)

  const newDelimiter = ",$&!"
  const stringArray = []
  refinedArray.forEach(element => {
    stringArray.push(element.join(newDelimiter))
  })
  const transformedData = stringArray.join("\n")

  return (
    <Modal className="preview-modal" isOpen={modal} toggle={toggle} scrollable>
      <ModalHeader toggle={toggle}>Preview</ModalHeader>
      <div className="preview">
        <CsvToHtmlTable 
          data={transformedData} 
          csvDelimiter={newDelimiter}
          tableClassName="table responsve table-striped table-hover"
        />
      </div>
    </Modal>
  )
}

export default Preview
