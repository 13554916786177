import { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import { useQuery } from "react-query"
import { queryClient } from "../utils/common"
import Users from "../api/UsersAPI"

export const getCurrentUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser({ bypassCache: true })
  } catch (error) {
    return null
  }
}

const useAuthStatus = () => {
  const [authorize, setAuthorize] = useState(false),
    [emailVerified, setEmailVerified] = useState(false),
    [loading, setLoading] = useState(true),
    { status, data, isFetching } = useQuery("useAuthStatus", getCurrentUser, {
      refetchOnWindowFocus: false
    })

  useEffect(() => {
    setLoading(true)
    if (!isFetching && status === "success") {
      if (data) {
        setAuthorize(true)
        if (data.attributes && data.attributes.email_verified) {
          setEmailVerified(true)
        }
      } else if (!data) {
        setAuthorize(false)
      }
      setLoading(false)
    }
  }, [data, status, isFetching])

  return [authorize, loading, emailVerified]
}

const signIn = async (email, password) => {
  const response = await Auth.signIn(email, password)
  return response
}

const signUp = async ({ fullName, email, password }) => {
  return await Auth.signUp({
    username: email,
    password,
    attributes: {
      name: fullName.trim(),
      email
    }
  })
}

const confirmSignUp = async (email, password, code) => {
  await Auth.confirmSignUp(email, code)
  const signInResponse = await Auth.signIn(email, password)
  await Users.create({
    full_name: signInResponse.attributes.name,
    email,
    auth_user_id: signInResponse.attributes.sub,
    type: "sign_up"
  })
  queryClient.invalidateQueries("useAuthStatus")
  return signInResponse
}

const signOut = async () => {
  await Auth.signOut()
  queryClient.invalidateQueries("useAuthStatus")
}

const updateUser = async name => {
  const user = await Auth.currentAuthenticatedUser()
  const res = await Auth.updateUserAttributes(user, { name: name })
  if (res === "SUCCESS") {
    await Users.update({ 
      full_name: name, 
      auth_user_id: user.attributes.sub
    })
  }
  return res
}

export { signUp, signIn, signOut, updateUser, useAuthStatus, confirmSignUp }
