import React from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import { NavLink as RouterNavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

const UnpaidModal = ({ modal }) => {
  return (
    <Modal className="modal-md modal-xs" isOpen={modal}>
      <ModalHeader>
        <FontAwesomeIcon className="text-center" icon={faExclamationCircle} size="lg" />
      </ModalHeader>
      <ModalBody className="text-center">
        <p>
          <b>WE ARE SORRY</b>
          <br />
          We are not able to process your payment due to card failure. Your account is deactivated for unpaid
          subscription.
        </p>

        <RouterNavLink to="/subscription" className="text-decoration-none">
          <Button color="primary" className="text-white ml-1 unpaid-delete-modal-button">
            Click here to activate your account
          </Button>
        </RouterNavLink>
      </ModalBody>
    </Modal>
  )
}

export default UnpaidModal
