import React, { useMemo } from "react"
import { UncontrolledAlert, Table, Col } from "reactstrap"
import { useQuery } from "react-query"
import { useStateValue } from "../../contexts/StateProvider"
import Invoice from "../../api/InvoiceAPI"
import SpinnerOverlay from "../ExtraComponents/SpinnerOverlay"
import "./subscription.css"


const filteredInvoice = (invoices) => {
  if (!invoices) {
    return []
  }

  return invoices.filter(invoice => invoice.total !== 0)
}

const InvoiceCard = () => {
  const { state } = useStateValue(),
    { user } = state,
    { status, data: invoiceData, error } = useQuery(
      ["invoice", user?.organization.stripe_customer_id],
      () => Invoice.query(user.organization.stripe_customer_id),
      {
        enabled: Boolean(user.organization.stripe_customer_id)
      }
    )
  
  const validInvoices = useMemo(() => filteredInvoice(invoiceData), [invoiceData])

  return (
    <Col className="px-2 px-sm-5 pb-5">
      <h3 className="font-avenir-bold pb-4">Payment History</h3>
      <Table responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {status === "success" &&
            validInvoices?.map(item => (
              <tr key={item.id}>
                <td>{item.created}</td>
                <td>$ {item.total.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                <td>{item.paid ? "Paid" : "Unpaid"}</td>
                <td>
                  <a
                    download
                    href={item.invoice_pdf}
                    className={`btn btn-primary btn-sm button subscribe-btn form-control d-flex align-items-center
                      justify-content-center font-weight-bold`}
                  >
                    Download PDF
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {status === "loading" && (
        <div className="d-flex justify-content-center align-items-center w-100 py-2 h-100">
          <SpinnerOverlay show />
        </div>
      )}
      {status === "error" && <UncontrolledAlert color="danger">{error.message}</UncontrolledAlert>}
      {status === "success" && validInvoices?.length === 10 ? (
        <p className="text-center">
          If you need more invoices, contact us at <a href="mailto:help@traindex.io">help@traindex.io</a>
        </p>
      ) : null}
    </Col>
  )
}

export default InvoiceCard
