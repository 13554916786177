import React, { useState } from "react"
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner
} from "reactstrap"
import { Link } from "react-router-dom"
import { signUp } from "../../utils/auth.utils"
import "./signUp.css"
import logo from "../../images/traindex-logo.png"
import { validatePassword } from "../../utils/common"
import VerifyEmail from "../VerifyEmail"

const SignUp = () => {
  const [formData, setFormData] = useState({
      fullName: "",
      email: "",
      password: "",
      confirmPassword: ""
    }),
    [loading, setLoading] = useState({
      signUp: false,
      resetPassword: false
    }),
    [isModalOpen, setIsModalOpen] = useState(false),
    [error, setError] = useState("")


  const handleSubmit = async event => {
    event.preventDefault()
    setLoading({ ...loading, signUp: true })

    try {
      const { email, password, fullName, confirmPassword } = formData
      if (password !== confirmPassword) {
        throw Error("Password and Confirmation password do not match.")
      }
      if (validatePassword(email, password, fullName.trim()) === "ok") {
        await signUp(formData)
        setLoading({ ...loading, signUp: false })
        setIsModalOpen(!isModalOpen)
      } else if (validatePassword(email, password, fullName.trim()) === "name") {
        setError("Password should not contain name")
        setLoading({ ...loading, signUp: false })
      } else if (validatePassword(email, password, fullName.trim()) === "email") {
        setError("Password should not contain your email")
        setLoading({ ...loading, signUp: false })
      }
    } catch (error) {
      setError(error.message)
      setLoading({ ...loading, signUp: false })
    }
  }

  const onChangeHandler = event => {
    const { name, value } = event.target
    return setFormData({ ...formData, [name]: value })
  }

  return (
    <div className="d-flex justify-content-center align-items-center full-height purple-background">
      <Card className="card-container p-2 m-1">
        <Link to="/" className="align-self-center mt-2 mb-3">
          <CardImg className="card_img" top src={logo} alt="Traindex logo" />
        </Link>
        <CardTitle className="signup-card-title font-weight-bold align-self-center">
          SIGN UP FOR A FREE DEMO
        </CardTitle>
        {error ? <CardSubtitle className="align-self-center mt-2 text-danger">{error}</CardSubtitle> : null}
        <CardBody className="signup-card-body align-self-center py-1 mx-5">
          <Form onSubmit={handleSubmit} className="d-flex flex-column">
            <FormGroup>
              <Label for="fullName" className="input-label font-weight-bold text-muted">
                FULL NAME
              </Label>
              <Input
                type="text"
                name="fullName"
                id="fullName"
                placeholder="Full name"
                onChange={onChangeHandler}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email" className="input-label font-weight-bold text-muted">
                EMAIL
              </Label>
              <Input type="email" name="email" id="email" placeholder="Email" onChange={onChangeHandler} required />
            </FormGroup>
            <FormGroup>
              <Label for="password" className="input-label font-weight-bold text-muted">
                PASSWORD
              </Label>
              <Input
                type="password"
                name="password"
                id="password"
                onChange={onChangeHandler}
                minLength="10"
                required
              />
              <FormText className="text-muted mb-1">Password must have at least 10 characters.</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="password" className="input-label font-weight-bold text-muted">
                CONFIRM PASSWORD
              </Label>
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                onChange={onChangeHandler}
                minLength="10"
                required
              />
            </FormGroup>
            <p className="privacy-notice text-center">
              We respect your privacy and will never sell, rent or share your personal information
              <Link to="/privacy" className="purple-color ml-1">
                <u>Privacy policy</u>
              </Link>
            </p>
            <Button type="submit" className="signup-btn d-flex font-weight-bold align-self-center mt-3">
              {loading.signUp ? <Spinner size="sm" className="signup-spinner m-1" /> : null}
              SIGN UP
            </Button>
            <p className="text-center mt-3">
              Have an account?
              <Link to="/sign-in" className="purple-color ml-1 font-weight-bold">
                Sign In
              </Link>
            </p>
          </Form>
        </CardBody>
      </Card>
      <VerifyEmail isModalOpen={isModalOpen} formData={formData} />
    </div>
  )
}

export default SignUp
