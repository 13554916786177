import React, { useEffect, useState } from "react"
import { HashLink as Link } from "react-router-hash-link"
import { NavLink } from "react-router-dom"
import logo from "../../images/trindex-final-logo-white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { Container, Row, Col, Button, Table, UncontrolledTooltip, Spinner } from "reactstrap"
import Footer from "../Footer"
import PriorArtApi from "../../api/PriorArtApi"
import "./priorart.css"

const PriorArt = ({ match }) => {
  const [data, setData] = useState(null),
    [error, setError] = useState(""),
    [loading, setLoading] = useState(false)

  const {
    params: { ucid },
  } = match

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const data = await PriorArtApi.getData(ucid)
        setData(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error.response && error.response.status === 404) {
          setError("Prior art report for this patent is not available.")
        } else if (error.response && error.response.status === 400) {
          setError("Bad request")
        } else {
          setError("Something went wrong")
        }
      }
    }
    fetchData()
  }, [ucid])

  const patentsDisplay =
    data &&
    data["prior_art_list"].map((item, index) => {
      let urlPath = item.ucid.replace(/-/g, "")
      const title = item.title && item.title.charAt(0).toUpperCase() + item.title.slice(1)
      const urlLink = `https://patents.google.com/patent/${urlPath}`
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.ucid}</td>
          <td>
            <a href={urlLink} target="_blank" rel="noopener noreferrer">
              {title || urlLink}
            </a>
          </td>
          <td>{item.conceptScore.toFixed(4)}</td>
        </tr>
      )
    })

  return (
    <div className="demo-body">
      <div className="demo-content pb-5">
        <Container className="header pt-4 pb-5" fluid>
          <Row noGutters>
            <Col className="ml-lg-5 mx-sm-0">
              <Link to="/">
                <img src={logo} alt="Traindex logo" />
              </Link>
            </Col>
            <Col className="text-right mr-lg-5 mx-sm-0">
              <NavLink to="/sign-in">
                <Button className="login-btn rounded-pill border-secondary" color="primary" title="Login">
                  Login
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
        <Container className="mx-auto">
          <h4 className="text-center mt-5">Prior Art report for patent {ucid}</h4>
          {loading && (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner size="lg" className="spinner" />
            </div>
          )}
          {data && (
            <Row>
              <Col>
                <Row>
                  <Col sm="12">
                    <Table responsive className="demo_table patent_info mt-3">
                      <tbody>
                        <tr>
                          <td colSpan="3">{data.title}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Assignee:</strong> {data.assignee}
                          </td>
                          <td>
                            <strong>Priority Date:</strong> {data.priority_date}
                          </td>
                          <td>
                            <strong>Grant date:</strong> {data.grant_date}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Container>
        <Container className="mx-auto">
          <Row>
            <Col>
              <Row>
                <Col sm="12">
                  <Table responsive className="demo_table mt-5">
                    <thead>
                      <tr style={{ backgroundColor: "#F2F2F2" }}>
                        <th style={{ width: "5%" }}>#</th>
                        <th style={{ width: "20%" }}>Patent UCID</th>
                        <th style={{ width: "55%" }}>Link</th>
                        <th style={{ width: "20%" }}>
                          Score <FontAwesomeIcon size="xs" icon={faQuestionCircle} id="status-tooltip-score" />
                          <UncontrolledTooltip placement="auto" target="status-tooltip-score">
                            Measures how similar the document with the searched patent on a scale of 0 - 1
                          </UncontrolledTooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{patentsDisplay}</tbody>
                  </Table>
                  {error && <p className="text-center">{error}</p>}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer className="demo-footer" />
    </div>
  )
}

export default PriorArt
