import React, { useEffect, useState } from "react"
import { Alert } from "reactstrap"
import { Link } from "react-router-dom"

const TrialAlert = ({ trial_end }) => {
  const [trialDays, setTrialDays] = useState("")
  useEffect(() => {
    const currentDate = new Date()
    const utcSeconds = trial_end
    const date = new Date(0)
    date.setUTCSeconds(utcSeconds)
    const trials = Math.ceil((date.getTime() - currentDate.getTime()) / (1000 * 3600 * 24))
    trials <= 5 && trials > 0 && setTrialDays(trials)
  }, [trial_end])
  return (
    <>
      {trialDays && (
        <Alert color="warning">
          You have {trialDays == 1 ? `${trialDays} day` : `${trialDays} days`} left on your trial.{" "}
          <Link to="/subscription" className="trial-alert">
            Buy Traindex and upgrade today.
          </Link>
        </Alert>
      )}
    </>
  )
}

export default TrialAlert
