import React from "react"
import "./sidebar.css"
import { MailOutline, Search, Payment } from "@material-ui/icons"
import { Link } from "react-router-dom"

export default function SideBar({ path }) {

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Admin Portal</h3>
          <ul className="sidebarList">
            <Link to="/admin/reminders" className="link">
              <li className={"sidebarListItem " + (path==="/admin/reminders" ? "active" : "") }>
                <MailOutline className="sidebarIcon" /> 
                Email Reminders
              </li>
            </Link>
            <Link to="/admin/pause_payment" className="link">
              <li className={"sidebarListItem " + (path==="/admin/pause_payment" ? "active" : "")} >
                <Payment className="sidebarIcon" /> 
                Pause Payment
              </li>
            </Link>
            <Link to="/admin/history" className="link">
              <li className={"sidebarListItem " + (path==="/admin/history" ? "active" : "")} >
                <Search className="sidebarIcon" /> 
                Search History
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  )
}
