import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import {
  Navbar,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavbarToggler
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { withRouter } from "react-router-dom"
import { signOut } from "../../../utils/auth.utils"
import { useStateValue } from "../../../contexts/StateProvider"
import "./topMenu.css"
import traindexLogo from "../../../images/traindex-logo.png"
import profileImage from "../../../images/default-profile-image.png"

const TopMenu = props => {
  const [collapsed, setCollapsed] = useState(true),
    { state, dispatch } = useStateValue(),
    { user } = state

  const toggleNavbar = () => setCollapsed(!collapsed)

  const redirectToSignIn = async () => {
    await signOut()
    return props.history.push("/sign-in")
  }

  const disableSearch = () => {
    dispatch({
      type: "SET_SEARCH_JOYRIDE",
      searchJoyride: false
    })
  }

  return (
    <Navbar className="py-0 px-0 navbar_container">
      <Nav className="full-width d-flex align-items-center">
        <div className="d-flex flex-grow-1 justify-content-center my-2">
          <NavbarBrand tag="span">
            <img alt="Traindex Logo" src={traindexLogo} height={30} />
          </NavbarBrand>
        </div>
        <NavbarToggler onClick={toggleNavbar} className="mr-2 d-block d-lg-none">
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
        <Collapse isOpen={!collapsed} navbar>
          <div className="d-flex flex-column flex-grow-1 justify-content-center">
            <NavLink className="nav-link mx-3" to="/index">
              Index
            </NavLink>
            <NavLink className="nav-link mx-3" to="/dataset">
              Dataset
            </NavLink>
            <NavLink className="nav-link mx-3" to="/search">
              Search
            </NavLink>
            <NavLink className="nav-link mx-3" to="/chatbot">
              Chatbot
            </NavLink>
          </div>
          <div className="d-flex flex-grow-1 justify-content-center">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav className="d-flex align-items-center">
                {user && user.full_name}
                <img src={profileImage} className="rounded mx-1" alt="user profile" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={NavLink} to="/profile" className="dropdown-item">
                  Profile
                </DropdownItem>
                <DropdownItem tag={NavLink} to="/subscription" className="dropdown-item">
                  Subscription
                </DropdownItem>
                <DropdownItem tag={NavLink} to="/settings" className="dropdown-item">
                  Settings
                </DropdownItem>
                <DropdownItem className="dropdown-item" onClick={redirectToSignIn}>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Collapse>
        <div className="d-none flex-grow-1 justify-content-center d-lg-flex">
          <NavLink className="nav-link mx-3" to="/index">
            Index
          </NavLink>
          <NavLink className="nav-link mx-3" to="/dataset">
            Dataset
          </NavLink>
          <NavLink className="nav-link mx-3" id="topmenu-search" to="/search" onClick={disableSearch}>
            Search
          </NavLink>
          <NavLink className="nav-link mx-3" to="/chatbot">
            Chatbot
          </NavLink>
        </div>
        <div className="d-none flex-grow-1 justify-content-center d-lg-flex">
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="d-flex align-items-center">
              {user && user.full_name}
              <img src={profileImage} className="rounded mx-1" alt="user profile" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={NavLink} to="/profile" className="dropdown-item">
                Profile
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/subscription" className="dropdown-item">
                Subscription
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/settings" className="dropdown-item">
                Settings
              </DropdownItem>
              <DropdownItem className="dropdown-item" onClick={redirectToSignIn}>
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Nav>
      <div className="navbar-underline" />
    </Navbar>
  )
}

export default withRouter(TopMenu)
