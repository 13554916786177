/* eslint-disable max-len */
import React from "react"
import { Container, Row, Col } from "reactstrap"

const PrivacyText = () => {
  return (
    <Container className="mt-5" fluid>
      <Row>
        <Col xs={{ size: 10, offset: 1 }}>
          <div className="text-justify privacy-rules">
            <p>
              Joinant, LLC. DBA Traindex, respects the privacy of visitors to its Websites. This Privacy Policy governs
              the manner in which Traindex collects, uses, maintains and discloses information collected from users
              (each, a &quot;User&quot;) of the traindex.io website (&quot;Website&quot;) and to any subdomains of this
              Website. This privacy policy applies to the Website and all products and services offered by Traindex.
            </p>
            <p>
              We recognize that visitors and users of this Website may want to know how we treat information collected
              on our Websites. This Privacy Policy, which may be updated from time to time, has been developed to
              address those questions.
            </p>
            <p>
              Your use of this Website indicates to us that you have read and accept our privacy practices, as outlined
              in this Privacy Policy. If you have any questions or concerns regarding this Privacy Statement, please
              contact us:
              <a href="https://www.traindex.io/#get-in-touch"> https://www.traindex.io/#get-in-touch </a>
            </p>
            <p>
              This Privacy Policy is in addition to any other legally binding agreement with Traindex or its affiliates
              that may govern your privacy or use of Traindex products and services. To the extent any such agreement is
              in writing and conflicts with this Privacy Policy, the terms of such written agreement will govern.
            </p>
            <p>
              In general, when Users visit our Website and access information, Users will remain anonymous. However, for
              some services provided through our Website, such as requesting a product trial or subscribing to email
              updates, we will ask Users to register using an online registration form and to share personal
              identification information.
            </p>
            <p>
              <span className="font-weight-bold">1. Personal Identification Information. </span>
              We may collect personal identification information from Users in a variety of ways, including contact
              details and usage metrics such as: when Users visit our Website, respond to a survey, fill out a form,
              request updates or alerts and in connection with other activities, services, features or resources we make
              available on our Website. Users may inquire as to the source of any information that we collect. Users may
              be asked for, as appropriate, name, email address, mailing address, phone number and other general contact
              information. Users may, however, visit our Website anonymously. We will collect personal identification
              information from Users only if they voluntarily submit such information to us. Users can always refuse to
              supply personally identification information, except that it may prevent them from engaging in certain
              Website-related activities. We will retain personal information for as long as it serves the purposes for
              which the information was collected and for longer periods of time only if required by law or regulation.
            </p>
            <p>
              <span className="font-weight-bold">2. Right of Data Access. </span>
              Users shall have the right to obtain from Traindex confirmation as to whether or not personal data is
              being processed, and, where that is the case, access to the personal data and the following information:
              <ol className=" pl-0">
                <li className="font-weight-normal ml-4">the purposes of related data processing</li>
                <li className="font-weight-normal ml-4">the categories of personal data concerned</li>
                <li className="font-weight-normal ml-4">
                  the recipients or categories of recipient to whom the personal data have been or will be disclosed, in
                  particular recipients in third countries or international organizations
                </li>
                <li className="font-weight-normal ml-4">
                  where possible, the envisaged period for which the personal data will be stored, or, if not possible,
                  the criteria used to determine that period
                </li>
                <li className="font-weight-normal ml-4">
                  the existence of the right to request from Traindex rectification or erasure of personal data or
                  restriction of processing of personal data concerning the data subject or to object to such processing
                </li>
                <li className="font-weight-normal ml-4">the right to lodge a complaint with a supervisory authority</li>
                <li className="font-weight-normal ml-4">
                  where the personal data are not collected from the data subject, any available information as to their
                  source
                </li>
                <li className="font-weight-normal ml-4">
                  the existence of automated decision-making, including profiling and meaningful information about the
                  logic involved, as well as the significance and the envisaged consequences of such processing for the
                  data subject.
                </li>
              </ol>
            </p>
            <p>
              <span className="font-weight-bold">3. Additional Data Rights. </span>
              <ol className=" pl-0">
                <li className="font-weight-normal ml-4">
                  Users shall have the right to obtain from Traindex, without undue delay, the rectification of
                  inaccurate personal data concerning the User.
                </li>
                <li className="font-weight-normal ml-4">
                  Users shall have the right to be forgotten. Users shall have the right to obtain from Traindex the
                  erasure of personal data without undue delay and Traindex shall have the obligation to erase personal
                  data without undue delay when the personal data is no longer necessary in relation to the purposes for
                  which it was collected or otherwise processed, or the User withdraws consent for data processing and
                  there is no other legal ground for doing so.
                </li>
                <li className="font-weight-normal ml-4">
                  User has the right to data portability. Users shall have the right to receive their personal data in a
                  structured, commonly used and machine-readable format and have the right to transmit this data to
                  another data controller without hindrance.
                </li>
              </ol>
            </p>
            <p>
              <span className="font-weight-bold">4. Timely and Transparent Compliance with Data Requests. </span>
              Traindex shall take appropriate measures to provide information related to data processing in a concise,
              transparent, intelligible and easily accessible form, using clear and plain language Traindex shall
              provide information on action taken on a request under the above Data Rights to Users without undue delay
              and in any event within one month of receipt of the request. That period may be extended by two further
              months where necessary, taking into account the complexity and number of the requests. Traindex shall
              inform the data subject of any such extension within one month of receipt of the request, together with
              the reasons for the delay. Where the data subject makes the request by electronic form means, the
              information shall be provided by electronic means where possible, unless otherwise requested by the data
              subject.
            </p>
            <p>
              <span className="font-weight-bold">5. Non-Personal Identification Information. </span>
              We may collect non-personal identification information about Users whenever they interact with our
              Website. Non-personal identification information may include the browser name, the type of computer and
              technical information about Users’ means of connecting to our Website, such as the operating system and
              the Internet service provider(s) utilized and other similar information.
            </p>
            <p>
              While accessing and operating a Traindex product, we may also record general statistical and tracking
              information, such as the date and time a User accesses his or her product and the time spent using it,
              etc., as well as aggregated, anonymous usage stats.
            </p>
            <p>
              <span className="font-weight-bold">6. Third Party Services. </span>
              We may use services hosted by third parties such as Google Analytics to help us understand how use our
              Website is being used. These services may collect information sent by a browser as part of a web page
              request, including IP addresses or cookies. If these third party services collect information, they do so
              anonymously and in the aggregate to provide information helpful to us such as website trends, without
              identifying individual visitors.
            </p>
            <p>
              <span className="font-weight-bold">7. Website Browser Cookies. </span>
              Our Website may use &quot;cookies&quot; to enhance User experience. User&apos;s web browser places cookies
              on their hard drive for record-keeping purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies, or to be alerted when cookies are being sent. If they
              do so, note that some parts of the Website may not function properly.
            </p>
            <p>
              <span className="font-weight-bold">8. How We Use Collected Information. </span>
              Traindex may collect and use Users personal information to respond to your customer service requests, to
              improve our Website by using feedback you provide to improve our products and services, to run a
              promotion, contest, survey or other Website feature, and to send periodic emails regarding updates
              pertaining to their product or services. It may also be used to respond to their inquiries, questions,
              and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may
              include company news, updates, related product or service information, etc. If at any time the User would
              like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the
              bottom of each email or User may contact us via our Website. We will not process information in a way that
              is incompatible with the purposes for which the information was collected and authorized or subsequently
              authorized by the User.
            </p>
            <p>
              <span className="font-weight-bold">9. How We Protect Your Information. </span>
              We apply data collection, storage and processing practices and security measures to protect against
              unauthorized access, alteration, disclosure or destruction of User’s personal information, username,
              password, transaction information and data stored on our Website.
            </p>
            <p>
              <span className="font-weight-bold">10. Access to Your Information. </span>
              Users may request a copy of any information they have provided to us and may request to correct any
              information that is inaccurate. Unless prohibited by applicable law, we reserve the right to charge a
              reasonable fee to cover costs for providing copies of information requested by Users.<br></br> Users may
              also request to have their information restricted from some or all of our uses or have information erased
              from our database. Please note that such requests may prevent them from engaging in certain
              Website-related activities. Prior to processing such requests, we may require that the User provide us
              with additional documentation to accredit their identity.
            </p>
            <p>
              <span className="font-weight-bold">11. Sharing Your Personal Information. </span>
              We do not sell, trade, or rent User’s personal identification information to others. We will not share
              User’s personal identification information with unrelated third parties not covered by this privacy policy
              without the User’s permission except to the extent reasonably necessary to comply with laws or to respond
              to judicial process.
            </p>
            <p>
              <span className="font-weight-bold">12. Third Party Websites / Links. </span>
              Users may find content on our Website that links to the websites and services of our partners, suppliers,
              advertisers, sponsors, licensors and other third parties. We do not control the content or links that
              appear on these websites and are not responsible for the practices employed by websites linked to or from
              our Website. In addition, these websites or services, including their content and links, may be constantly
              changing. These websites and services may have their own privacy policies and customer service policies.
              Browsing and interaction on any other website, including websites which have a link to our Website, is
              subject to that website&apos;s own terms and policies. Traindex is not responsible for the privacy
              practices of any third party websites.
            </p>
            <p>
              <span className="font-weight-bold">
                13. Compliance with Children&apos;s Online Privacy Protection Act.{" "}
              </span>
              Protecting the privacy of the very young is especially important. For that reason, we do not collect or
              maintain information on our Website from those we actually know are under the age of 13, nor is any part
              of our Website targeted to attract anyone under 13. We request that all visitors to our Website who are
              under 13 not disclose or provide any personally identifiable information. If we discover that a child
              under 13 has provided us with personally identifiable information, we will delete that child&apos;s
              personally identifiable information from our records.
            </p>
            <p>
              <span className="font-weight-bold">14. Data Security. </span>
              We take reasonable steps to maintain the security of the personally identifiable information that we
              collect, including limiting the number of people who have physical access to our database servers, as well
              as installing electronic security systems that guard against unauthorized access. However, no data
              transmission over the Internet can be guaranteed to be completely secure. Accordingly, we cannot ensure or
              warrant the security of any information that you transmit to us, so you do so at your own risk.
            </p>

            <p>
              <span className="font-weight-bold">15. Consent to Transfer. </span>
              This Website is operated in the United States. If you are located outside of the United States, please be
              aware that any information you provide to us will be transferred to the United States and/or third
              countries. By using our Website, participating in any of our services and/or providing us with your
              information, you consent to this transfer.
            </p>
            <p>
              We treat all information in strict confidence. We will not sell your personally identifiable information
              to any company or organization except we may transfer your personally identifiable information to a
              successor entity upon a merger, consolidation or other corporate reorganization in which Traindex
              participates, or to a purchaser of all or substantially all of Traindex’s assets. Such successor entity
              will be bound by terms and conditions substantially similar to those of this Privacy Policy.
            </p>

            <p>
              <span className="font-weight-bold">16. Changes to this Privacy Policy. </span>
              Traindex has the discretion to update this privacy policy at any time. When we do, we will revise the
              updated date at the bottom of this page. We encourage Users to frequently check this page for any changes
              to stay informed about how we are helping to protect the personal information we collect. You acknowledge
              and agree that it is your responsibility to review this privacy policy periodically and become aware of
              modifications.
            </p>
            <p>
              <span className="font-weight-bold">17. Your Acceptance of These Terms. </span>
              By using this Website, you signify your acceptance of this policy. If you do not agree to this policy,
              please do not use our Website. Your continued use of the Website following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>

            <p>
              <span className="font-weight-bold">Contacting Us. </span>
              If you have any questions about this Privacy Policy, the practices of this site, requests regarding your
              information, or your dealings with this site, please contact us at:
            </p>

            <p>
              Joinant, LLC.<br></br>
              16301 NE 8th St<br></br>
              Suite 208<br></br>
              Bellevue, WA 98008
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PrivacyText
