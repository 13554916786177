import React, { useState } from "react"
import { Container } from "reactstrap"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import "./swagger-custom.css"

const WikiSwaggerAPI = () => {
  const [alert, setAlert] = useState(false)

  const onChangeHandler = event => {
    var target = event.target || event.srcElement || event.originalTarget

    if (target.innerText == "Try it out") {
      if (document.getElementsByClassName("unlocked").length == 2) {
        setAlert(true)
        window.scrollTo(0, 0)
        var buttons = document.querySelectorAll("button")
        for (var i = 0, l = buttons.length; i < l; i++) {
          if (buttons[i].innerText == "Try it out") {
            buttons[i].click()
          }
        }
      }
    }
  }

  return (
    <Container onClick={onChangeHandler}>
      {alert && (
        <div className="alert alert-danger mt-5 text-center">
          Please authorize your request first by adding the API key
        </div>
      )}
      <SwaggerUI docExpansion="full" url="/wiki_swagger.json" />
    </Container>
  )
}

export default WikiSwaggerAPI
