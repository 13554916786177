import client from "./APIClient"

export default class ApiSettings {
  static all = organizationId => {
    if (organizationId) {
      return client(`index?org_id=${organizationId}`)
    }
  }

  static addKey = body => {
    return client("index", { method: "POST", body })
  }

  static removeKey = body => {
    return client("index/delete", { method: "POST", body })
  }
}
