import React, { useState } from "react"
import { Button, Form, FormFeedback, Input, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import Users from "../../api/UsersAPI"
import "./organizationModal.css"


const OrganizationModal = ({ user, modal, setModal, getUserInfo }) => {
  const [orgName, setOrgName] = useState(""),
    [loading, setLoading] = useState(false),
    [error, setError] = useState("")

  const handleChange = ({ target: { value } }) => {
    setOrgName(value)
    if (value.length > 3) {
      setError("")
    } else {
      setError("Please enter the organization name with at least 4 characters.")
    }
  }

  const onCreateOrg = async (e) => {
    e.preventDefault()
    if (orgName.length > 3) {
      try {
        setLoading(true)
        await Users.update({
          email: user?.attributes.email,
          auth_user_id: user?.username,
          orgName: orgName
        })
      } catch (error) {
        setLoading(false)
        setError(error.message)
      } finally {
        await getUserInfo()
        setLoading(false)
        setModal(false)
      }
    } else {
      setError("Please enter the organization name with at least 4 characters.")
    }
  }

  return (
    <Modal className="modal-md modal-xs" isOpen={modal}>
      <ModalHeader>
        <FontAwesomeIcon className="text-center" icon={faExclamationCircle} size="lg" />
      </ModalHeader>
      <ModalBody className="text-center">
        <p>
          <b>WE ARE SORRY</b>
          <br />
          Your account does not belong to any organization.
          Please create a new organization to use Traindex.
        </p>

        <Form onSubmit={onCreateOrg}>
          <Input
            type="text"
            placeholder="Your organization name"
            name="orgName"
            value={orgName}
            onChange={handleChange}
            className="modal-input mr-2"
            invalid={orgName.length < 4}
          />
          <FormFeedback className="text-left">{error}</FormFeedback>
          <Button 
            color="primary" 
            className="text-white mt-3 mx-auto"
            disabled={loading}
          >
            Create Organization
          </Button>
        </Form>
        {loading && (
          <div className="mt-2 d-flex align-items-center justify-content-center">
            <Spinner size="lg" className="spinner" />
          </div>)}
      </ModalBody>
    </Modal>
  )
}

export default OrganizationModal
