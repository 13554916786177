import React, { useState } from "react"
import { useMutation } from "react-query"
import { queryClient } from "../../utils/common"
import { Button, Input, InputGroup, UncontrolledAlert } from "reactstrap"
import Subscription from "../../api/SubscriptionApi"
import "./coupon.css"

const Coupon = ({ stripe_subscription_id, couponCode, setCouponCode, showApplyBtn, getUserInfo }) => {
  const [openCouponField, setOpenCouponField] = useState(false)

  const linkBtnHandler = () => {
    setOpenCouponField((prev) => !prev)
  }

  const onChangeCouponField = (event) => {
    setCouponCode(event.target.value)
  }

  const applyCouponAPI = () => {
    return Subscription.applyCoupon({
      stripe_subscription_id,
      couponCode
    })
  }

  const {mutate: applyCoupon, error, data } = useMutation(applyCouponAPI, {
    onSuccess: () => {
      ["subscription-status", "invoice", "subscription"].forEach((queryKey) =>
        queryClient.invalidateQueries(queryKey)
      )
      getUserInfo()
    }
  })

  return (
    <div className="coupon-container">
      {openCouponField ? (
        <>
          <InputGroup>
            <Input
              type="text"
              placeholder="Enter Coupon"
              className="coupon-field"
              value={couponCode}
              onChange={onChangeCouponField}
            />
            {showApplyBtn && (
              <Button className="text-white subscribe-btn" onClick={applyCoupon} disabled={!couponCode}>
                Apply
              </Button>
            )}
          </InputGroup>
          {error && error.message && (
            <UncontrolledAlert color="danger" className="mt-3" fade={false}>
              {error.message}
            </UncontrolledAlert>
          )}
          {data && data.message && (
            <UncontrolledAlert color="success" className="mt-3" fade={false}>
              {data.message}
            </UncontrolledAlert>
          )}
        </>
      ) : (
        <Button className="p-0 coupon-link-btn" color="link" size="sm" onClick={linkBtnHandler}>
          Have a coupon?
        </Button>
      )}
    </div>
  )
}

export default Coupon