import React, { useState, useEffect } from "react"
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Alert,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import "./emailReminders.scss"

export default function FormComponent({
  param_name,
  param_duration,
  param_unit,
  param_file,
  param_subject,
  index,
  onSubmitHandler,
}) {
  const [unit, setUnit] = useState(param_unit),
    [duration, setDuration] = useState(param_duration),
    [timeDifference, setTimeDifference] = useState(""),
    [loading, setLoading] = useState(false),
    [successMessage, setSuccessMessage] = useState(""),
    [errorMessage, setErrorMessage] = useState(""),
    [dropdownOpen, setDropDownOpen] = useState(false)

  useEffect(() => {
    if (unit !== undefined) {
      let d = new Date()
      if (unit === "minutes") d.setMinutes(d.getMinutes() - parseInt(duration))
      if (unit === "hours") d.setHours(d.getHours() - parseInt(duration))
      if (unit === "days") d.setDate(d.getDate() - parseInt(duration))
      if (unit === "weeks") d.setDate(d.getDate() - parseInt(duration) * 7)
      setTimeDifference(d.toString())
    }
  }, [unit, duration])

  const onChangeHandler = (e) => {
    const { target } = e
    setSuccessMessage("")
    setErrorMessage("")
    if (target.name === "duration") setDuration(target.value)
    if (target.name === "unit") setUnit(target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setSuccessMessage("")
    setErrorMessage("")

    const { error } = await onSubmitHandler(index, param_name, unit, duration, param_file, param_subject)
    if (!error) {
      setSuccessMessage("Parameter updated successfully!")
    }
    if (error) setErrorMessage("Unable to process request!")
    setLoading(false)
  }

  return (
    <Form className="col-md-8 p-0" onSubmit={onSubmit}>
      {successMessage.length > 0 && <Alert color="success">{successMessage}</Alert>}
      {errorMessage.length > 0 && <Alert color="danger">{errorMessage}</Alert>}
      <FormGroup className="profile-form-group">
        <legend>Name</legend>
        <Input type="text" name="name" id="name" value={param_name} disabled />
      </FormGroup>
      <FormGroup className="profile-form-group">
        <legend>File</legend>
        <Input type="text" name="file" id="file" value={param_file} disabled />
      </FormGroup>
      <FormGroup className="profile-form-group">
        <legend>Subject</legend>
        <Input type="text" name="subject" id="subject" value={param_subject} disabled />
      </FormGroup>
      <div className="mb-4">
        <InputGroup>
          <legend>Repeat every</legend>
          <Input
            type="number"
            name="duration"
            id="duration"
            value={duration || ""}
            onChange={onChangeHandler}
            min="0"
            className="input"
          />
          <InputGroupButtonDropdown
            isOpen={dropdownOpen}
            toggle={() => {
              setDropDownOpen(!dropdownOpen)
            }}
            addonType="append"
          >
            <DropdownToggle caret>{unit}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Units</DropdownItem>
              <DropdownItem disabled={unit === "minutes"} onClick={onChangeHandler} name="unit" value="minutes">
                Minutes
              </DropdownItem>
              <DropdownItem disabled={unit === "hours"} onClick={onChangeHandler} name="unit" value="hours">
                Hours
              </DropdownItem>
              <DropdownItem disabled={unit === "days"} onClick={onChangeHandler} name="unit" value="days">
                Days
              </DropdownItem>
              <DropdownItem disabled={unit === "weeks"} onClick={onChangeHandler} name="unit" value="weeks">
                Weeks
              </DropdownItem>
            </DropdownMenu>
          </InputGroupButtonDropdown>
        </InputGroup>
      </div>
      <p>
        This email will be sent to the users if they don&apos;t make any search after{" "}
        <span style={{ fontWeight: "bold" }}>{timeDifference}</span> day(s) whenever the next cronjob runs.
      </p>
      <Button type="submit" disabled={loading} className="mb-5">
        Save
      </Button>
    </Form>
  )
}
