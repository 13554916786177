import client from "./APIClient"

export default class OrganizationsAPI {
  static get = async organizationId => {
    return client(`organizations?org_id=${organizationId}`)
  }

  static list = async userId => {
    return client(`organizations?auth_user_id=${userId}`)
  }

  static create = formData => {
    return client("organizations", { method: "POST", body: formData })
  }
  static update = params => {
    return client("organizations", { method: "PUT", body: params })
  }
}
