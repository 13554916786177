import React, { useState, useEffect } from "react"
import { useStateValue } from "../../contexts/StateProvider"
import { Container } from "reactstrap"
import FormComponent from "./FormComponent"
import { update_parameters } from "../../utils/admin.utils"
import { useHistory, useLocation } from "react-router-dom"
import SideBar from "../SideBar"

export default function EmailReminders() {
  const {
    state: { user },
  } = useStateValue()
  const [forethetaAdmin, setForethetaAdmin] = useState(false),
    history = useHistory()

  useEffect(() => {
    if (user) {
      if (user.foretheta_admin) {
        setForethetaAdmin(user.foretheta_admin)
      } else {
        history.push("/index")
      }
    }
  }, [user])

  const onSubmitHandler = async (index, name, unit, duration, file, subject) => {
    forethetaAdmin[index] = {
      name,
      unit,
      duration,
      file,
      subject,
    }
    setForethetaAdmin(forethetaAdmin)
    const { response, error } = await update_parameters(forethetaAdmin)
    return { response, error }
  }

  function findPath() {
    const location = useLocation()
  
    return location.pathname
  }

  let path = findPath()

  const Reminders = forethetaAdmin && 
    forethetaAdmin.map((index, key) => (
      <FormComponent
        param_name={index["name"]}
        param_duration={index["duration"]}
        param_unit={index["unit"]}
        param_file={index["file"]}
        param_subject={index["subject"]}
        index={key}
        key={key}
        onSubmitHandler={onSubmitHandler}
      />
    ))

  return (
    <Container className="reminder-container mt-3">
      <SideBar path={path} />
      <div className="reminders ml-4">
        <h3 className="mb-3">Email Reminders</h3>
        {Reminders}
      </div>
    </Container>
  )
}
