import React, { useState, useEffect } from "react"
import { 
  Form, 
  FormGroup, 
  Label, 
  Input, 
  UncontrolledAlert,
  Spinner, 
  Container 
} from "reactstrap"
import { updateUser } from "../../utils/auth.utils"
import UpdatePassword from "../UpdatePassword"
import { Button } from "../Reactstrap_Components/Button"
import { useStateValue } from "../../contexts/StateProvider"
import "./profile.css"

const Profile = () => {
  const { state, dispatch } = useStateValue(),
    { user, error } = state,
    [loading, setLoading] = useState(false),
    [saveError, setSaveError] = useState(""),
    [saveStatus, setSaveStatus] = useState("idle"),
    [userName, setUserName] = useState("")

  useEffect(() => {
    setUserName((user && user.full_name) || "")
  }, [user])

  const onUpdateUser = async event => {
    event.preventDefault()
    try {
      setLoading(true)
      saveError && setSaveError("")
      setSaveStatus("pending")
      await updateUser(userName).then(res => {
        setSaveStatus(res)
        setLoading(false)
        setTimeout(() => {
          setSaveStatus("idle")
        }, 3000)
        dispatch({
          type: "SET_USER_FULLNAME",
          fullName: userName
        })
      })
    } catch (error) {
      setLoading(false)
      setSaveError("Something went wrong. Please try again later.")
    }
  }

  return (
    <Container className="profile-container mt-3">
      {user && (
        <>
          <h3 className="mb-3 font-avenir-bold">Profile</h3>
          <Form className="col-md-6 p-0" onSubmit={onUpdateUser}>
            {error && <UncontrolledAlert color="danger">{error}</UncontrolledAlert>}
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner size="lg" className="spinner" />
              </div>
            )}
            {saveStatus === "SUCCESS" && <UncontrolledAlert color="success">Successfully updated!</UncontrolledAlert>}
            <FormGroup>
              <Label className="font-weight-bold" for="full_name">
                Full Name
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={userName || ""}
                onChange={e => setUserName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="font-weight-bold" for="email">
                Email
              </Label>
              <Input disabled type="email" name="email" id="email" defaultValue={user.email} />
            </FormGroup>
            <Button 
              disabled={saveStatus === "pending" || userName.length === 0 || userName === user?.full_name} 
              className="profile-btn" type="submit"
            >
              Update profile
            </Button>
          </Form>
          <UpdatePassword />
        </>
      )}
    </Container>
  )
}

export default Profile
