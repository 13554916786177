/* eslint-disable no-extra-semi */
import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Alert,
} from "reactstrap"
import moment from "moment"
import { useQuery, useMutation } from "react-query"
import Select from "react-select"
import Coupon from "./Coupon"
import { useStateValue } from "../../contexts/StateProvider"
import Subscription from "../../api/SubscriptionApi"
import Emails from "../../api/EmailsAPI"
import SpinnerOverlay from "../ExtraComponents/SpinnerOverlay"
import TrialAlert from "../TrialAlert"
import { queryClient } from "../../utils/common"
import "./subscription.css"

const formatDate = (timestamp) => {
  return moment.unix(timestamp).format("ll")
}

const currentDate = moment().unix()

const BillingCard = () => {
  const { state, getUserInfo } = useStateValue(),
    { user } = state,
    [modal, setModal] = useState(false),
    [modalError, setModalError] = useState(""),
    [selected, setSelected] = useState(""),
    [opinion, setOpinion] = useState(""),
    [couponCode, setCouponCode] = useState(""),
    [subscriptionPlan, setSubscriptionPlan] = useState(null),
    [updateDisable, setUpdateDisable] = useState(true),
    [confirmSubscriptionPlanModal, setConfirmSubscriptionPlanModal] = useState(false),
    [confirmCallbackName, setConfirmCallbackName] = useState(""),
    { status, data: subscriptionData, error } = useQuery(
      ["subscription", user.stripe_subscription_id],
      () => Subscription.get(user.stripe_subscription_id),
      { enabled: Boolean(user.stripe_subscription_id) }
    ),
    mutateQuery = (data) => {
      ;["subscription-status", "invoice", "subscription"].forEach((queryKey) => queryClient.invalidateQueries(queryKey))
      return queryClient.setQueryData(["subscription", user.stripe_subscription_id], data)
    },
    { mutate: cancelSubscription, status: cancelSubscriptionStatus, error: cancelSubscriptionError } = useMutation(
      Subscription.delete,
      {
        onSuccess: mutateQuery,
      }
    ),
    { mutate: updateSubscription, status: updateSubscriptionStatus, error: updateSubscriptionError } = useMutation(
      Subscription.update,
      {
        onSuccess: mutateQuery,
      }
    ),
    {
      mutate: activateAndApplyCoupon,
      status: activateAndApplyCouponStatus,
      error: activateAndApplyCouponError,
    } = useMutation(Subscription.activateAndApplyCoupon, {
      onSuccess: mutateQuery,
    })

  const isCancelAtPeriodEnd = subscriptionData && subscriptionData.cancel_at_period_end
  const isPaused = subscriptionData && subscriptionData.pause_collection 
    && subscriptionData.pause_collection["behavior"] === "mark_uncollectible"
  const subscriptionStatus =
    subscriptionData &&
    (isCancelAtPeriodEnd ? "canceled" :isPaused ? "Active" : subscriptionData.status === "past_due" ? "Past due"
      : subscriptionData.status)
  const isTrialing = subscriptionStatus === "trialing"
  const isCanceled = subscriptionStatus === "canceled"
  const isDue = subscriptionStatus === "Past due"
  const isLoading = status === "loading"
  const isUpdating =
    cancelSubscriptionStatus === "loading" ||
    updateSubscriptionStatus === "loading" ||
    activateAndApplyCouponStatus === "loading"
  const isError =
    status === "error" ||
    cancelSubscriptionStatus === "error" ||
    updateSubscriptionStatus === "error" ||
    activateAndApplyCouponStatus === "error" ||
    !user.stripe_subscription_id
  const errorMessage =
    (error && error.message) ||
    (cancelSubscriptionError && cancelSubscriptionError.message) ||
    (updateSubscriptionError && updateSubscriptionError.message) ||
    (activateAndApplyCouponError && activateAndApplyCouponError.message) ||
    (!user.stripe_subscription_id && "No subscription found for this account.")
  const percentOff = subscriptionData?.discount?.coupon?.percent_off || 0
  const monthlyPrice = parseInt(process.env.REACT_APP_MONTHLY_PRICE)
  const annualPrice = parseInt(process.env.REACT_APP_ANNUAL_PRICE)
  const actualMonthlyPrice = Math.round(monthlyPrice * (1 - percentOff / 100))
  const actualAnnualPrice = Math.round(annualPrice * (1 - percentOff / 100))
  const formattedMonthlyPrice = `$${actualMonthlyPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })}`
  const formattedAnnualPricePerMonth = `$${(actualAnnualPrice / 12).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })}`
  const subscriptionOptions = [
    {
      label: `Monthly (${formattedMonthlyPrice}/month)`,
      value: "month",
    },
    {
      label: `Annual (${formattedAnnualPricePerMonth}/month)`,
      value: "year",
    },
  ]
  const pastDays = Math.ceil((currentDate - subscriptionData?.current_period_start)/(24*60*60)) || 0
  const leftDays = Number(process.env.REACT_APP_STRIPE_RETRY_DAYS) - pastDays

  useEffect(() => {
    const amount = subscriptionData?.plan.amount || 0
    const percentOff = subscriptionData?.discount?.coupon?.percent_off || 0
    const actualAmount = (amount * (1 - percentOff / 100)) / 100
    const interval = subscriptionData?.plan.interval || "month"
    if (interval === "month") {
      const price = `$${actualAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`
      setSubscriptionPlan({
        label: `Monthly (${price}/month)`,
        value: "month",
      })
    } else if (interval === "year") {
      const price = `$${(actualAmount / 12).toLocaleString(undefined, { minimumFractionDigits: 2 })}`
      setSubscriptionPlan({
        label: `Annual (${price}/month)`,
        value: "year",
      })
    } else {
      setSubscriptionPlan(null)
    }
  }, [subscriptionData])

  const toggleModal = () => {
    setModal(!modal)
  }

  const handleReasonChange = (event) => {
    setSelected(event.target.value)
    setOpinion("")
    setModalError("")
  }

  const onCancelSubscription = async () => {
    try {
      if (!selected) {
        setModalError("Please select one option above")
      } else {
        if (selected === "option4" && !opinion) {
          setModalError("Please provide your feedback")
        } else {
          await Emails.sendCancel({
            full_name: user.full_name,
            email: user.email,
            reason: opinion,
            option: selected,
            org_name: user.organization.name
          }).then(async (data) => {
            setModal(false)
            setSelected("")
            setOpinion("")
            setModalError("")
            if (data.ResponseMetadata.HTTPStatusCode === 200) {
              await cancelSubscription({
                stripe_subscription_id: user.stripe_subscription_id,
                org_id: user.org_id,
              })
              await getUserInfo()
            }
          })
        }
      }
    } catch (error) {
      setModalError(error.message)
    }
  }

  const checkForSubscriptionPlanConfirmation = (callbackName) => {
    if (!user) {
      if (confirmCallbackName === callbackName) {
        onCloseSubscriptionPlanModal()
        return true
      } else {
        setConfirmCallbackName(callbackName)
        setConfirmSubscriptionPlanModal(true)
        return false
      }
    } else {
      return true
    }
  }

  const onActivateSubscription = async () => {
    if (checkForSubscriptionPlanConfirmation("onActivateSubscription")) {
      if (!isCancelAtPeriodEnd && subscriptionData.status === "canceled" && couponCode) {
        await activateAndApplyCoupon({
          org_id: user.org_id,
          subscription_plan: subscriptionData.value,
          couponCode,
        })
      } else {
        await updateSubscription({
          org_id: user.org_id,
          activate_subscription: true,
          subscription_plan: subscriptionPlan.value,
        })
      }
      await getUserInfo()
    }
  }

  const onChangeSubscriptionHandler = (option) => {
    setSubscriptionPlan(option)
    if (option.value !== subscriptionData?.plan.interval) {
      setUpdateDisable(false)
    } else {
      setUpdateDisable(true)
    }
  }

  const onConfirmSubscriptionPlanModal = async () => {
    if (confirmCallbackName === "onActivateSubscription") {
      await onActivateSubscription()
    }
  }

  const onCloseSubscriptionPlanModal = () => {
    setConfirmCallbackName("")
    setConfirmSubscriptionPlanModal(false)
  }

  const updateSubscriptionPlan = async () => {
    if (checkForSubscriptionPlanConfirmation("updateSubscriptionPlan")) {
      await updateSubscription({
        org_id: user.org_id,
        subscription_plan: subscriptionPlan.value,
      })
      setUpdateDisable(true)
      await getUserInfo()
    }
  }

  return (
    <>
      <Col className="px-2 px-sm-5 pt-2">
        {(isCanceled && !user.last4) && (
          <Alert color="info">
            Your subscription has expired. Please update your payment details to reactivate it.
          </Alert>
        )}
        {(isDue && !isPaused && !user.last4) && (
          <Alert color="warning">
            Your subscription is now {pastDays} days past due. 
            Please update your payment details within {leftDays} days to avoid your account being terminated.
          </Alert>
        )}
        {isDue && !isPaused && Boolean(user.last4) && (
          <Alert color="info">
            There seems to be an issue with your credit card. Please update your payment details to reactivate your
            subscription.
          </Alert>
        )}
        {user && user.subscription && <TrialAlert trial_end={user.subscription.trial_end} />}
        <h3 className="font-avenir-bold">Billing Overview</h3>
        {isError && <UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert>}
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100 py-5 my-5">
            <SpinnerOverlay show />
          </div>
        ) : (
          <>
            {isUpdating && (
              <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-75">
                <SpinnerOverlay show />
              </div>
            )}
            <div className="py-2">
              Subscription Status: <strong className="text-capitalize">{subscriptionStatus}</strong>
            </div>
            {isTrialing && (
              <div className="py-2">
                Trial ends on: <strong>{formatDate(subscriptionData.trial_end)}</strong>
              </div>
            )}
            <div className="py-2">
              <Select
                className="subscription-select"
                value={subscriptionPlan}
                name="subscription"
                isSearchable={false}
                options={subscriptionOptions}
                onChange={onChangeSubscriptionHandler}
                placeholder="Subscription Plan"
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </div>
            <Coupon
              stripe_subscription_id={user?.stripe_subscription_id}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              getUserInfo={getUserInfo}
              showApplyBtn={subscriptionData && subscriptionData.status !== "canceled"}
            />
            <div className="py-2">
              {isCanceled ? (
                <Button onClick={onActivateSubscription} className="form-control subscribe-btn">
                  Activate Subscription
                </Button>
              ) : (
                <>
                  <div className="py-2">
                    <Button
                      className="form-control subscribe-btn"
                      onClick={updateSubscriptionPlan}
                      disabled={updateDisable}
                    >
                      Update Subscription
                    </Button>
                  </div>
                  <Button onClick={toggleModal} className="form-control subscribe-btn">
                    Cancel Subscription
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </Col>
      {modal && (
        <div>
          <Modal className="modal-lg modal-md modal-sm modal-xs" isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              Before you leave, we would love if you could spare a few seconds to answer the question below:
            </ModalHeader>
            <ModalBody>
              <h5>Please select the reason for canceling subscription</h5>
              <Form>
                <FormGroup check>
                  <Label className="font-weight-bold" for="option1" check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="option1"
                      id="option1"
                      checked={selected === "option1"}
                      onChange={handleReasonChange}
                    />{" "}
                    Complexity
                    <br />
                    <p className="font-weight-normal">
                      If you have face any trouble with Traindex. Please reach out to our support at help@traindex.io
                      and we will assist you immediately.
                    </p>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label className="font-weight-bold" for="option2" id="option1" check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="option2"
                      id="option2"
                      checked={selected === "option2"}
                      onChange={handleReasonChange}
                    />{" "}
                    Pricing
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label className="font-weight-bold" for="option3" check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="option3"
                      id="option3"
                      checked={selected === "option3"}
                      onChange={handleReasonChange}
                    />{" "}
                    Not useful
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label className="font-weight-bold" for="option4" check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="option4"
                      id="option4"
                      checked={selected === "option4"}
                      onChange={handleReasonChange}
                    />{" "}
                    Other
                  </Label>
                  <Input
                    type="textarea"
                    name="text"
                    value={opinion}
                    disabled={selected !== "option4"}
                    placeholder="Enter your feedback..."
                    onChange={(event) => {
                      setOpinion(event.target.value)
                    }}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              {modalError && <span className="mx-auto text-danger">{modalError}</span>}
              <Button className="subscribe-btn" onClick={onCancelSubscription}>
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
      <Modal isOpen={confirmSubscriptionPlanModal} toggle={onCloseSubscriptionPlanModal}>
        <ModalHeader toggle={onCloseSubscriptionPlanModal}>Update Subscription</ModalHeader>
        <ModalBody>
          You are about to change your subscription plan. <br />
          Do you wish to continue?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCloseSubscriptionPlanModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={onConfirmSubscriptionPlanModal} className="text-white">
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default BillingCard
