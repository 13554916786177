import React from "react"
import { Alert } from "reactstrap"

/**
|--------------------------------------------------
| Reusable/Modular Message component with some default props
| for showing error/sucess messages/alerts
|--------------------------------------------------
*/

export const Message = ({
  color = "danger",
  header,
  content,
  isOpen = false,
  onToggle,
  fade = true
}) => {
  return (
    <Alert color={color} isOpen={isOpen} toggle={onToggle} fade={fade}>
      <p className="alert-heading">{header}</p>
      <hr />
      <p className="mb-0">{content}</p>
    </Alert>
  )
}
