import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Spinner, Alert } from "reactstrap"
import { Button } from "../Reactstrap_Components/Button"
import { confirmSignUp } from "../../utils/auth.utils"

const VerifyEmail = ({ isModalOpen, formData }) => {
  const history = useHistory(),
    [code, setCode] = useState(""),
    [loading, setLoading] = useState(false),
    [error, setError] = useState("")

  const confirm = async () => {
    const { email, password } = formData
    try {
      error && setError("")
      setLoading(true)
      await confirmSignUp(email, password, code)
      history.push("/index?signup-success=1")
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  const handleCodeChange = event => {
    setCode(event.target.value)
  }

  return (
    <Modal isOpen={isModalOpen} backdrop="static">
      <ModalHeader>Verify Email</ModalHeader>
      {error && (
        <Alert color="danger" data-testid="confirm-code-error">
          {error}
        </Alert>
      )}
      <ModalBody>
        Thank you for signing up!
        <p className="py-2">
          We need you to verify your email to complete your registration. Please check your email and paste verification
          code below:
        </p>
        <Input
          type="text"
          name="code"
          id="code"
          data-testid="code"
          value={code}
          onChange={handleCodeChange}
          placeholder="e.g. 123456"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={code.length < 6}
          data-testid="confirm-code-btn"
          className="purple_btn flex-grow-1 text-white"
          bgColor="#5E216B"
          onClick={confirm}
        >
          {loading && <Spinner size="sm" className="mx-1 text-white" />}Confirm
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default VerifyEmail
