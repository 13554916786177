import React from "react"
import Header from "./Header/"
import TermsOfUseText from "./Terms/TermsOfUseText"
import Footer from "./Footer"
import "../index.css"

const Terms = () => {
  return (
    <div>
      <Header />
      <TermsOfUseText />
      <Footer />
    </div>
  )
}

export default Terms
