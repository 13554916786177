import React, { useState, useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import { Spinner } from "reactstrap"
import { getCurrentUser } from "../auth.utils"
import "./publicRoute.css"

export const PublicRoute = ({ component: Component, ...props }) => {
  const [authorize, setAuthorize] = useState(false),
    [loading, setLoading] = useState(true)

  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        if (data) {
          setAuthorize(true)
        }
        setLoading(false)
      })
      .catch(() => {
        setAuthorize(false)
        setLoading(false)
      })
  }, [])

  return loading ? (
    <div className="d-flex align-items-center justify-content-center spinner_container">
      <Spinner size="lg" className="spinner" />
    </div>
  ) : (
    <Route
      {...props}
      render={props =>
        !authorize ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/index",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
