import React from "react"
import { ButtonGroup as ReactstrapButtonGroup, Button as ReactstrapButton } from "reactstrap"

/**
|--------------------------------------------------
| reusable ReactStrap Button and ButtonGroup components with
| some default props
|--------------------------------------------------
*/

export const Button = ({
  children,
  color = "white",
  bgColor = "gray",
  onClick,
  size = "md",
  disabled = false,
  outline = false,
  ...props
}) => {
  return (
    <ReactstrapButton
      style={{
        color,
        backgroundColor: bgColor,
        borderColor: outline ? color : bgColor
      }}
      disabled={disabled}
      onClick={onClick}
      size={size} // size values => "sm" or "lg"
      {...props}
    >
      {children}
    </ReactstrapButton>
  )
}

export const ButtonGroup = ({ bgColor, children, ...props }) => {
  return (
    <ReactstrapButtonGroup
      style={{
        backgroundColor: bgColor,
        borderRadius: 5
      }}
      {...props}
    >
      {children}
    </ReactstrapButtonGroup>
  )
}
