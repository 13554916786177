export const initialState = {
  user: null,
  orgInfo: null,
  files: [],
  indexes: [],
  userError: "",
  indexesError: "",
  apiSettingerror: "",
  orgInfoError: "",
  filesError: "",
  isSignUp: false,
  datasetJoyride: false,
  indexJoyride: true,
  searchJoyride: true
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
        userError: ""
      }
    case "SET_USER_FULLNAME":
      return {
        ...state,
        user: { ...state.user, full_name: action.fullName }
      }
    case "SET_GROUP_INFO":
      return {
        ...state,
        orgInfo: action.orgInfo
      }
    case "SET_FILES":
      return {
        ...state,
        files: action.files,
        filesError: ""
      }
    case "SET_ORGS":
      return {
        ...state,
        orgs: action.orgs,
        orgsError: ""
      }
    case "SET_INDEXES":
      return {
        ...state,
        indexes: action.indexes,
        indexesError: ""
      }
    case "SET_IS_SIGN_UP":
      return {
        ...state,
        isSignUp: action.isSignUp
      }
    case "SET_DATASET_JOYRIDE":
      return {
        ...state,
        datasetJoyride: action.datasetJoyride
      }
    case "SET_INDEX_JOYRIDE":
      return {
        ...state,
        indexJoyride: action.indexJoyride
      }
    case "SET_SEARCH_JOYRIDE":
      return {
        ...state,
        searchJoyride: action.searchJoyride
      }
    case "SET_USER_ERROR":
      return {
        ...state,
        userError: action.error,
        user: action.user
      }
    case "SET_GROUP_INFO_ERROR":
      return {
        ...state,
        orgInfoError: action.error
      }
    case "SET_FILES_ERROR":
      return {
        ...state,
        filesError: action.error
      }
    case "SET_ORGS_ERROR":
      return {
        ...state,
        orgsError: action.error
      }
    case "SET_INDEXES_ERROR":
      return {
        ...state,
        indexesError: action.error
      }
    default:
      return state
  }
}

export default reducer
