import React from "react"
import { useStateValue } from "../../contexts/StateProvider"
import { Container } from "reactstrap"
import BillingCard from "./BillingCard"
import PaymentMethod from "./PaymentMethod"
import InvoiceCard from "./InvoiceCard"
import "./subscription.css"

const Subscription = () => {
  const { state } = useStateValue(),
    { user } = state

  return (
    <Container className="py-2 font-avenir subscription-container">
      {(user && !user.role) || (user && user.role === "admin") ? (
        <>
          <BillingCard />
          <PaymentMethod />
          <InvoiceCard />
        </>
      ) : (
        <h5 className="mt-3 text-center">
          {user && user.role === "member" ? "You must be an administrator to access this page." : null}
        </h5>
      )}
    </Container>
  )
}

export default Subscription
