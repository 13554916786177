/* eslint-disable indent */
import React, { useState, useEffect } from "react"
import ReactJoyride from "react-joyride"
import {
  Container,
  Input,
  Label,
} from "reactstrap"
import { formatBytes, convertedDate } from "../../utils/common"
import DatasetApi from "../../api/DatasetApi"
import AwsApi from "../../api/AwsApi"
import { useStateValue } from "../../contexts/StateProvider"
import UnpaidModal from "../UnpaidModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH, faUpload, faTerminal } from "@fortawesome/free-solid-svg-icons"
import "./dataset.css"
import CSVUploadModal from "./CSVUploadModal"
import Preview from "./Preview"
import TrialAlert from "../TrialAlert"
import PastDueAlert from "../PastDueAlert"
import { DataGrid } from "@mui/x-data-grid"
import Button from "@mui/material/Button"
import DeleteIcon from "@mui/icons-material/Delete"

const Dataset = () => {
  const { state, dispatch } = useStateValue(),
    { user, files, filesError } = state,
    [error, setError] = useState(),
    [loading, setLoading] = useState(false),
    [pageSize, setPageSize] = useState(10),
    [preview, setPreview] = useState(),
    [modal, setModal] = useState(false),
    [csvModal, setCSVModal] = useState(false),
    [run, setRun] = useState(false)

  const steps = [
    {
      title: "Upload dataset via CSV",
      target: ".csv-upload-button",
      content: "Click here to upload your custom dataset. Supported format is CSV.",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Upload dataset via CLI",
      target: ".cli-upload-button",
      content: (
        <div>
          <p>
            If you have very large files, you can upload up to <span id="file-size">5TB</span> using the CLI. Some
            technical skills are required.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Data Catalogs",
      target: ".data-catalogs",
      content: "These are Data Catalogs that you can start using right away.",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
    },
    {
      title: "Expand Dataset",
      target: ".expand-button",
      content: (
        <div>
          <p>
            Click <FontAwesomeIcon icon={faEllipsisH} /> to expand and preview dataset
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      spotlightClicks: true,
    },
  ]

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      if (user && files.length === 0) {
        try {
          setLoading(true)
          setRun(false)
          const data = await DatasetApi.fetchFiles(user.org_id)
          dispatch({
            type: "SET_FILES",
            files: data,
          })
          setLoading(false)
          setRun(true)
        } catch (error) {
          dispatch({
            type: "SET_FILES_ERROR",
            error: error.message,
          })
          setLoading(false)
        }
      }
    })()
  }, [user])

  const refreshDataset = async () => {
    try {
      setLoading(true)
      const files = await DatasetApi.fetchFiles(user.org_id)
      dispatch({
        type: "SET_FILES",
        files: files,
      })
      setLoading(false)
    } catch (e) {
      setError("There was a problem with the server.")
    }
  }

  const toggle = () => {
    setCSVModal(!csvModal)
    refreshDataset()
  }

  const deleteFile = async (key) => {
    let preservedKey = key.replace("+", "%2B")
    setLoading(true)
    await DatasetApi.removeFile(preservedKey)
    const files = await DatasetApi.fetchFiles(user.org_id)
    dispatch({
      type: "SET_FILES",
      files: files,
    })
    setLoading(false)
  }

  const getPreview = async (key) => {
    let preservedKey = key.replace("+", "%2B")
    setLoading(true)
    try {
      const resp = await DatasetApi.fetchFiles(preservedKey)
      setPreview(resp.data)
      setModal(true)
      setLoading(false)
    } catch (error) {
      setError("Something went wrong. We will fix it soon")
      setLoading(false)
    }
  }

  const isCsv = (fileName) => {
    return fileName.endsWith(".csv")
  }

  const onUpload = async (e) => {
    e.preventDefault()
    if (e.target.files[0]) {
      const fileData = e.target.files[0]
      if (isCsv(fileData.name)) {
        if (fileData.size < 2147483648) {
          const formData = new FormData()
          setLoading(true)
          const resp = await DatasetApi.signed_policy({
            org_id: user.org_id,
            user_email: user.email,
            filename: fileData.name,
          })
          let signedFields = {}
          if (resp && resp.fields) {
            signedFields = resp.fields
          }
          for (const key of Object.keys(signedFields)) {
            formData.append(key, signedFields[key])
            if (key === "policy") {
              const decodedPolicy = JSON.parse(window.atob(signedFields[key]))
              decodedPolicy.conditions.forEach((i) => {
                if (!i.acl && i[0] === "starts-with") {
                  const key = i[1].split("$")
                  formData.append(key[1], i[2])
                }
              })
            }
          }
          formData.append("file", fileData)
          try {
            await AwsApi.uploadCsv(formData, resp.url)
            const files = await DatasetApi.fetchFiles(user.org_id)
            dispatch({
              type: "SET_FILES",
              files: files,
            })
            setLoading(false)
          } catch (e) {
            setError("There was a problem with the server.")
          }
        } else {
          setError("Your file is larger than 2GB, please select to upload via CLI tool.")
        }
      } else {
        setError("Only csv file is accepted.")
      }
    }
  }

  const joyRideCallback = (data) => {
    data.action === "close" &&
      dispatch({
        type: "SET_DATASET_JOYRIDE",
        datasetJoyride: false,
      })
  }


  const refinedFiles = files && files.map(
    (file) => {
      const fileName = file.Key.split("/").pop(),
      isUSPatentsDataset = file.Key.includes("/data-catalog/us_patents_dataset.csv"),
      isWikiDataset = file.Key.includes("/data-catalog/wikipedia.csv"),
      isArxivDataset = file.Key.includes("/data-catalog/arxiv.csv"),
      fileSize = isUSPatentsDataset
        ? 860000000000
        : isWikiDataset
        ? 18900000000
        : isArxivDataset
        ? 1.1e12
        : file.Size,
      fileDate = isUSPatentsDataset
        ? "2021-08-20"
        : isWikiDataset
        ? "2021-08-27"
        : isArxivDataset
        ? "2021-08-24"
        : file.LastModified
      return ({
        ...file,
        id: file.Key,
        name: fileName,
        size: formatBytes(fileSize),
        date: convertedDate(fileDate)
      })
    }
  )

  const filesForGrid = refinedFiles && refinedFiles.sort((a, b) => new Date(b.date) - new Date(a.date))

  const columns = [
    { 
      field: "name", 
      headerName: "Name",
      flex: 1
    },
    { 
      field: "date", 
      headerName: "Date", 
      flex: 1 
    },
    { 
      field: "size", 
      headerName: "Size", 
      flex: 1,
      sortable: false
    },
    { 
      field: "author", 
      headerName: "Author", 
      flex: 1 
    },
    { 
      field: "action", 
      headerName: "Actions",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              color="secondary"
              onClick={() => {
                getPreview(params.row.Key)
                setRun(!run)
                dispatch({
                  type: "SET_DATASET_JOYRIDE",
                  datasetJoyride: false,
                })
              }}
              className="preview-button mr-2"
            >
              Preview
            </Button>
            {params.row.Key.split("/")[1] !== "data-catalog" && (
              <Button
                startIcon={<DeleteIcon />}
                color="error"
                onClick={() => deleteFile(params.row.Key)}
              >
                Delete
              </Button>
            )}
          </>
        )
      }
    }
  ]

  return (
    <Container className="body-container mt-5">
      <ReactJoyride
        callback={joyRideCallback}
        steps={steps}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep
        run={state.datasetJoyride && run}
        styles={{
          options: {
            height: 0,
            primaryColor: "#4c2e74",
          },
        }}
        locale={{
          last: "End",
        }}
      />
      {user && user.subscription && (
        <div className={`${user.subscription._isExpired ? "blur-bg" : "justify-content-center px-1"}`}>
          <TrialAlert trial_end={user.subscription.trial_end} />
          <PastDueAlert subscription={user.subscription} />
          <div className="mx-auto dataset">
            <Button 
              className="dataset-btn p-0 ml-1 mr-3 mb-1 csv-upload-button"
              variant="contained"
            >
              <Label className="mb-0" for="file">
                <FontAwesomeIcon icon={faUpload} /> CSV Upload
              </Label>
              <Input
                type="file"
                id="file"
                name="customFile"
                accept=".csv"
                style={{ display: "none" }}
                onChange={onUpload}
              />
            </Button>
            <Button
              className="dataset-btn p-0 ml-1 mb-1 cli-upload-button"
              onClick={() => toggle()}
              variant="contained"
            >
              <Label className="mb-0">
                <FontAwesomeIcon icon={faTerminal} /> CLI Upload
              </Label>
            </Button>
            <div
              className="mt-3"
              style={{ 
                display: "flex", 
                width: "100%"
              }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  autoHeight
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rows={filesForGrid || []}
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  loading={loading}
                />
              </div>
            </div>
          </div>
          {filesError && <div className="mt-5 text-danger">{filesError}</div>}
          {error && <div className="mt-5 text-danger">{error}</div>}
          {modal && <Preview data={preview} modal={modal} setModal={setModal} />}
          {csvModal && <CSVUploadModal modal={csvModal} toggle={toggle} user={user} />}
          {user && user.subscription._isExpired && <UnpaidModal modal={true} />}
        </div>
      )}
    </Container>
  )
}

export default Dataset
