import moment from "moment"
import client from "./APIClient"

export default class Invoice {
  static query = async (stripe_customer_id) => {
    const invoices = await client(`invoice?stripe_customer_id=${stripe_customer_id}`)
    return invoices.map((item) => ({
      ...item,
      created: moment.unix(item.created).format("ll"),
      total: item.total / 100,
    }))
  }
}