import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import ReactJoyride from "react-joyride"
import {
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Table,
  CardSubtitle,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"
import { useStateValue } from "../../contexts/StateProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { Button } from "../Reactstrap_Components/Button"
import "./demoapi.css"
import patentSample from "./patent_sample.json"
import wikiSample from "./wikipedia_sample.json"
import arxivSample from "./arxiv_sample.json"
import UnpaidModal from "../UnpaidModal"
import TrialAlert from "../TrialAlert"
import PastDueAlert from "../PastDueAlert"
import Select from "react-select"

const DemoAPI = () => {
  const { state, dispatch } = useStateValue(),
    { user } = state,
    apis = user?.organization.apis,
    [patents, setPatents] = useState(""),
    [articles, setArticles] = useState(""),
    [arxivSamples, setArxivSamples] = useState(""),
    [loading, setLoading] = useState(false),
    [activeTab, setActiveTab] = useState("1"),
    [error, setError] = useState(""),
    [dropDownValue, setDropDownValue] = useState("Patents"),
    [searchValue, setSearchValue] = useState(""),
    [optionValue, setOptionValue] = useState({ label: "Insert sample query", value: "" }),
    patentNumbers = Object.keys(patentSample),
    wikiArticles = Object.keys(wikiSample),
    arxivTitles = Object.keys(arxivSample),
    [keyString, setKeyString] = useState("demo-search")

  const steps = [
    {
      title: "Toggle Indexes",
      target: ".search-dropdown",
      content: "You can select one of the built-in indexes here to perform the search over.",
      floaterProps: {
        disableAnimation: true,
      },
      placement: "top",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Add search text",
      target: ".demo-input",
      content: (
        <p>
          Paste the text you want to search for, e.g.
          <p id="search-text-example">
            a mechanical switch having a plurality of separate and independent switch positions which comprise a first
            switch position ad a second switch position, the mechanical switch being configured to switch among a
            plurality of programmable user interface profile settings which comprise a first profile setting and a
            second profile setting.
          </p>
        </p>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Choose Samples",
      target: ".sample-dropdown",
      content: "You could also choose from our sample texts",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Search",
      target: ".search_btn",
      content: "Click on the search button to start a search in " + dropDownValue,
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      spotlightClicks: true,
    },
  ]

  const selectIndex = (e) => {
    setDropDownValue(e.currentTarget.textContent)
    setSearchValue("")
    setPatents("")
    setArticles("")
    setArxivSamples("")
    setOptionValue({ label: "Insert sample query", value: "" })
    if (activeTab !== e.target.value) setActiveTab(e.target.value)
    error && setError("")
  }

  const patentsOptions = patentNumbers.map((patentNumber) => {
    return { value: patentNumber, label: patentNumber }
  })

  const articleOptions = wikiArticles.map((article) => {
    return { value: article, label: article }
  })

  const arxivOptions = arxivTitles.map((title) => {
    return { value: title, label: title }
  })

  let sampleOptions = activeTab === "1" ? patentsOptions : (activeTab === "2" ? articleOptions : arxivOptions)

  const onSubmitRequest = async (event) => {
    event.preventDefault()
    setLoading(true)
    dispatch({
      type: "SET_IS_SIGN_UP",
      isSignUp: false,
    })
    error && setError("")
    try {
      const response = await axios({
        url: activeTab === "1" ? `${apis?.us_patents_endpoint}/query` : 
          (activeTab === "2" ? `${apis?.wiki_endpoint}/query` : `${apis?.arxiv_endpoint}/query`),
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "x-api-key": activeTab === "1" ? apis?.us_patents_api_keys[0].api_key : 
            (activeTab === "2" ? apis?.wiki_api_keys[0].api_key : apis?.arxiv_api_keys[0].api_key ),
        },
        data: JSON.stringify({ request: { conceptFeaturesString: searchValue.trim(), maxNumItems: 100 } }),
      })
      activeTab === "1" ? setPatents(response.data) : 
        (activeTab === "2" ? setArticles(response.data) : setArxivSamples(response.data))
      setLoading(false)
    } catch (error) {
      setError("The server is busy right now. Please try again later.")
      setLoading(false)
    }
  }

  const patentsDisplay =
    patents &&
    (patents.foundItems.length ? (
      patents.foundItems.map((item, index) => {
        let urlPath = item.ucid.replace(/-/g, "")
        const title = item.title && item.title.charAt(0).toUpperCase() + item.title.slice(1)
        const urlLink = `https://patents.google.com/patent/${urlPath}`
        return (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{item.ucid}</td>
            <td>
              <a href={urlLink} target="_blank" rel="noopener noreferrer">
                {title || urlLink}
              </a>
            </td>
            <td>{item.conceptScore.toFixed(4)}</td>
          </tr>
        )
      })
    ) : (
      <tr className="text-center">
        <td colSpan="4">No item found.</td>
      </tr>
    ))

  const articlesDisplay =
    articles &&
    (articles.foundItems.length ? (
      articles.foundItems.map((item, index) => {
        return (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{item.ID}</td>
            <td>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            </td>
            <td>{item.conceptScore.toFixed(4)}</td>
          </tr>
        )
      })
    ) : (
      <tr className="text-center">
        <td colSpan="4">No item found.</td>
      </tr>
    ))

  const arxivSamplesDisplay =
    arxivSamples &&
    (arxivSamples.foundItems.length ? (
      arxivSamples.foundItems.map((item, index) => {
        let [ id_start, id_end ] = item.id.split(".")
        if (id_start.length === 3) id_start = "0" + id_start
        if (id_end.length < 4) {
          id_end = id_end + "0".repeat(4 - id_end.length)
        } else if (id_end.length > 4) {
          id_end = id_end.substring(0,4)
        }
        const fixed_id = id_start + "." + id_end
        const urlLink = `https://arxiv.org/abs/${fixed_id}`
        return (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{fixed_id}</td>
            <td>
              <a href={urlLink} target="_blank" rel="noopener noreferrer">
                {item.title || urlLink}
              </a>
            </td>
            <td>{item.conceptScore.toFixed(4)}</td>
          </tr>
        )
      })
    ) : (
      <tr className="text-center">
        <td colSpan="4">No item found.</td>
      </tr>
    ))

  const handleSelectSample = (option) => {
    setOptionValue(option)
    if (activeTab === "1") {
      setSearchValue(patentSample[option.value])
      setKeyString(patentSample[option.value])
    } else if (activeTab == "2") {
      setSearchValue(wikiSample[option.value])
      setKeyString(wikiSample[option.value])
    } else {
      setSearchValue(arxivSample[option.value])
      setKeyString(arxivSample[option.value])
    }
  }

  const joyRideCallback = (data) => {
    data.action === "reset" ||
      (data.action === "close" &&
        dispatch({
          type: "SET_IS_SIGN_UP",
          isSignUp: false,
        }))
  }

  return (
    <div className={`${user && user.subscription._isExpired ? "blur-bg" : "justify-content-center px-1"}`}>
      <div>
        <Container className="mx-auto mt-5">
          {user && user.subscription && <TrialAlert trial_end={user.subscription.trial_end} />}
          {user && user.subscription && <PastDueAlert subscription={user.subscription} />}
          <ReactJoyride
            callback={joyRideCallback}
            steps={steps}
            continuous={true}
            showSkipButton={true}
            scrollToFirstStep
            run={state.isSignUp}
            styles={{
              options: {
                height: 0,
                primaryColor: "#4c2e74",
              },
            }}
            locale={{
              last: "End",
            }}
          />
          <p>
            This is a sample search widget implemented to give you a chance to interact with the API without using API
            client tools.
          </p>
          <p>
            To see all functionality and features, please consider using an API client like{" "}
            <a href="https://insomnia.rest/" className="text-decoration-none" target="_blank" rel="noopener noreferrer">
              Insomnia
            </a>{" "}
            and{" "}
            <a
              href="https://www.postman.com/"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Postman
            </a>
          </p>
        </Container>
      </div>
      <div className="justify-content-center px-1 mt-5">
        <Container className="mt-4 mx-auto">
          <Form className="mx-auto" onSubmit={onSubmitRequest}>
            <FormGroup>
              <div className="d-flex text-center">
                <UncontrolledDropdown className="my-3 mr-3 search-dropdown">
                  <DropdownToggle className="search-dropdown-toggle py-2 px-2 bg-transparent border-0" caret>
                    {dropDownValue}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem value="1" onClick={selectIndex}>
                      Patents
                    </DropdownItem>
                    <DropdownItem value="2" onClick={selectIndex}>
                      Wikipedia
                    </DropdownItem>
                    <DropdownItem value="3" onClick={selectIndex}>
                      Arxiv
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <div className="mt-4">
                  <FontAwesomeIcon icon={faQuestionCircle} id="QuestionMark" />
                  <UncontrolledTooltip placement="right" target="QuestionMark">
                    Only indexes on the active status are listed
                  </UncontrolledTooltip>
                </div>
              </div>
              <Input
                key={keyString}
                className="demo-input"
                autoFocus
                type="textarea"
                rows="5"
                name="api"
                value={searchValue}
                placeholder="Search text"
                onChange={(event) => setSearchValue(event.target.value)}
                id="api"
              />
              <div className="d-flex align-items-center my-3">
                <Select
                  className="demo-select default-select text-left"
                  options={sampleOptions}
                  value={optionValue}
                  onChange={(option) => handleSelectSample(option)}
                />
                <Button className="purple_btn search_btn" type="submit" disabled={!searchValue}>
                  Search
                </Button>
              </div>
            </FormGroup>
            {error ? <CardSubtitle className="align-self-center mt-2 text-danger">{error}</CardSubtitle> : null}
          </Form>
          {loading && (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner size="lg" className="spinner" />
            </div>
          )}
          {patentsDisplay && (
            <Row className="mt-5">
              <Col sm="12">
                <Table responsive className="demo_table">
                  <thead>
                    <tr style={{ backgroundColor: "#F2F2F2" }}>
                      <th style={{ width: "5%" }}>#</th>
                      <th style={{ width: "20%" }}>Patent UCID</th>
                      <th style={{ width: "55%" }}>Link</th>
                      <th style={{ width: "20%" }}>Score</th>
                    </tr>
                  </thead>
                  <tbody>{patentsDisplay}</tbody>
                </Table>
              </Col>
            </Row>
          )}
          {articlesDisplay && (
            <Row className="mt-5">
              <Col sm="12">
                <Table responsive className="demo_table">
                  <thead>
                    <tr style={{ backgroundColor: "#F2F2F2" }}>
                      <th style={{ width: "5%" }}>#</th>
                      <th style={{ width: "20%" }}>Article ID</th>
                      <th style={{ width: "55%" }}>Title</th>
                      <th style={{ width: "20%" }}>Score</th>
                    </tr>
                  </thead>
                  <tbody>{articlesDisplay}</tbody>
                </Table>
              </Col>
            </Row>
          )}
          {arxivSamplesDisplay && (
            <Row className="mt-5">
              <Col sm="12">
                <Table responsive className="demo_table">
                  <thead>
                    <tr style={{ backgroundColor: "#F2F2F2" }}>
                      <th style={{ width: "5%" }}>#</th>
                      <th style={{ width: "20%" }}>Article ID</th>
                      <th style={{ width: "55%" }}>Title</th>
                      <th style={{ width: "20%" }}>Score</th>
                    </tr>
                  </thead>
                  <tbody>{arxivSamplesDisplay}</tbody>
                </Table>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {user && user.subscription._isExpired && <UnpaidModal modal={true} />}
    </div>
  )
}

DemoAPI.propTypes = {
  history: PropTypes.object.isRequired,
}

export default DemoAPI