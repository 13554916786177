import React, { useState, useEffect } from "react"
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  CardText,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  Table,
  UncontrolledTooltip,
  CardSubtitle,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { Link } from "react-router-dom"
import Clipboard from "react-clipboard.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCopy,
  faQuestionCircle,
  faAngleDown,
  faAngleUp,
  faEye,
  faEyeSlash,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons"
import WikipediaData from "./wikipedia.json"
import UnpaidModal from "../../UnpaidModal"
import ApiSettings from "../../../api/ApiSettings"
import { Message } from "../../ExtraComponents/Message"
import { useStateValue } from "../../../contexts/StateProvider"
import "./indexItem.css"

const WikipediaIndex = () => {
  const [collapse, setCollapse] = useState(null),
    columnNames = Object.keys(WikipediaData[0]),
    [loading, setLoading] = useState({
      getAll: false,
      addMore: false,
      deleteKey: false,
    }),
    { state, getUserInfo } = useStateValue(),
    { user } = state,
    [visible, setVisible] = useState(null),
    [apis, setApis] = useState(null),
    [error, setError] = useState(""),
    [modal, setModal] = useState(false),
    [keyToDelete, setKeyToDelete] = useState("")

  useEffect(() => {
    if (user) {
      setApis(user.organization.apis)
    }
  }, [user])

  const handleAddMore = async () => {
    try {
      if (apis.wiki_api_keys.length === 10) return setError("Maximum 10 API keys are allowed")
      setLoading((prevLoading) => ({ ...prevLoading, addMore: true }))
      await ApiSettings.addKey({ org_id: user.organization.item_id, index: "wikipedia" })
    } catch (error) {
      setError(error.message)
    } finally {
      await getUserInfo()
      setLoading((prevLoading) => ({ ...prevLoading, addMore: false }))
    }
  }

  const handleDeleteKey = async () => {
    setModal(!modal)
    try {
      if (apis.wiki_api_keys.length === 1)
        return setError("Operation not allowed, please add another key before deleting this one")
      error && apis.wiki_api_keys.length > 1 && setError("")
      setLoading((prevLoading) => ({ ...prevLoading, deleteKey: true }))
      await ApiSettings.removeKey({
        api_key_id: keyToDelete,
        index: "wikipedia",
        org_id: user.organization.item_id,
      })
    } catch (error) {
      setError(error.message)
    } finally {
      await getUserInfo()
      setLoading((prevLoading) => ({ ...prevLoading, deleteKey: false }))
    }
  }

  const onToggle = () => setError("")

  const toggleModal = () => setModal(!modal)

  const onChangeVisible = (id) => {
    if (id === visible) {
      setVisible(null)
    } else {
      setVisible(id)
    }
  }

  const onDeleteKey = (apiId) => {
    toggleModal()
    setKeyToDelete(apiId)
  }

  const onChangeCollapse = (index) => {
    if (index === collapse) {
      setCollapse(null)
    } else {
      setCollapse(index)
    }
  }

  return (
    <Container className={`${user && user.subscription._isExpired ? "blur-bg" : "index-details mb-5"}`}>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to="/index">Index</Link>
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="#">
          Wikipedia
        </BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mt-5 font-weight-bold">Wikipedia</h5>
      <Card className="index_overview">
        <CardHeader>
          <Row className="header-main mx-1">
            <h5 className="mb-0 py-2 font-weight-bold">Index Overview</h5>
            <div>
              <Link className="mr-2" to="/swagger/wikipedia">
                <Button className="api-specification-button">API Specification</Button>
              </Link>
              <Link to="/docs/wikipedia">
                <Button className="full-documentation-button">Full documentation</Button>
              </Link>
            </div>
          </Row>
        </CardHeader>
        {!apis ? (
          <CardBody>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Spinner size="lg" className="spinner" />
            </div>
          </CardBody>
        ) : apis.wiki_endpoint ? (
          <>
            <CardBody>
              <CardSubtitle className="my-2">Endpoint</CardSubtitle>
              <InputGroup>
                <Input value={apis.wiki_endpoint} disabled />
              </InputGroup>
              <CardText className="mt-2">
                <Clipboard className="copy pl-0 ml-0" data-clipboard-text={apis.wiki_endpoint}>
                  <FontAwesomeIcon icon={faCopy} /> copy to clipboard
                </Clipboard>
              </CardText>
              <CardSubtitle className="mt-4 pt-2 mb-2">Version</CardSubtitle>
              <InputGroup className="version">
                <InputGroupText>v</InputGroupText>
                <Input value="2.0" disabled />
              </InputGroup>
            </CardBody>
          </>
        ) : (
          <CardBody>
            <div>
              No API available. Please contact
              <a href="mailto:help@traindex.io" className="mx-1">
                help@traindex.io
              </a>
              to get one.
            </div>
          </CardBody>
        )}
      </Card>
      <Card className="mt-5">
        <CardHeader>
          <Row className="mx-1">
            <h5 className="mb-0 py-2 font-weight-bold">
              Index Secret Keys
              <FontAwesomeIcon className="mt-2 ml-1" size="sm" icon={faQuestionCircle} id="secret-tooltip" />
            </h5>
          </Row>
          <UncontrolledTooltip placement="right" target="secret-tooltip">
            Your API keys are like your passwords: make sure to always keep them hidden! Share them only with services
            you trust.
          </UncontrolledTooltip>
        </CardHeader>
        {!apis ? (
          <CardBody>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Spinner size="lg" className="spinner" />
            </div>
          </CardBody>
        ) : apis.wiki_api_keys ? (
          <>
            <CardBody>
              <Message header="Error" content={error} isOpen={error ? true : false} onToggle={onToggle} />
              <Table responsive>
                <thead>
                  <tr>
                    <th>API Key</th>
                    <th></th>
                    <th>Creation Date</th>
                  </tr>
                </thead>
                <tbody>
                  {apis.wiki_api_keys.map((apiKey) => (
                    <tr key={apiKey.id}>
                      <td className="key-row">
                        {visible === apiKey.api_key
                          ? apiKey.api_key
                          : "***************************************************"}
                      </td>

                      <td>
                        <Clipboard className="copy_key mr-3" data-clipboard-text={apiKey.api_key}>
                          <FontAwesomeIcon icon={faCopy} />
                        </Clipboard>
                        <Button className="copy_key" onClick={() => onChangeVisible(apiKey.api_key)}>
                          <FontAwesomeIcon icon={visible === apiKey.api_key ? faEyeSlash : faEye} />
                        </Button>
                      </td>

                      <td>{apiKey.creation_date}</td>

                      <td style={{ width: "120px" }}>
                        {loading.deleteKey && keyToDelete === apiKey.id && (
                          <Spinner type="grow" className="align-self-center" />
                        )}
                        <p type="button" className="delete-key float-right" onClick={() => onDeleteKey(apiKey.id)}>
                          Delete
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                disabled={loading.addMore}
                color="link"
                onClick={() => handleAddMore()}
                className="add_more_container align-self-start"
              >
                <span className="add_more">
                  Add more secret keys <FontAwesomeIcon icon={faPlusCircle} />
                </span>
              </Button>
              {loading.addMore && <Spinner type="grow" className="align-self-center" />}
            </CardBody>
            <Modal className="confirm_delete" isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Delete API Key?</ModalHeader>
              <ModalBody>
                <p className="py-2">Are you sure you want to delete this API key?</p>
              </ModalBody>
              <ModalFooter>
                <Button className="delete_btn text-white shadow-none" onClick={() => handleDeleteKey()}>
                  Yes
                </Button>{" "}
                <Button className="text-white shadow-none" onClick={toggleModal}>
                  No
                </Button>
              </ModalFooter>
            </Modal>
          </>
        ) : (
          <CardBody></CardBody>
        )}
      </Card>
      <Card className="mt-5">
        <CardHeader>
          <Row className="mx-1">
            <h5 className="mb-0 py-2 font-weight-bold">Index Data</h5>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="mt-3 ml-1 px-1">
            <thead>
              <tr className="font-avenir-bold">
                <th>Dataset Name</th>
                <th>Size</th>
                <th>No of rows</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Wikipedia Data</td>
                <td>18.9 GB</td>
                <td>51 849 881</td>
              </tr>
            </tbody>
          </Table>
          <Container className="data_preview">
            <CardSubtitle className="mt-4 pt-2">Preview</CardSubtitle>
            {columnNames.map((name, index) => (
              <Container key={name}>
                <Row
                  key={name}
                  className={(name === "article" ? "index" : "metadata") + " mt-4 mx-auto"}
                  onClick={() => onChangeCollapse(index)}
                >
                  <div className="order">{index + 1}</div>
                  <div className="title pl-3 font-weight-bold">{name}</div>
                  <div className="data_role">
                    {name === "article" ? "Index" : "Metadata"}
                    <FontAwesomeIcon
                      className="ml-4"
                      icon={collapse === index ? faAngleUp : faAngleDown}
                      onClick={() => onChangeCollapse(index)}
                    />
                  </div>
                </Row>
                <Collapse isOpen={collapse === index}>
                  <Table className="m-0">
                    <tbody>
                      {WikipediaData.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{item[name]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Collapse>
              </Container>
            ))}
          </Container>
        </CardBody>
      </Card>
      {user && user.subscription._isExpired && <UnpaidModal modal={true} />}
    </Container>
  )
}

export default WikipediaIndex
