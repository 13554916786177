import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import "./chatbot.css"

const SingleMessage = ({ index, message }) => {
  if (message?.isUser) {
    return (
      <div key={index} className="d-flex flex-row-reverse mb-2">
        <span className="symbol-icon bg-grey-100 mb-0 ml-2">U </span>
        {/* <span className="bg-grey-100 p-2 rc-bottom-left">{message.text}</span> */}
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="bg-grey-100 mx-90 minw-10 p-2 rc-bottom-left">
          {message.text}
        </ReactMarkdown>
      </div>
    )
  } else {
    return (
      <div key={index} className="bot-message mb-4 d-flex">
        <span className="symbol-icon bg-purple-100 mb-0 mr-2">D </span>
        {/* <span className="bg-purple-100 p-2 rc-bottom-right">{message.text}</span> */}
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="bg-purple-100 mx-90 p-2 rc-bottom-right">
          {message.text}
        </ReactMarkdown>
      </div>
    )
  }
}

export default SingleMessage
