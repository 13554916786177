import client from "./APIClient"

export default class Indexes {
  static async list(organizationId) {
    return client(`indexes?org_id=${organizationId}`)
  }

  static get = async (index_id) => {
    return client(`indexes?index_id=${index_id}`)
  }

  static create = formData => {
    return client("indexes", { method: "POST", body: formData })
  }
  static update = params => {
    return client("indexes", { method: "PUT", body: params })
  }
}
