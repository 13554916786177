import React, { useState, useEffect } from "react"
import TextTransition, { presets } from "react-text-transition"
import Start15DayFreeTrialButton from "../Start15DayFreeTrialButton"

const TEXTS = ["patent search ", "prior art search ", "novelty search ", "similarity search "]

const TopText = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index => index + 1)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="text-center py-5">
      <h1 className="text-white text-center font-weight-bolder font-avenir-bold topline">
        AI-powered{" "}
        <TextTransition
          className="text-secondary"
          inline
          text={TEXTS[index % TEXTS.length]}
          springConfig={presets.gentle}
        />
        <br />
        without hiring a data science team
      </h1>
      <Start15DayFreeTrialButton className="mt-5" />
      <br />
      <small className="text-light">No credit card required</small>
    </div>
  )
}

export default TopText
