import React, { useState } from "react"
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import { Auth } from "aws-amplify"
import { Link } from "react-router-dom"
import Users from "../../api/UsersAPI"
import { signIn } from "../../utils/auth.utils"
import "./signIn.css"
import { validatePassword } from "../../utils/common"
import VerifyEmail from "../VerifyEmail"
import traindexLogo from "../../images/traindex-logo.png"

const SignIn = props => {
  const [email, setEmail] = useState(""),
    [password, setPassword] = useState(""),
    [loading, setLoading] = useState({
      signIn: false,
      resetPassword: false
    }),
    [user, setUser] = useState({}),
    [newPassword, setNewPassword] = useState(""),
    [modal, setModal] = useState(false),
    [error, setError] = useState(""),
    [isModalOpen, setIsModalOpen] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading({ ...loading, signIn: true })
    error && setError("")

    try {
      const userInfo = await signIn(email, password)
      setLoading({ ...loading, signIn: false })
      if (userInfo.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(userInfo)
        setModal(true)
      } else {
        return redirectToDashboard()
      }
    } catch (error) {
      if (error.name === "InvalidParameterException") {
        setError("Please check your input information.")
      } else if (error.name === "UserNotConfirmedException") {
        setIsModalOpen(!isModalOpen)
      } else {
        setError(error.message)
      }
      setLoading({ ...loading, signIn: false })
    }
  }

  const handleResetPasswordSubmit = async event => {
    event.preventDefault()
    setLoading({ ...loading, resetPassword: true })
    try {
      const fullName = user.challengeParam.userAttributes.name || ""
      const passwordValidate = validatePassword(email, newPassword, fullName)
      if (passwordValidate === "ok") {
        await Auth.completeNewPassword(user, newPassword, { email })
        await Users.update({ email: email, email_verified: "true", auth_user_id: user.username })
        setLoading({ ...loading, resetPassword: false })
        return redirectToDashboard()
      } else if (passwordValidate === "name") {
        setError("Password should not contain part of your name")
      } else if (passwordValidate === "email") {
        setError("Password should not contain part of your email")
      }
    } catch (error) {
      setError(error.message)
      setLoading({ ...loading, resetPassword: false })
    }
  }

  const onChangeHandler = event => {
    const { name, value } = event.target
    name === "email" ? setEmail(value) : name === "password" ? setPassword(value) : setNewPassword(value)
  }

  const toggle = () => setModal(!modal)

  const redirectToDashboard = () => {
    return props.history.push("/index")
  }

  return (
    <div className="d-flex justify-content-center align-items-center full_height purple_background">
      <Card className="card-container p-2 m-1">
        <Link to="/" className="align-self-center mt-2 mb-3">
          <CardImg className="card_img" top src={traindexLogo} alt="Traindex logo" />
        </Link>
        <CardTitle className="card_title font-weight-bold align-self-center mt-2">WELCOME</CardTitle>
        {error ? <CardSubtitle className="align-self-center mt-2 text-danger">{error}</CardSubtitle> : null}
        <CardBody className="card_body align-self-center mx-5">
          <Form onSubmit={handleSubmit} className="d-flex flex-column">
            <FormGroup className="my-3">
              <Label for="email" className="input_label font-weight-bold text-muted">
                EMAIL
              </Label>
              <Input
                name="email"
                id="email"
                placeholder="e.g. example@domain.com"
                onChange={onChangeHandler}
                required
              />
            </FormGroup>
            <FormGroup className="my-4">
              <Label for="password" className="input_label font-weight-bold text-muted">
                PASSWORD
              </Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={onChangeHandler}
                required
              />
            </FormGroup>
            <Link to="/reset-password">Forgot password?</Link>
            <Button type="submit" className="signin_btn font-weight-bold d-flex align-self-center mt-3">
              {loading.signIn ? <Spinner size="sm" className="signin_spinner m-1" /> : null}
              SIGN IN
            </Button>
            <p className="text-center mt-3">
              Don&apos;t have an account?
              <Link to="/sign-up" className="purple_color ml-1 font-weight-bold">
                Sign Up
              </Link>
            </p>
          </Form>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Reset password</ModalHeader>
        {error ? <div className="align-self-center mt-2 text-danger">{error}</div> : null}
        <Form onSubmit={handleResetPasswordSubmit} className="d-flex flex-column">
          <ModalBody>
            <FormGroup className="my-4">
              <Label for="newPassword" className="input_label font-weight-bold text-muted">
                PASSWORD
              </Label>
              <FormText className="text-muted mb-1">Password must have at least 10 characters.</FormText>
              <Input
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="New Password"
                onChange={onChangeHandler}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" className="signin_btn font-weight-bold align-self-center">
              {loading.resetPassword ? <Spinner size="sm" className="signin_spinner m-1" /> : null}
              SAVE
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <VerifyEmail isModalOpen={isModalOpen} formData={{ email, password }} />
    </div>
  )
}

export default SignIn
