import React from "react"
import { Spinner } from "reactstrap"
import "./spinnerOverlay.css"

const SpinnerOverlay = ({ show }) => {
  return show ? (
    <Spinner data-testid="spinner-overlay" size="xlg" className="position-absolute spinner-overlay" color="primary" />
  ) : null
}

export default SpinnerOverlay