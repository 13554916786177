/* eslint-disable max-len */
import React from "react"
import { Container, Row, Col } from "reactstrap"

const TermsOfUseText = () => {
  return (
    <Container className="mt-5" fluid>
      <Row>
        <Col xs={{ size: 10, offset: 1 }}>
          <div className="text-justify terms-of-use-text">
            <p>
              <span className="font-weight-bold">1. General. </span>
              These terms of use (these “Terms of Use”), together with the related Privacy Policy (which can be found
              here:
              <a href="/privacy">https://www.traindex.io/privacy-policy/</a>, the “Privacy Policy”), which are
              incorporated into these Terms of Use by this reference, govern your access to and use of any website
              (including but not limited to traindex.io), mobile application, desktop application, plugin or other media
              platform that references or links to these Terms of Use (collectively, the “Site”), however accessed or
              used, and constitute a legally binding contract between you and Joinant, LLC., a Washington Limited
              Liability Corporation, DBA Traindex (together with its subsidiaries, if any, and other affiliates,
              “Traindex,” “we,” “us” or “our”). By signifying your acceptance of these Terms of Use, or by accessing or
              using the Site or allowing others to access or use the Site on your behalf or through the use of your
              Traindex user account (“Your Account”), you agree to be bound by these Terms of Use (which may be updated
              from time to time, as discussed below). Accordingly, you should read and evaluate these Terms of Use and
              the Privacy Policy carefully before accessing or using the Site, or allowing others to access or use the
              Site on your behalf, and you should consult your legal and other advisors and make your own informed
              decision as to whether and how to access or use the Site. Do not access or use the Site if you are
              unwilling or unable to be bound by the terms of these Terms of Use or any Service Agreement applicable to
              you (as discussed below in these Terms of Use).
            </p>
            <p>
              These Terms of Use are in addition to any other legally binding agreement that you may have entered into
              with Traindex that is intended by Traindex to govern your use of the Site (each, a “Service Agreement”).
              To the extent any such Service Agreement is in writing and any terms thereof conflict with the terms of
              these Terms of Use, such terms of the applicable Service Agreement will govern.
            </p>
            <p>
              Please consult these Terms of Use periodically as Traindex may, at any time, at its discretion and without
              notice, revise these Terms of Use. The “Last updated” date at the top of this page will reflect the latest
              date any edits were made. By continuing to access or use the Site after these Terms of Use have been
              revised, you shall hereby automatically be deemed to be bound by such revised Terms of Use. If at any time
              you choose not to accept the terms of these Terms of Use, you should immediately discontinue your access
              to or use of the Site.
            </p>
            <p>
              Traindex may, at its sole discretion, and at any time, discontinue the Site or any part thereof, with or
              without notice, or may prevent your use of the Site or any part thereof with or without notice to you. You
              agree that you do not have any rights in the Site and that Traindex will have no liability to you if the
              Site or any part thereof is discontinued or your ability to access the Site or any part thereof or any
              content you may have posted to any part of the Site is terminated.
            </p>
            <p>
              PLEASE NOTE THAT THESE TERMS OF USE LIMIT TRAINDEX’S POTENTIAL LIABILITY TO YOU AND MAY SUBSTANTIVELY
              AFFECT YOUR RIGHTS IN OTHER WAYS AND CONTAIN AN AGREEMENT TO ARBITRATE, WHICH WILL, WITH LIMITED
              EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST TRAINDEX TO BINDING AND FINAL ARBITRATION UNLESS
              YOU OPT OUT OF THE AGREEMENT TO ARBITRATE (SEE “DISCLAIMER;” “LIMITATION OF LIABILITY;” “LIMITED TIME TO
              BRING YOUR CLAIM;” AND “AGREEMENT TO ARBITRATE” PROVISIONS BELOW). UNLESS YOU OPT OUT: (I) YOU WILL ONLY
              BE PERMITTED TO PURSUE CLAIMS AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
              CLASS OR REPRESENTATIVE ACTION OR PROCEEDING AND (II) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING
              MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.
            </p>
            <p>
              <span className="font-weight-bold">2. Authorized Users; General Terms. </span>
              You must be at least 18 years old to register to access or use the Site. By accessing or using the Site,
              you represent and warrant that you are over the age of 18 and have the requisite power and authority to
              enter into and accept the terms of these Terms of Use. Subject to these Terms of Use, you may not access
              or use the Site if you are unable to form a legal binding agreement with Traindex. If you are under 18
              years of age, you may not access or use the Site absent the consent of a parent or guardian.
            </p>
            <p>
              If you are using the Site on behalf of any other person or entity (each, a “Person”), (i) you must be duly
              authorized by such Person to act on their behalf and have the requisite power and authority to act on
              behalf of and legally bind such other Person; (ii) if such Person subsequently disputes such power or
              authority, you hereby agree to provide or execute and deliver such other documents and/or take such other
              actions required on your part to prove that you have (or had, at all relevant times) such requisite power
              and authority; and (iii) if such Person disputes such power or authority, you shall be liable and shall
              indemnify Traindex and the other Traindex Parties (as defined below) for such dispute and/or for any
              failure of such Person to comply with the terms of these Terms of Use.
            </p>
            <p>
              Traindex shall issue (or has issued) a password to you for purposes of your access and use of the Site and
              the Service. You are responsible for maintaining the confidentiality of all passwords associated with Your
              Account and for ensuring that each password is used only by you. You are entirely responsible for any and
              all activities that occur under or through the use of Your Account and any all charges incurred from use
              of the Services accessed under or through the use of Your Account. You hereby agree to immediately notify
              Traindex of any unauthorized use of Your Account or any other breach of security known to you. Without
              limiting the generality of applicable terms elsewhere in these Terms of Use, Traindex shall have no
              liability for any loss or damage arising from your failure to comply with these requirements.
            </p>
            <p>
              We grant you permission to use the Site subject to the restrictions and other terms set forth in these
              Terms of Use. Additionally, as between you and Traindex, your use of the Site is at your own risk with
              regard to any documents, materials, data, information or communications submitted, published, disseminated
              or transmitted to or through, saved in, displayed on or otherwise contained in or communicated through
              (collectively, “transmitted through”) the Site (“Content”) other than Content and/or Software (as defined
              below) that Traindex itself posts to or otherwise makes available through or in or in connection with the
              Site (“Traindex Content”), and you waive any claim that you may have against Traindex, its affiliates, and
              their respective officers, directors, members, employees and agents, licensees and customers
              (collectively, the “Traindex Parties,” and each, individually, an “Traindex Party”) with regard to any
              Content transmitted through Site by any Person other than Traindex (“Third Party Content”), including but
              not limited to any Content transmitted through the Site by you or any other Person using Your Account
              (whether transmitted via email, ftp, other offline means, bulk upload, browser plugins, through
              annotations or other notes created in the Site or through any other means (“Your Content”)), any other
              Traindex end-user (each, a “User”) or any third party agents, vendors and/or business partner of Traindex
              (each a “Third Party Provider,” and any such Content of a Third Party Provider, “Third Party Provider
              Content”), and you agree and acknowledge that neither Traindex nor any other Traindex Party shall have any
              liability with regard to any claim that you may have that arises out of or in connection with any Third
              Party Content. Any dealings you have with any other User or other Person while using (or that otherwise
              arise out of or in connection with your use of) the Site are between you and such other Person(s), and you
              agree that the Traindex Parties shall not be liable for any loss or claim that you may have against any
              such other Persons.
            </p>
            <p>
              <span className="font-weight-bold">3. User Behavior. </span>
              You agree that while using the Site you will comply with the following rules (in addition to the other
              rules set forth elsewhere in these Terms of Use):
              <ul className=" pl-0">
                <li className="font-weight-normal ml-4">
                  You will not disobey or breach these Terms of Use, the Privacy Policy, or any other applicable
                  instructions conveyed by Traindex (whether via email, posted to the Site or otherwise), or use the
                  Service in any manner or for any purpose other than as expressly permitted by these Terms of Use, the
                  Privacy Policy, any applicable Service Agreement(s) or any other policy, instruction, or terms
                  applicable to the Service that are provided by Traindex on or in connection with the Site.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not violate any Applicable Laws in connection with your access to or use of the Site.
                </li>
                <li className="font-weight-normal ml-4">
                  Your use of the Site and Traindex Content as permitted hereunder shall be solely for your internal
                  business use in accordance with, and subject to the terms of, such Service Agreement.
                </li>
                <li className="font-weight-normal ml-4">
                  You agree that the Site is only intended for manual use, and you will not access the Site through the
                  use of any “bots” or other software applications that may be used to perform automated tasks or
                  scripts, including accessing or in any way using the Site or the Service.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not submit or transmit to, through or in connection with the Site: (i) any unlawful,
                  harassing, libelous, privacy invading, abusive, threatening, defamatory, vulgar, racist, or otherwise
                  harmful or objectionable Content of any kind (including, but not limited to, any Content that depicts
                  nudity, sexual conduct or violence); (ii) any Content that infringes upon any third party right
                  (including, but not limited to, any copyright, trademark, right of publicity or privacy, or any other
                  intellectual property or similar rights, as well as rights associated with any recognizable name,
                  voice, person, or image (collectively “Third Party IP”)); (iii) any advertisements or other
                  promotional materials or information for yourself, Your Content or your services, or for any other
                  Person or such Person’s Content or services, in each case, for purposes of advertising or promoting
                  those services; (iv) any software viruses, Trojan horses, worms or any other malicious application or
                  code; (v) any political or promotional opinions, in each case, for the purpose of promoting those
                  opinions; or (vi) any Content which may constitute or encourage conduct that is fraudulent, a criminal
                  offense or civil wrong or otherwise violates any Applicable Laws.
                </li>
                <li className="font-weight-normal ml-4">
                  While accessing or using the Site, you will not impersonate any Person, make any false statement
                  regarding any Person (including but not limited to, any Person’s employment, agency or affiliation
                  with any other Person), or create false identities for any reason.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not interfere with or disrupt Traindex’s or any other User’s access to or use of the Site.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not take any action that imposes (or may impose, as determined by us in our sole discretion)
                  an unreasonable or disproportionately large load on our servers or other infrastructure underlying the
                  Site, and you will not use the Site in any way that causes (or is likely to cause, as determined by us
                  in our sole discretion) the Site or access to or use of it to be interrupted, interfered with, damaged
                  or impaired in any way, including but not limited to any use of the Site that in any way interferes
                  with or disrupts servers or networks used by Traindex to provide the Service or used by other Users to
                  access the Site or violates any third party regulations, policies or procedures of such servers or
                  networks.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not “scrape” or otherwise use any data mining, robots, or similar data gathering or
                  extraction methods (except as may be the result of standard search engine protocols or technologies
                  used by a search engine with our express consent).
                </li>
                <li className="font-weight-normal ml-4">
                  You will not publish or disclose to third parties any evaluation of the Site or the Service.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not create or submit unwanted email, comments, likes or other forms of commercial or
                  harassing communications (a/k/a “spam”) to any other Users.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not reverse engineer, decompile, disassemble or otherwise attempt to discover the source
                  code, object code or underlying structure, ideas, know-how or algorithm pertaining to the Site or any
                  software, proprietary documentation or data related to the Site (collectively, “Software”).
                </li>
                <li className="font-weight-normal ml-4">
                  You will not modify or alter any part of the Site or any Traindex Content, and you will not
                  circumvent, disable or otherwise interfere with any security-related feature of the Site or any system
                  resources, services or networks connected to or accessible through the Site.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not modify, alter, tamper with, translate, repair or create derivative works based on the
                  Site or any Traindex Content, Third Party Provider Content or Provided Software.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not rent, lease, distribute, sell, resell, assign, or otherwise transfer your rights to use
                  the Site.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not (i) use the Site for timesharing or service bureau purposes or otherwise for the benefit
                  of a third party; (ii) sell, lend, rent, resell, lease, sublicense or otherwise transfer to any third
                  party or commercialize the Site (or any part thereof), including but not limited to any Software or
                  Traindex Content, or any of the rights granted to you with respect to the Service by Traindex; (iii)
                  access or use the Site or the Service in a way intended to improperly avoid incurring fees or
                  exceeding usage limits or quotas; or (iv) download or copy any Content from the Site for your own
                  benefit or for the benefit of another merchant, website or other Person, except as contemplated by
                  these Terms of Use.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not use the Site in automatic, semi-automatic or manual tools designed to create virus
                  signatures, virus detection routines, or any other data or code for detecting malicious code or data.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not use, modify, duplicate, print, transmit or distribute in any manner any Traindex Content
                  or any other reports, research, materials, data or other information viewed, accessed or provided on
                  the Site except as expressly set forth in these Terms of Use or, to the extent your use is expressly
                  permitted by a Service Agreement, solely for your internal business use in accordance with, and
                  subject to the terms of, such Service Agreement.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not attempt to gain access to the Site, other Users’ accounts, Traindex’s computer systems or
                  networks connected to the Site through hacking, password mining or any other similar means.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not advertise products or services or solicit any other User, whether or not such advertising
                  or solicitation is in the form of unrequested bulk commercial email.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not remove, obscure or alter any proprietary rights notice pertaining the Site, the Software,
                  the Service, any Traindex Content or any Third Party Provider Content.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not use any meta tags or any other “hidden text” utilizing any of Traindex’s names or
                  trademarks without our express written consent.
                </li>
                <li className="font-weight-normal ml-4">
                  You will not use the Site or any other part of the Service in connection with the operation of nuclear
                  facilities, aircraft navigation, communications systems, medical devices, air traffic control devices,
                  real time control systems or other situations in which the failure of the Site or the Service could
                  lead to death, personal injury or physical property or environmental damage.
                </li>
                <li className="font-weight-normal ml-4">
                  You further agree not to cause, encourage or permit any third party to do any of the foregoing on your
                  behalf or in any way with your assistance or collaboration, including without limitation by providing
                  other Persons with your username and password or by otherwise authorizing any third party to access or
                  use the Site.
                </li>
              </ul>
            </p>
            <p>
              <span className="font-weight-bold">4. Legal Notices. </span>
              From time to time, we have or will in the future post legal notices to the Site. You shall not remove
              these notices or credits, or any additional information contained along with the notices and credits. The
              Site may contain references to trademarks, copyrighted materials, technologies, products, processes and
              software and other proprietary rights of Traindex or other Persons. Except as necessary for your access to
              and use of the Site as contemplated by these Terms of Use, no license to or right in any such trademarks,
              copyrighted materials, technologies, products, processes, software or other proprietary rights of Traindex
              is granted to or conferred upon you.
            </p>
            <p>
              <span className="font-weight-bold">5. Traindex Content; Ownership. </span>
              You acknowledge that some Traindex Content and other materials accessed as part of the Site may be
              proprietary to Traindex or may be licensed to Traindex by Third Party Providers. You acknowledge and agree
              that, as between Traindex and you, to the fullest extent permitted by Applicable Laws, all right, title
              and interest in and to the Site and any Traindex Content, and all modifications and enhancements thereof
              or thereto, including, without limitation, all rights under copyright and patent and other intellectual
              property rights, belong to and are retained solely by Traindex or Traindex’s licensors and providers, as
              applicable. Traindex reserves all rights not expressly granted under these Terms of Use.
            </p>
            <p>
              You are authorized to access and use the Site and related Traindex Content as necessary for your access to
              and use of the Site as contemplated by these Terms of Use, provided that you continue to, at all times,
              comply in full with these Terms of Use.
            </p>
            <p>
              <span className="font-weight-bold">6. Your Content; No Infringing Use. </span>
              You acknowledge and agree that Traindex is not the owner of and shall not be responsible for Your Content
              or any other Third Party Content. You also acknowledge and agree that we don’t, and could not effectively,
              control, verify, or endorse Your Content or any other Third Party Content.
            </p>
            <p>
              PLEASE BE AWARE THAT (i) VIDEOS, ARTICLES, DOCUMENTS AND/OR OTHER CONTENT THAT YOU UPLOAD TO THE SITE
              (WHICH WOULD CONSTITUTE PART OF YOUR CONTENT) MAY BE PROTECTED BY COPYRIGHT AND OTHER LAWS REGARDING
              INTELLECTUAL PROPERTY RIGHTS; (ii) IF ANY OF YOUR CONTENT CONTAINS OR DEPICTS ANY THIRD PARTY IP, THE
              HOLDER OF THE RIGHTS TO SUCH THIRD PARTY IP MAY HAVE THE RIGHT TO CLAIM THAT USE OF SUCH CONTENT
              (INCLUDING BUT NOT LIMITED TO IN CONNECTION WITH YOUR USE OF THE SITE) INFRINGES ON THE OWNER’S
              INTELLECTUAL PROPERTY RIGHTS; AND (iii) YOU WILL BE SOLELY RESPONSIBLE FOR, AND YOU WILL INDEMNIFY AND
              HOLD HARMLESS THE TRAINDEX PARTIES FOR, ANY AND ALL RISK ASSOCIATED WITH YOUR CONTENT, INCLUDING BUT NOT
              LIMITED TO ANY CLAIM ASSERTING THAT YOUR CONTENT (OR ANY PORTION THEREOF), AND/OR THE STORING IN OR
              SUBMISSION OR TRANSMISSION TO, THROUGH OR IN CONNECTION WITH THE SITE, INFRINGES UPON ANY THIRD PARTY IP
              RIGHTS.
            </p>
            <p>
              In addition to any and all other agreements, acknowledgements, representations and warranties that you
              make in these Terms of Use, as of the date you accept and agree to these Terms of Use:
            </p>
            <ul className=" pl-0">
              <li className="font-weight-normal ml-4">
                You acknowledge and agree that you will: (i) ensure that Your Content (including the storage or
                transmission thereof) complies with these Terms of Use and any and all Applicable Laws; (ii) promptly
                handle and resolve any notices and claims relating to Your Content, including any notices sent to you by
                any Person claiming that any content violates any Third Party IP rights, such as take-down notices
                pursuant to the Digital Millennium Copyright Act and any other notices; and (iii) maintain appropriate
                security, protection and backup copies of Your Content, which may include, your use of additional
                encryption technology to protect Your Content from unauthorized access.
              </li>
              <li className="font-weight-normal ml-4">
                You represent and warrant that you have the full legal right, power and authority to use Your Content in
                connection with your use of the Site and to grant the rights granted by you to Traindex with regard to
                Your Content in these Terms of Use.
              </li>
              <li className="font-weight-normal ml-4">
                You agree that you may not state or imply that Your Content is in any way sponsored or endorsed by
                Traindex or that you are the owner of any Traindex Content.
              </li>
              <li className="font-weight-normal ml-4">
                No portion of Your Content contains any disabling mechanism or protection feature designed to prevent
                its use, copying or enjoyment in the manner contemplated in the Agreements, and all of Your Content will
                be free of any virus, worm, lock, or other mechanism or device that may be used to modify, delete,
                damage or disable the Site or any other hardware or computer system or Software, or which would
                otherwise render inaccessible or impair the use of any of the same in any way. None of Your Content has
                been obtained, created or submitted to us in violation of any Applicable Law. None of Your Content is
                subject to any terms or condition that might be breached by such Content being used as contemplated by
                these Terms of Use and any applicable Service Agreement(s).
              </li>
              <li className="font-weight-normal ml-4">
                If after you submit Your Content to us, you receive any notice or otherwise learn in any way that any of
                the representations made by you in above were not accurate, complete and not misleading in all material
                respects or no longer are accurate, complete and not misleading in all material respects or of any claim
                by a third party to the effect of the foregoing, you will promptly provide us with written notice all
                relevant facts regarding the same and if appropriate, remove the Content from the Site.
              </li>
              <li className="font-weight-normal ml-4">
                You acknowledge that Traindex has policies and processes which must be adhered to prior to Content being
                posted on the Site. You further acknowledge and agree that Traindex does not and cannot review all
                Content uploaded to the Site and is not responsible for the content, quality, or consequences of you
                uploading any of Your Content to the Site, and Traindex reserves the right to delete, move, refuse to
                accept or edit any Content (including but not limited to Your Content) that Traindex may determine, in
                its sole discretion, violates or may violate the terms of the Traindex Terms of Use, any Third Party IP
                right(s), any of the policies of any other Person or is otherwise unacceptable, in Traindex’s sole
                discretion.
              </li>
            </ul>
            <p>
              Traindex respects the intellectual property of others, and we expect all Users to do the same. It is our
              policy, in appropriate circumstances and at our discretion, to disable or terminate the accounts of any
              User who infringe or attempt to infringe the copyrights or other intellectual property rights of Traindex
              or any Third Party IP rights.
            </p>
            <p>
              We provide functions that allow you to control who may access Your Content. You acknowledge that, if you
              enable the features that allow you to share Your Content with others, anyone you’ve shared Your Content
              with (including the general public, in certain circumstances) may have access to Your Content.
              Furthermore, you agree that any such Content can be read by the operators and/or other agents of Traindex,
              whether or not they are the intended recipients. If we or any other Person asks you to cease and desist
              from sharing any of Your Content with any third party, you agree to comply with such request immediately.
            </p>
            <p>
              You hereby grant Traindex and its contractors the right to transmit, use and disclose Your Content solely
              to the extent necessary to provide you with access to and use of the Site, as otherwise permitted by these
              Terms of Use, or as may be necessary or appropriate to comply with any request of a governmental or
              regulatory body (including subpoenas or court orders), as otherwise required or permitted by law, or to
              respond to an emergency which Traindex believes in good faith requires Traindex to disclose information to
              assist in preventing the death or serious bodily injury of any Person.
            </p>
            <p>
              TRAINDEX WILL HAVE NO LIABILITY OF ANY KIND AS A RESULT OF THE DELETION OF, CORRECTION OF, DESTRUCTION OF,
              DAMAGE TO, LOSS OF OR FAILURE TO STORE OR ENCRYPT ANY OF YOUR CONTENT.
            </p>
            <p>
              <span className="font-weight-bold">7. Updates to the service. </span>
              Traindex reserves the right, in its sole discretion, to make unscheduled deployments of updates,
              enhancements or such other changes to the Site at any time that Traindex deems necessary or otherwise
              desirable, in each case to the full extent permitted by all applicable local, state, national or
              international laws, statutes, ordinances, rules and regulations (“Applicable Laws”) and without notice or
              liability to Traindex. For example, we may add or remove functionalities or features, and we may suspend
              or stop a Service altogether.
            </p>
            <p>
              <span className="font-weight-bold">8. Software. </span>
              If any Software of Traindex or any Third Party Provider(s) is provided to you by Traindex in connection
              with your use of the Site (“Provided Software”), the use of such Software shall be governed in one of two
              ways: (i) If you’re presented with license terms that you must accept in order to use any Provided
              Software, those terms apply; and (ii) if no license terms are presented to you, these Terms of Use shall
              apply. We reserve all other rights to any Provided Software. We may automatically check your version of
              any Provided Software. We may also automatically download to your computer or device new versions of any
              such Software.
            </p>
            <p>
              Any Provided Software is licensed, not sold. Unless we notify you otherwise, any license to any Provided
              Software granted hereby Software license shall automatically terminate when your right to use the Site
              ends. You must then promptly uninstall any Provided Software, or we may disable it. You must not work
              around any technical limitations in any Provided Software.
            </p>
            <p>
              Any and all Provided Software shall be subject to applicable U.S. export laws and regulations. You hereby
              agree to complying with all domestic and international export laws and regulations that apply to any
              Provided Software. These laws include restrictions on destinations, end users, and end use. Without
              limitation, you may not transfer any Provided Software or any right granted to you by Traindex to access
              or use the Site (the “Service”) without U.S. government permission to anyone on U.S. government exclusion
              lists (see the Commerce Department’s compliance list at
              <a href="http://www.bis.doc.gov/index.php/policy-guidance/lists-of-parties-of-concern">
                http://www.bis.doc.gov/index.php/policy-guidance/lists-of-parties-of-concern.
              </a>
              You hereby represent and warrant that you’re not on any of those lists or under the control of or an agent
              for anyone on those lists or the entities listed above.
            </p>
            <p>
              <span className="font-weight-bold">9. Ideas, Suggestions, Feedback or Data Shared with Traindex. </span>
              If you choose to send us any message, documents, information and/or data, including but not limited to any
              ideas, suggestions, questions, feedback and/or proposals regarding the Site or the Service (or other
              products or services), such message, documents, information and/or data shall be deemed non-confidential
              and we shall have no obligation of any kind with respect to such message, documents, information and/or
              data and shall be free to disclose and/or distribute any such message, documents, information and/or data
              to others without limitation and to reproduce and use any ideas, concepts, know-how or techniques
              contained in such message, documents, information and/or data, including without limitation to develop,
              manufacture, market and produce products and services incorporating such ideas, concepts, know-how or
              techniques.
            </p>
            <p>
              <span className="font-weight-bold">10. Security. </span>
              You shall not violate or attempt to violate the security of the Site. Violations of system or network
              security may result in civil or criminal liability. Traindex reserves the right to investigate occurrences
              which may involve such violations and may involve and cooperate actively with, law enforcement authorities
              in prosecuting users who have participated in such violations.
            </p>
            <p>
              <span className="font-weight-bold">11. Third-Party Links, Sites, and Services. </span>
              The Site may contain links to third-party websites, advertisers, services, special offers, or other events
              or activities that are not owned or controlled by Traindex. We do not endorse or assume any responsibility
              for any such third-party sites, information, materials, products, or services. If you access any third
              party website, service, or content through or in connection with your access or use of the Site, you do so
              at your own risk and you agree that Traindex will have no liability arising from your use of or access to
              any third-party website, service, or content.
            </p>
            <p>
              <span className="font-weight-bold">12. Privacy. </span>
              Your privacy is important to us. Our Privacy Policy
              <a href="/privacy">(https://www.traindex.io/privacy-policy/) </a>
              is incorporated into, subject to and made part of these Terms of Use.<br></br>In order to operate and
              provide the Service, we collect certain information about you. As part of the Service, we may also
              automatically upload information about your computer or device, your use of the Service, and Service
              performance.
            </p>
            <p>
              <span className="font-weight-bold">13. Indemnity. </span>
              You shall indemnify, defend, hold harmless the Traindex Parties against any liability or obligation
              arising out of or related to any breach (or any claim of breach or alleged breach) of these Terms of Use
              or any applicable Service Agreement(s) by you, your affiliates, directors, officers, employees or agents,
              you shall pay any final judgment entered against any of the Traindex Parties in any such proceeding or
              agreed to in settlement. Traindex will promptly notify you in writing of such claim or suit (provided that
              any delay by Traindex in providing such written notice shall not affect Traindex’s rights under this
              section unless and only to the extent you are materially prejudiced by such delay), and Traindex will use
              commercially reasonable efforts to provide such available information and non-monetary assistance you
              reasonably request.
            </p>

            <p>
              <span className="font-weight-bold">14. Disclaimer. </span>
              Traindex does not warrant, endorse or guarantee the completeness, accuracy, integrity or timeliness of any
              information provided via the Site, and we are not responsible for mistakes, out-of-date information,
              inaccuracies, typographical or other errors. You must evaluate, and bear all risks associated with, the
              use of any Content (including but not limited to Third Party Content) or other information included in the
              Site, including any reliance on the accuracy, completeness, safety or usefulness of such information. You
              acknowledge that any information included in the Site is subject to change without notice. Any opinions or
              assertions contained in the Site at any time or from time to time do not represent the opinions or beliefs
              of Traindex and/or its affiliates. Traindex and/or one or more of its directors, officers, employees,
              representatives or agents may have a position in any of the securities mentioned in Content included from
              time to time in the Site.
            </p>
            <p>
              Notwithstanding anything to the contrary in these Terms of Use or in any Service Agreement, we reserve the
              right to change, suspend, remove, or disable access to the Site, any Content or other materials comprising
              a part of the Site or the Service at any time without notice, provided that we agree to at all times
              materially comply with our obligations under any applicable Service Agreement(s). In no event will we be
              liable for making these changes (provided that we have materially complied with our obligations under
              applicable Service Agreement(s)). Furthermore, any such change, suspension, removal or disabling of access
              to the Site, any Content or other materials comprising a part of the Site or the Service shall not act as
              a waiver of any breach of these Terms of Use or any applicable Service Agreement(s) and shall not release
              you or any other Person(s) from any liability related to any such Person’s breach of such Person’s
              obligations under these Terms of Use or any applicable Service Agreement(s).
            </p>
            <p>
              As a registered user in good standing, you may be provided with limited access to the videos, articles or
              other Content saved to the Site. Such access is provided as an accommodation only, and Your Content (or
              any portion thereof) may be deleted at any time and for any reason. We do not warrant, and will not have
              any liability or responsibility for, your use of the Site or the Service (or any part or portion thereof).
              We may also impose limits on the use of or access to certain features or portions of the Site and/or the
              Service, for any reason and without notice or liability. The Site and the Service (or any part or portion
              thereof) may be unavailable from time to time due to mechanical, telecommunication, software, and other
              failures. We cannot predict or control when such downtime may occur and cannot control the duration of
              such downtime.
            </p>
            <p>
              YOUR USE OF THE SITE IS AT YOUR SOLE RISK. TRAINDEX MAKES NO WARRANTY THAT THE SITE WILL MEET YOUR
              REQUIREMENTS. THE SITE, TRAINDEX CONTENT AND ALL OTHER INFORMATION OR CONTENT CONTAINED IN THE SITE IS
              PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, EXPRESS, STATUTORY OR
              IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY, TITLE, CUSTOM, TRADE, QUIET
              ENJOYMENT, ACCURACY OF INFORMATION CONTENT OR SYSTEM INTEGRATION. TRAINDEX DOES NOT WARRANT THAT THE SITE
              OR THE SERVICES WILL BE AVAILABLE OR OPERATE IN AN UNINTERRUPTED, ERROR-FREE OR COMPLETELY SECURE MANNER
              OR THAT ERRORS OR DEFECTS WILL BE CORRECTED.
            </p>
            <p>
              <span className="font-weight-bold">15. Limitation of Liability. </span>
              IN NO EVENT SHALL ANY OF THE TRAINDEX PARTIES’ OR TRAINDEX’S SUPPLIERS OR LICENSORS OR PROVIDERS’
              AGGREGATE LIABILITY, IF ANY, ARISING OUT OF OR IN ANY WAY RELATED TO THE SITE OR THE SERVICE EXCEED THE
              FEES PAID BY YOU FOR THE SERVICE FOR THE PERIOD OF TWELVE (12) MONTHS PRIOR TO THE EVENT THAT DIRECTLY
              GAVE RISE TO THE DAMAGES CLAIMED, WITHOUT REGARD TO WHETHER SUCH CLAIM IS BASED IN CONTRACT, TORT
              (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE.
            </p>
            <p>
              IN NO EVENT SHALL TRAINDEX, ANY OF THE OTHER TRAINDEX PARTIES OR TRAINDEX’S SUPPLIERS OR LICENSORS OR
              PROVIDERS BE LIABLE FOR ANY INDIRECT, EXEMPLARY, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING,
              WITHOUT LIMITATION, LOSS OF DATA OR LOSS OF PROFITS, WITHOUT REGARD TO WHETHER SUCH CLAIM IS BASED IN
              CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF
              TRAINDEX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
              <span className="font-weight-bold">16. Limited Time to Bring Your Claim. </span>
              You and Traindex agree that any cause of action arising out of or related to the Service or any Traindex
              Content must be commenced within one (1) year after the cause of action arises. Otherwise, such cause of
              action is permanently barred.
            </p>
            <p>
              Notwithstanding anything to the contrary in these Terms of Use or in any Service Agreement, we reserve the
              right to change, suspend, remove, or disable access to the Site, any Content or other materials comprising
              a part of the Site or the Service at any time without notice, provided that we agree to at all times
              materially comply with our obligations under any applicable Service Agreement(s). In no event will we be
              liable for making these changes (provided that we have materially complied with our obligations under
              applicable Service Agreement(s)). Furthermore, any such change, suspension, removal or disabling of access
              to the Site, any Content or other materials comprising a part of the Site or the Service shall not act as
              a waiver of any breach of these Terms of Use or any applicable Service Agreement(s) and shall not release
              you or any other Person(s) from any liability related to any such Person’s breach of such Person’s
              obligations under these Terms of Use or any applicable Service Agreement(s).
            </p>
            <p>
              <span className="font-weight-bold">17. Local Laws; Export Control. </span>
              Traindex controls and operates the Site from its headquarters in the United States of America and the
              Traindex Content may not be appropriate or available for use in other locations. If you use the Site
              outside the United States of America, you are responsible for following local Applicable Laws.
            </p>
            <p>
              <span className="font-weight-bold">18. Agreement to Arbitrate. </span>
              These Terms of Use are governed by and construed in accordance with the laws of the State of Washington,
              without giving effect to any principles of conflicts of law. For any action at law or in equity relating
              to the arbitration provision of these Terms of Use, you agree to resolve any dispute you have with
              Traindex exclusively in a state or federal court located in Seattle, Washington, and to submit to the
              personal jurisdiction of the courts located in Seattle, Washington for the purpose of litigating all such
              disputes.
            </p>
            <p>
              <span className="font-weight-bold">19. Governing Law; Venue. </span>
              Traindex controls and operates the Site from its headquarters in the United States of America and the
              Traindex Content may not be appropriate or available for use in other locations. If you use the Site
              outside the United States of America, you are responsible for following local Applicable Laws.
            </p>
            <p>
              If any provision of these Terms of Use is held to be unlawful, void, or for any reason unenforceable
              during arbitration or by a court of competent jurisdiction, then that provision will be deemed severable
              from these Terms of Use and will not affect the validity and enforceability of any remaining provisions.
              This dispute resolution provision will survive the termination of any or all of your transactions with
              Traindex.
            </p>
            <p>
              The parties agree that the United Nations Convention for the International Sale of Goods is excluded in
              its entirety from these Terms of Use.
            </p>
            <p>
              <span className="font-weight-bold">20. International Access. </span>
              The Site may be accessed from countries other than the United States. We make no representations or
              warranties that the Site or the Service are appropriate for use or access in other locations. If you
              access and use the Site or the Service outside the United States, you do so on your own initiative and you
              are responsible for complying with both United States and local laws and regulations, if and to the extent
              such laws are applicable
            </p>
            <p>
              You may not use, export, re-export, import or transfer the Site or the Service (or any portion thereof or
              any rights thereto granted by Traindex) except as authorized by United States Applicable Laws, the laws of
              the jurisdiction in which you gained access to the Service and the Site, and any other Applicable Laws. In
              particular, but without limitation, neither the Site nor the Service (nor any portion thereof or any
              rights thereto granted by Traindex) may be exported or re-exported: (a) into any United States embargoed
              countries; or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or
              the U.S. Department of Commerce’s Denied Person’s List or Entity List. By accessing and using the Site
              and/or the Service, you represent and warrant that you are not located in any such country or on any such
              list. You also may not use the Site or the Service for any purpose prohibited by United States Applicable
              Laws, including the development, design, manufacture or production of missiles, nuclear, chemical or
              biological weapons. We reserve the right to limit, in our sole discretion, the availability of the Site
              and/or the Service (or any portion thereof) to any Person, geographic area, or jurisdiction, at any time.
            </p>
            <p>
              <span className="font-weight-bold">21. U.S. Government Restricted Rights. </span>
              If this product is acquired under the terms of a government contract, use, duplication and disclosure are
              subject to the terms of this license and the following restrictions: subdivision (c)(1)(ii) of the Rights
              in Technical data and Computer Software clause at 252.227-7013 (DOD contracts); subdivisions (a) through
              (d) of 52.227-19 (Civilian agency contracts); and the applicable ADP Schedule Contract (GSA contracts).
            </p>
            <p>
              <span className="font-weight-bold">22. Consent to Electronic Communication. </span>
              By inputting any information on the Site, you are consenting to receive communications from us
              electronically and you agree that all agreements, notices, disclosures and other communications that we
              provide to you electronically satisfy any legal requirement that such communications be in writing.
            </p>
            <h3>Trademark information</h3>
            <p>
              <span className="font-weight-bold">23. Proprietary Rights Notice. </span>
              Traindex and the Traindex logo are registered service marks of Traindex. All other Traindex trademarks,
              service marks, domain names, logos and company names or other proprietary designations of Traindex used
              herein or referred to on the Site are either trademarks, service marks, domain names, logos or company
              names or are otherwise the property of Traindex. In countries where any of the Traindex trademarks,
              service marks, domain names, logos or company names are not registered, Traindex claims other rights
              associated with unregistered trademarks, service marks, domain names, logos, trade name and company names.
              Other product or company names referred to on the Site may be trademarks of their respective owners. You
              may not use any trademark, service mark, domain name, logo, company name or trade name of Traindex or any
              third party without permission from the owner of the applicable trademark, service mark, domain name,
              logo, company name or trade name. You may contact Traindex by sending an e-mail or writing to the address
              above, to request written permission to use trademarks and materials on the Site for purposes other than
              stated in these Terms of Use or for all other questions relating to the Site.
            </p>
            <p>
              <span className="font-weight-bold">24. Miscellaneous. </span>
              Both you and Traindex acknowledge and agree that no partnership is formed, and neither of you nor Traindex
              has the power or the authority to obligate or bind the other.
            </p>
            <p>
              These Terms of Use operate to the fullest extent permissible by law. If any provision of these Terms of
              Use is unlawful, void, or unenforceable, that provision is deemed severable from these Terms of Use and
              does not affect the validity and enforceability of any remaining provisions.
            </p>
            <p>
              Traindex’s failure to insist upon or enforce strict performance of any provision of these Terms of Use
              will not be construed as a waiver of any provision or right. No waiver of any of these Terms of Use will
              be deemed a further or continuing waiver of such term or condition or any other term or condition.
            </p>
            <p>
              Neither party shall be in default if its failure to perform any obligation under these Terms of Use is
              caused solely by supervening conditions beyond that party’s reasonable control including, without
              limitation, acts of God, civil commotion, war, strikes, labor disputes, third party Internet service
              interruptions or slowdowns, vandalism or “hacker” attacks, acts of terrorism or governmental demands or
              requirements.
            </p>

            <p>
              These Terms of Use constitute a binding agreement between you and Traindex, and are accepted by you as a
              condition for your use of the Site. These Terms of Use constitute the entire agreement between you and
              Traindex regarding the use of the Site and supersede all prior or contemporaneous communication or
              proposal (whether oral, written or electronic) between you and Traindex (other than any applicable Service
              Agreement).
            </p>
            <p>
              The Traindex Parties are intended third party beneficiaries of these Terms of Use. Nothing in these Terms
              of Use or the guidelines, policies on the Site, express or implied, is intended to or shall confer upon
              any third person or entity other than the Traindex Parties any rights, benefits or remedies of any nature
              whatsoever. Without limiting the generality of the foregoing, no provision herein shall be for the benefit
              of or enforceable by any creditor of any party hereto.
            </p>
            <p>
              Your obligations and our rights and remedies set out in these Terms of Use are cumulative and are in
              addition to your obligations and our rights and remedies at law or in equity.
            </p>
            <p>
              Traindex may assign these Terms of Use without your consent to any other party so long as such party
              agrees to be bound by its terms.
            </p>
            <p>
              These Terms of Use are in addition to the policies, guidelines and restrictions contained on the Site
              (which are all incorporated by this reference into these Terms of Use).
            </p>
            <p>
              Unless the context requires otherwise, in any part of these Terms of Use: (a) “including” (and any of its
              derivative forms, e.g. “includes”), “e.g.” and “for example” means “including but not limited to”; (b)
              “must not”, “should not”, “shall not” and “may not” are expressions of prohibition, and “will”, “must”,
              “should” and “shall” are expressions of command, and not merely expressions of future intent or
              expectation; (c) use of the singular imports the plural and vice versa; (d) references to one or no gender
              include the other or no gender; (e) references to the terms “herein” or “hereto” refer to these Terms of
              Use (including any terms incorporated by reference herein); (f) the headings in these Terms of Use are for
              ease of reference only and shall not affect its interpretation; and (g) when calculating the time period
              before which, within which or following which any act is to be done or step taken pursuant to these Terms
              of Use, the date that is the reference date in calculating such period shall be excluded and the time
              period shall be deemed to end at 11:59 PM Pacific Time (US) on the applicable date.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default TermsOfUseText
