import React, { useState } from "react"
import { Button, Label, Input, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp, faChevronDown, faFilter } from "@fortawesome/free-solid-svg-icons"
import "./chatbot.css"
const Filter = ({
  priorityDValue,
  toggleValue,
  publicationDValue,
  cpcValue,
  kindCodeValue,
  onChangePriorityD,
  onChangePublicationD,
  cpcHandleChange,
  priorityCondition,
  publicationCondition,
  kindCodeHandleChange,
  handleClearSearch,
  onChangePriorityCondition,
  onChangeToggleValue,
  onChangePublicationCondition,
}) => {
  const [ddOpen, setDdOpen] = useState(false)
  const toggleDD = () => setDdOpen((prev) => !prev)

  return (
    <Dropdown isOpen={ddOpen} toggle={toggleDD}>
      <DropdownToggle color="primary" outline className="mb-3 w-100 d-flex align-items-center justify-content-between">
        <FontAwesomeIcon icon={faFilter} size="xs" className="px-1" />
        Search Criteria
        {ddOpen ? (
          <FontAwesomeIcon icon={faChevronUp} size="xs" className="px-1" />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} size="xs" className="px-1" />
        )}
      </DropdownToggle>
      <DropdownMenu className="p-2 mt-2 w-100">
        <div className="pbox d-flex flex-column mb-3 p-2">
          <Label htmlFor="priorityInputField">Priority Date </Label>
          <div className="d-flex">
            <Input
              className="p-1 mr-2 mb-3 col-4"
              type="select"
              value={priorityCondition}
              onChange={onChangePriorityCondition}
            >
              <option value="Before">Before</option>
              <option value="After">After</option>
              <option value="Equal">Equal</option>
            </Input>
            <Input
              type="date"
              id="priorityInputField"
              className=""
              value={priorityDValue}
              onChange={onChangePriorityD}
            />
          </div>
        </div>
        <div className="pbox d-flex flex-column mb-3 p-2">
          <Label htmlFor="publicationInputField">Publication Date </Label>
          <div className="d-flex">
            <Input
              className="p-1 mr-2 mb-3 col-4"
              type="select"
              value={publicationCondition}
              onChange={onChangePublicationCondition}
            >
              <option value="Before">Before</option>
              <option value="After">After</option>
              <option value="Equal">Equal</option>
            </Input>
            <Input
              type="date"
              id="publicationInputField"
              className=""
              value={publicationDValue}
              onChange={onChangePublicationD}
            />
          </div>
        </div>
        <div className="d-flex flex-column mb-3">
          <Label htmlFor="cpcInputField">CPC </Label>
          <Input
            type="text"
            id="cpcInputField"
            placeholder="CPC string"
            className=""
            value={cpcValue}
            onChange={cpcHandleChange}
          />
        </div>
        <div className="d-flex flex-column mb-3">
          <Label htmlFor="kindCodeInputField">Kind Code </Label>
          <Input
            type="text"
            id="kindCodeInputField"
            maxLength="2"
            pattern="\d*"
            placeholder="2 digit code"
            inputMode="numeric"
            value={kindCodeValue}
            onChange={kindCodeHandleChange}
          />
        </div>
        <DropdownItem divider />
        <Button
          onClick={handleClearSearch}
          outline
          color="primary"
          className="mt-3 w-100"
          disabled={!priorityDValue && !publicationDValue && !cpcValue && !kindCodeValue}
        >
          Clear search
        </Button>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Filter
