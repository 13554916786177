import React, { createContext, useContext, useEffect, useReducer } from "react"
import { getCurrentUser } from "../utils/auth.utils"
import Users from "../api/UsersAPI"
import reducer, { initialState } from "./reducer"

export const StateContext = createContext()

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function getUser() {
      const userObj = await getCurrentUser()
      if (userObj) {
        const emailStatus = userObj.attributes.email_verified
        const userId = userObj.attributes.sub
        try {
          const userData = await Users.get(userId)
          const subscription = userData.subscription
          userData.stripe_subscription_id = subscription.id
          userData.last4 = userData.organization.last4
          userData.email_verified = emailStatus ? true : false
          userData.role = userObj.attributes["custom:role"]
          dispatch({
            type: "SET_USER",
            user: userData
          })
        } catch (error) {
          dispatch({
            type: "SET_USER_ERROR",
            error: error.message,
            user: userObj
          })
        }

      }
    }
    getUser()
  }, [])

  const getUserInfo = async () => {
    const userObj = await getCurrentUser()
    const emailStatus = userObj.attributes.email_verified
    const userId = userObj.attributes.sub
    try {
      const userData = await Users.get(userId)
      const subscription = userData.subscription
      userData.stripe_subscription_id = subscription.id
      userData.last4 = userData.organization.last4
      userData.email_verified = emailStatus ? true : false
      userData.role = userObj.attributes["custom:role"]
      dispatch({
        type: "SET_USER",
        user: userData
      })
    } catch (error) {
      dispatch({
        type: "SET_USER_ERROR",
        error: error.message,
        user: userObj
      })
    }
  }

  return <StateContext.Provider value={{ state, getUserInfo, dispatch }}>{children}</StateContext.Provider>
}

export const useStateValue = () => useContext(StateContext)
