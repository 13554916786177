import client from "./APIClient"

export default class PaymentMethod {
  static create = (body) => {
    return client("payment-method", {method: "POST", body})
  }

  static async update(body) {
    return client("payment-method", {method: "PATCH", body})
  }

  static async verify(body) {
    return client("bank-account-verify", {method: "PATCH", body})
  }
}
