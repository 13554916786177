import React, { useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import ReactMarkdown from "react-markdown"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container } from "reactstrap"
import Scrollspy from "react-scrollspy"
import { CopyBlock, monoBlue } from "react-code-blocks"
import "./apiDocs.css"
import { useStateValue } from "../../contexts/StateProvider"

const ApiDocumentation = ({ match }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false),
    { state } = useStateValue(),
    { user } = state,
    apis = user?.organization.apis,
    introRef = useRef(),
    endpointRef = useRef(),
    queryStructureRef = useRef(),
    vectorsRef = useRef(),
    dictionaryRef = useRef(),
    authRef = useRef(),
    errorsRef = useRef()

  const {
    params: { index_name }
  } = match

  const apiEndpoint = (index_name === "us-patents-index" && user) ? apis?.us_patents_endpoint : 
    ((index_name === "wikipedia" && user) ? apis?.wiki_endpoint :
      ((index_name === "arxiv" && user) ? apis?.arxiv_endpoint : `https://api/traindex.io/${index_name}`))

  const apiKey = (index_name === "us-patents-index" && user) ? apis?.us_patents_api_keys[0].api_key : 
    ((index_name === "wikipedia" && user) ? apis?.wiki_api_keys[0].api_key : 
      ((index_name === "arxiv" && user) ? apis?.arxiv_api_keys[0].api_key : "***********************"))

  const introText = (index_name === "wikipedia") ? `
  ## Introduction
  What is Traindex Wikipedia API?

  Traindex is a search service for corporate datasets, powered by machine learning.
  Specifically, it is a semantic text similarity service served as an API.

  Traindex applied a model on articles extracted from Wikipedia and served it 
  as an API that your applications can consume.` : 
    (index_name === "arxiv") ? `## Introduction
  What is Traindex arXiv API?

  Traindex is a search service for corporate datasets, powered by machine learning.
  Specifically, it is a semantic text similarity service served as an API.

  Traindex applied a model on articles extracted from "arXiv - a free distribution service and an open-access archive 
  for 1,856,320 scholarly articles in the fields of physics, mathematics, computer science, quantitative biology, 
  quantitative finance, statistics, electrical engineering and systems science, and economics" and served it 
  as an API that your applications can consume.` : 
      `## Introduction
  What is Traindex Patents API?

  Traindex is a search service for corporate datasets, powered by machine learning.
  Specifically, it is a semantic text similarity service served as an API.

  Traindex applied a model on patents extracted from "Google public patents data" and served it 
  as an API that your applications can consume.`
  

  const endpointText = `
  ## API Endpoint
  Each user of Traindex has a unique API endpoint assigned to them. The structure of the API URI is as follows:

  - An endpoint that starts with https://
  - The hostname part of the URI for all Traindex customers is: api.traindex.io
  - The path parameter. This is usually the name of the user organization.    

  >API endpoint: ${apiEndpoint}  `

  const queryText = `
  ## Query Structure
  Following is a sample search query:
  `

  /* eslint-disable max-len */
  const queryCode = String.raw`curl --request POST \
    --url ${apiEndpoint}/query \
    --header 'content-type: application/json' \
    --header 'x-api-key: ${apiKey}' \
    --data '{"request": {"conceptFeaturesString": "A method for recovering and/or processing oil to be recovered from water, which method comprises providing an ice-breaker or ice-breaking vessel, the ice-breaker or ice-breaking vessel having a single hull, an anti-roll system and/or a heeling system including one or more pairs of tanks located inside said hull on opposite sides thereof, a first set of connecting pipes for transferring water and air between tanks of the anti-roll system and/or a heeling system","maxNumItems": 5}}'`
  /* eslint-disable max-len */

  const paramsText = `
  ### Parameters
  - url: URL to the API endpoint.
  - x-api-key: This is the API key which you can get from your Traindex account.
  - data: It will contain the data you want to request.
  - conceptFeaturesString: This parameter is used to send the query document/patent to our API.
  - maxNumItems: the maximum number of results you want to get back.


  The above query results in the following response:`

  const queryResponse = (index_name === "wikipedia") ? `
  {
    "totalFoundItems": 5,
    "foundItems": [
        {
            "ID": "41111",
            "dbindex": "796773",
            "title": "Endurability",
            "link": "https://en.wikipedia.org/wiki/Endurability",
            "conceptScore": 0.7781573534011841
        },
        {
            "ID": "4521831",
            "dbindex": "189124",
            "title": "Supervisory control",
            "link": "https://en.wikipedia.org/wiki/Supervisory_control",
            "conceptScore": 0.7681357264518738
        },
        {
            "ID": "845208",
            "dbindex": "119042",
            "title": "Seal (mechanical)",
            "link": "https://en.wikipedia.org/wiki/Seal_(mechanical)",
            "conceptScore": 0.7673228979110718
        },
        {
            "ID": "3074923",
            "dbindex": "912727",
            "title": "Leak",
            "link": "https://en.wikipedia.org/wiki/Leak",
            "conceptScore": 0.763748824596405
        },
        {
            "ID": "7899396",
            "dbindex": "545494",
            "title": "Aquacase",
            "link": "https://en.wikipedia.org/wiki/Aquacase",
            "conceptScore": 0.7437938451766968
        }
    ]
}` : (index_name === "arxiv") ? `
{
  "totalFoundItems": 5,
  "foundItems": [
      {
          "id": "705.0025",
          "title": "Can the Internet cope with stress?",
          "authors": "Andreas Martin Lisewski",
          "journal_ref": "nan",
          "update_date": "2007-05-23",
          "conceptScore": 0.27704310417175293
      },
      {
          "id": "1306.6328",
          "title": "The International Linear Collider Technical Design Report - Volume 3.II:  Accelerator Baseline Design",
          "authors": "Chris Adolphsen (SLAC), Maura Barone (Fermilab), Barry Barish  (Caltech), Karsten Buesser (DESY), Philip Burrows (University of Oxford,  Particle Physics Department), John Carwardine (ANL), Jeffrey Clark  (Fermilab), Hélène Mainaud Durand (CERN), Gerry Dugan (Cornell  University), Eckhard Elsen (DESY), Atsushi Enomoto (KEK), Brian Foster  (DESY), Shigeki Fukuda (KEK), Wei Gai (ANL), Martin Gastal (CERN), Rongli  Geng (Jefferson Lab), Camille Ginsburg (Fermilab), Susanna Guiducci (LNF),  Mike Harrison (BNL), Hitoshi Hayano (KEK), Keith Kershaw (CERN), Kiyoshi Kubo  (KEK), Victor Kuchler (Fermilab), Benno List (DESY), Wanming Liu (ANL),  Shinichiro Michizono (KEK), Christopher Nantista (SLAC), John Osborne (CERN),  Mark Palmer (Fermilab), James McEwan Paterson (SLAC), Thomas Peterson  (Fermilab), Nan Phinney (SLAC), Paolo Pierini (INFN, Laboratorio LASA), Marc  Ross (SLAC), David Rubin (Cornell University), Andrei Seryi (University of  Oxford), John Sheppard (SLAC), Nikolay Solyak (Fermilab), Steinar Stapnes  (CERN), Toshiaki Tauchi (KEK), Nobu Toge (KEK), Nicholas Walker (DESY), Akira  Yamamoto (KEK), Kaoru Yokoya (KEK)",
          "journal_ref": "nan",
          "update_date": "2013-06-28",
          "conceptScore": 0.26382333040237427
      },
      {
          "id": "904.2216",
          "title": "Tridiagonal realization of the anti-symmetric Gaussian $\\beta$-ensemble",
          "authors": "Ioana Dumitriu, Peter J. Forrester",
          "journal_ref": "nan",
          "update_date": "2015-05-13",
          "conceptScore": 0.26248809695243835
      },
      {
          "id": "1201.3937",
          "title": "Mixture Likelihood Ratio Scan Statistic for Disease Outbreak Detection",
          "authors": "Michael D. Porter and Jarad B. Niemi and Brian J. Reich",
          "journal_ref": "nan",
          "update_date": "2012-01-20",
          "conceptScore": 0.2616485059261322
      },
      {
          "id": "1307.7466",
          "title": "Integration of 3D Object Recognition and Planning for Robotic  Manipulation: A Preliminary Report",
          "authors": "Damien Jade Duff and Esra Erdem and Volkan Patoglu",
          "journal_ref": "nan",
          "update_date": "2013-07-31",
          "conceptScore": 0.26011067628860474
      }
  ]
}` : `
    "totalFoundItems": 5,
    "foundItems": [
        {
            "app_num": "US-14-000009",
            "pub_num": "2014190901",
            "grant_num": "9605398",
            "ucid": "US-9605398-B2",
            "application_number": "US-201214000009-A",
            "publication_date": "20170328",
            "filing_date": "20120216",
            "grant_date": "20170328",
            "priority_date": "20110216",
            "assignee": "SNELLMAN LARS, VALTARI KAI, SUUTARLA HANNA, ARCTIA SHIPPING OY",
            "priority_number": "FI-20110053-A, FI-2012000009-W",
            "conceptScore": 0.5993363857269287
        },
        {
            "app_num": "US-07-443631",
            "pub_num": "",
            "grant_num": "5010835",
            "ucid": "US-5010835-A",
            "application_number": "US-44363189-A",
            "publication_date": "19910430",
            "filing_date": "19891130",
            "grant_date": "19910430",
            "priority_date": "19881202",
            "assignee": "MITSUI SHIPBUILDING ENG",
            "priority_number": "JP-2666589-U, JP-15657988-U, JP-1080589-U, JP-15657888-U",
            "conceptScore": 0.4359312057495117
        },
        {
            "app_num": "US-08-644254",
            "pub_num": "",
            "grant_num": "5660131",
            "ucid": "US-5660131-A",
            "application_number": "US-64425496-A",
            "publication_date": "19970826",
            "filing_date": "19960510",
            "grant_date": "19970826",
            "priority_date": "19960510",
            "assignee": "MARINETTE MARINE CORP",
            "priority_number": "US-64425496-A",
            "conceptScore": 0.42032840847969055
        },
        {
            "app_num": "US-09-402765",
            "pub_num": "",
            "grant_num": "6162105",
            "ucid": "US-6162105-A",
            "application_number": "US-40276599-A",
            "publication_date": "20001219",
            "filing_date": "19770909",
            "grant_date": "20001219",
            "priority_date": "19970411",
            "assignee": "NORSKE STATS OLJESELSKAP",
            "priority_number": "NO-971690-A, NO-9700241-W",
            "conceptScore": 0.41880133748054504
        },
        {
            "app_num": "US-08-512990",
            "pub_num": "",
            "grant_num": "5511504",
            "ucid": "US-5511504-A",
            "application_number": "US-51299095-A",
            "publication_date": "19960430",
            "filing_date": "19950809",
            "grant_date": "19960430",
            "priority_date": "19950809",
            "assignee": "",
            "priority_number": "US-51299095-A",
            "conceptScore": 0.4102482199668884
        }
    ]
}`

  const vectorsText = `
  ## Vectors
  /vectors endpoint provides the vectors representation of your query.

  Following is a sample vectors query:
  `

  const vectorsCode = String.raw`curl --request POST \
  --url ${apiEndpoint}/vectors \
  --header 'content-type: application/json' \
  --header 'x-api-key: ${apiKey}' \
  --data '{"request": {"query": "A method for recovering and/or processing oil to be recovered from water, which method comprises providing an ice-breaker or ice-breaking vessel, the ice-breaker or ice-breaking vessel having a single hull, an anti-roll system and/or a heeling system including one or more pairs of tanks located inside said hull on opposite sides thereof, a first set of connecting pipes for transferring water and air between tanks of the anti-roll system and/or a heeling system"}}'`

  const vectorsResponse = (index_name === "arxiv") ? `
  {
    "query": "A method for recovering and/or processing oil to be recovered from water, which method comprises providing an ice-breaker or ice-breaking vessel, the ice-breaker or ice-breaking vessel having a single hull, an anti-roll system and/or a heeling system including one or more pairs of tanks located inside said hull on opposite sides thereof, a first set of connecting pipes for transferring water and air between tanks of the anti-roll system and/or a heeling system",
    "vector representation": [
        0.5435671210289001,
        0.28300851583480835,
        -0.11737117171287537,
        ...
        -0.2924908399581909,
        0.5332142114639282,
        0.22638912498950958
    ]
}
  ` : (index_name === "wikipedia") ? `
  {
    "LSI vector": [
        [
            0.08720608910354245,
            -0.00439771436006822,
            0.07255721254393505,
            ...
            -0.045833777286727564,
            0.03923036169678675,
            0.04132380815888073
        ]
    ],
    "query": "A method for recovering and/or processing oil to be recovered from water, which method comprises providing an ice-breaker or ice-breaking vessel, the ice-breaker or ice-breaking vessel having a single hull, an anti-roll system and/or a heeling system including one or more pairs of tanks located inside said hull on opposite sides thereof, a first set of connecting pipes for transferring water and air between tanks of the anti-roll system and/or a heeling system"
}
  ` :`{
    "query": "A method for recovering and/or processing oil to be recovered from water, which method comprises providing an ice-breaker or ice-breaking vessel, the ice-breaker or ice-breaking vessel having a single hull, an anti-roll system and/or a heeling system including one or more pairs of tanks located inside said hull on opposite sides thereof, a first set of connecting pipes for transferring water and air between tanks of the anti-roll system and/or a heeling system",
    "vector representation": [
        0.3086247742176056,
        -0.193357452750206,
        -0.2795202136039734,
        ...
        -0.30346155166625977,
        -0.27963852882385254,
        0.1711854636669159
    ]
  }`

  const dictionaryText = `
  ## Dictionary
  /dictionary endpoint provides a list of words from your query that exist in the dictionary for that index. 
  If passed a parameter that indicates you want all the elements, it would return everything that exists in the 
  dictionary. This endpoint would not accept a query, it would just accept a flag that indicates we want 
  all the words in the dictionary.

  Following is a sample dictionary query:
  `

  /* eslint-disable max-len */
  const dictionaryCode = String.raw`curl --request POST \
  --url ${apiEndpoint}/dictionary \
  --header 'content-type: application/json' \
  --header 'x-api-key: ${apiKey}' \
  --data '{"request": {"query": "A method for recovering and/or processing oil to be recovered from water, which method comprises providing an ice-breaker or ice-breaking vessel, the ice-breaker or ice-breaking vessel having a single hull, an anti-roll system and/or a heeling system including one or more pairs of tanks located inside said hull on opposite sides thereof, a first set of connecting pipes for transferring water and air between tanks of the anti-roll system and/or a heeling system, and one or more water pumps connected to the water pipes between the tanks of the anti-roll system and/or a heeling system for pumping water from one tank into another to heel the vessel; installing a parallel oil piping system and valves and oil pumps suitable for the recovery, separation and transfer of oil between the tanks of the anti-roll system and/or of the heeling system; closing the first set of connecting pipes and the one or more water pumps connected to the water pipes between the tanks of the anti-roll system and/or a heeling system; and collecting the oil to be recovered from water by an oil recovery apparatus and pumping the oil by means of the parallel oil piping system and oil pumps into tanks of the anti-roll system and/or tanks of the heeling system."}}'`
  /* eslint-disable max-len */

  const dictionaryResponse = (index_name === "wikipedia") ? `
  {
    "words": [
        "said",
        "on",
        "be",
        "providing",
        "or",
        "pipes",
        "inside",
        "processing",
        "system",
        "which",
        "having",
        "oil",
        "single",
        "including",
        "sides",
        "first",
        "transferring",
        "water",
        "set",
        "opposite",
        "between",
        "hull",
        "tanks",
        "air",
        "recovered",
        "method",
        "more",
        "vessel",
        "one",
        "connecting",
        "for",
        "heeling",
        "pairs",
        "located",
        "recovering",
        "an",
        "comprises",
        "from"
      ]
    }` : (index_name === "arxiv") ? `
    {
      "words": [
          "said",
          "on",
          "be",
          "providing",
          "or",
          "pipes",
          "inside",
          "processing",
          "system",
          "which",
          "having",
          "oil",
          "single",
          "including",
          "sides",
          "first",
          "transferring",
          "water",
          "set",
          "opposite",
          "between",
          "hull",
          "tanks",
          "air",
          "recovered",
          "method",
          "more",
          "vessel",
          "one",
          "connecting",
          "for",
          "heeling",
          "pairs",
          "located",
          "recovering",
          "an",
          "comprises",
          "from"
      ]
  }` : `{
    "words": [
        "vessel",
        "inside",
        "transferring",
        "recovered",
        "including",
        "pipes",
        "located",
        "first",
        "opposite",
        "air",
        "sides",
        "system",
        "connecting",
        "one",
        "method",
        "single",
        "hull",
        "providing",
        "said",
        "pairs",
        "water",
        "oil",
        "tanks",
        "comprises",
        "processing",
        "set",
        "recovering",
        "heeling"
    ]
  }`

  const authenticationText = `
  ## Authentication
  Traindex API Authentication is via an API key. You send this as part of your request via the x-api-key parameter.

  This parameter is always required. We'll return an error if the key is either missing or invalid.

  Your API key is what identifies your account, so make sure to keep it secret! You can generate new keys or 
  delete existing ones in the API settings page of the Traindex application.

  You can find these settings by visiting the API section in your Traindex navigation bar. 
  You can also visit the [API settings page](https://www.traindex.io/index).`

  const errorsText = `
  ## Errors
  Traindex API uses conventional HTTP response codes to indicate the success or failure of an API request.

  In case of an error, we'll return an array of errors containing information regarding what happened.

  - **200 - OK** The request was successful.
  - **400 - Bad** request Your request was not valid.
  - **401 - Unauthorized** No valid API key was provided.
  - **5XX - Server errors** Something went wrong on Traindex's end.
  `

  const scrollItems = [
    "introduction",
    "api-endpoint",
    "query-structure",
    "vectors",
    "dictionary",
    "authentication",
    "errors"
  ]

  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

  return (
    <Container className="body-container mt-3">
      <div className="api-docs site-wrapper vh-90">
        <div className="menu-wrapper">
          <div className="sidebar">
            <Dropdown
              className="menu menu-dropdown mt-4"
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen(!dropdownOpen)}
            >
              <DropdownToggle caret>
                <FontAwesomeIcon icon={faBars} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => scrollToRef(introRef)}>Introduction</DropdownItem>
                <DropdownItem onClick={() => scrollToRef(endpointRef)}>API Endpoint</DropdownItem>
                <DropdownItem onClick={() => scrollToRef(queryStructureRef)}>Query Structure</DropdownItem>
                <DropdownItem onClick={() => scrollToRef(vectorsRef)}>Vectors</DropdownItem>
                <DropdownItem onClick={() => scrollToRef(dictionaryRef)}>Dictionary</DropdownItem>
                <DropdownItem onClick={() => scrollToRef(authRef)}>Authentication</DropdownItem>
                <DropdownItem onClick={() => scrollToRef(errorsRef)}>Errors</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Scrollspy className="menu menu-scroll p-0 mt-4" items={scrollItems} currentClassName="isCurrent">
              <li>
                <a href="#introduction" className="menu-item">
                  Introduction
                </a>
              </li>
              <li>
                <a href="#api-endpoint" className="menu-item">
                  API Endpoint
                </a>
              </li>
              <li className="pl-3">
                <a href="#query-structure" className="menu-item">
                  Query Structure
                </a>
              </li>
              <li className="pl-3">
                <a href="#vectors" className="menu-item">
                  Vectors
                </a>
              </li>
              <li className="pl-3">
                <a href="#dictionary" className="menu-item">
                  Dictionary
                </a>
              </li>
              <li>
                <a href="#authentication" className="menu-item">
                  Authentication
                </a>
              </li>
              <li>
                <a href="#errors" className="menu-item">
                  Errors
                </a>
              </li>
            </Scrollspy>
          </div>
        </div>
        <main className="main-wrapper">
          <div className="mx-2">
            <section ref={introRef} id="introduction" className="my-4">
              <ReactMarkdown source={introText} />
            </section>
            <section ref={endpointRef} id="api-endpoint" className="my-4">
              <ReactMarkdown source={endpointText} />
            </section>
            <section ref={queryStructureRef} id="query-structure" className="my-4">
              <ReactMarkdown source={queryText} />
              <CopyBlock text={queryCode} language="text" theme={monoBlue} codeBlock />
              <ReactMarkdown className="mt-3" source={paramsText} />
              <CopyBlock text={queryResponse} language="json" theme={monoBlue} codeBlock />
            </section>
            <section ref={vectorsRef} id="vectors" className="my-4">
              <ReactMarkdown source={vectorsText} />
              <CopyBlock text={vectorsCode} language="text" theme={monoBlue} codeBlock />
              <p>The result of the query is:</p>
              <CopyBlock text={vectorsResponse} language="json" theme={monoBlue} codeBlock />
            </section>
            <section ref={dictionaryRef} id="dictionary" className="my-4">
              <ReactMarkdown source={dictionaryText} />
              <CopyBlock text={dictionaryCode} language="text" theme={monoBlue} codeBlock />
              <p>The result of the above query is as follows:</p>
              <CopyBlock text={dictionaryResponse} language="json" theme={monoBlue} codeBlock />
            </section>
            <section ref={authRef} id="authentication" className="my-4">
              <ReactMarkdown source={authenticationText} />
            </section>
            <section ref={errorsRef} id="errors" className="my-4">
              <ReactMarkdown source={errorsText} />
            </section>
          </div>
        </main>
      </div>
    </Container>
  )
}

export default ApiDocumentation
