import React, { useState, useEffect } from "react"
import {
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  Alert,
  Table,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Container
} from "reactstrap"
import Users from "../../api/UsersAPI"
import { useStateValue } from "../../contexts/StateProvider"
import "./settings.css"
import { Button } from "../Reactstrap_Components/Button"
import OrganizationsAPI from "../../api/OrganizationsAPI"

const Settings = () => {
  const [formData, setFormData] = useState({ email: "", fullName: "" }),
    { state } = useStateValue(),
    { user } = state,
    [loading, setLoading] = useState(true),
    [error, setError] = useState(""),
    [organization, setOrganization] = useState(""),
    [updatedOrg, setUpdatedOrg] = useState(""),
    [users, setUsers] = useState(""),
    [role, setRole] = useState("member"),
    [success, setSuccess] = useState(""),
    colors = ["#5e21b6", "#fbb03b", "#29abe2", "#8cc63f", "#f15a24"]

  useEffect(() => {
    setLoading(true)
    if (user) {
      setOrganization(user.organization?.name)
      const fetchUsers = async () => {
        const users = await Users.list(user.organization.item_id)
        return users
      }
      fetchUsers().then(data => {
        setUsers(data.users)
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [user])

  const handleSubmit = async event => {
    event.preventDefault()
    error && setError("")
    try {
      const { email, fullName } = formData
      const found = users && users.some(el => el.email === email)
      if (found) {
        setError("This user is already in the organization.")
      } else {
        const params = {
          email,
          full_name: fullName.trim(),
          role: role,
          org_name: organization,
          org_id: user.org_id,
          type: "invite",
          auth_user_id: user.item_id
        }
        for (const key of Object.keys(params)) {
          if (params[key] === "") {
            delete params[key]
          }
        }
        const newUser = await Users.create(params)
        setUsers([...users, newUser])
        setSuccess("Your invitation has been sent successfully!")
        setTimeout(() => {
          setSuccess("")
        }, 3000)
        setFormData({ email: "", fullName: "" })
        setRole("member")
        setLoading(false)
      }
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  const updateOrganization = async event => {
    event.preventDefault()
    try {
      const params = {
        auth_user_id: user.item_id,
        org_id: user.org_id,
        org_name: updatedOrg
      }
      await OrganizationsAPI.update(params)
      setSuccess("Organization updated successfully!")
      setTimeout(() => {
        setSuccess("")
      }, 3000)
      setLoading(false)
      user.organization.name = updatedOrg
      setOrganization(updatedOrg)
      setUpdatedOrg("")
    } catch (error) {
      if (error.message == "Unauthorized request!") {
        setError("You are not allowed to change the organization name!")
      } else {
        setError(error.message)
      }
      setLoading(false)
    }
  }

  const onChangeHandler = event => {
    const { name, value } = event.target
    return setFormData({ ...formData, [name]: value })
  }
  const onOrganizationChangeHandler = ({ target: { value } }) => {
    return setUpdatedOrg(value)
  }

  const removeUser = async (email, full_name) => {
    error && setError("")
    try {
      await Users.remove({ username: email, org_id: user.org_id, auth_user_id: user.item_id })
      setUsers(users.filter(user => user.email !== email))
      setSuccess(`${full_name} has been removed successfully!`)
      setTimeout(() => {
        setSuccess("")
      }, 3000)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  const updateUser = async (User, event) => {
    error && setError("")
    const newRole = event.target.value
    if (User.role !== newRole) {
      try {
        const res = await Users.update({ 
          email: User.email, 
          role: newRole,
          user_id: User.user_id,
          auth_user_id: user.item_id })
        if (res.status === 200) {
          const newUsers = users.map(u => (u.email === User.email ? { ...u, role: newRole } : u))
          setUsers(newUsers)
        }
      } catch (error) {
        setError(error.message)
      }
    }
  }

  const usersDisplay =
    users &&
    users.map((userItem, index) => {
      const abbrevName = userItem.email.charAt(0)
      const color = colors[Math.floor(index % colors.length)]
      return (
        <tr className="d-flex justify-content-between" key={index.toString()}>
          <td className="pl-0 mr-auto">
            <div className="circle mx-3" style={{ backgroundColor: color }}>
              {abbrevName.toUpperCase()}
            </div>
            {userItem.email}{" "}
            {user.email === userItem.email ? "(you)" : userItem.status !== "CONFIRMED" ? "(pending)" : ""}
          </td>
          <td>
            <UncontrolledButtonDropdown disabled={user && user.role !== "admin"} className="dropdown-btn">
              <DropdownToggle
                disabled={user && user.role !== "admin"}
                className="role-toggle"
                caret={user && user.role === "admin"}
              >
                {userItem.role === "admin" ? "Admin" : "Member"}
              </DropdownToggle>
              <DropdownMenu right className="dropdown-role">
                <DropdownItem value="admin" active={userItem.role === "admin"} onClick={e => updateUser(userItem, e)}>
                  Admin
                </DropdownItem>
                <DropdownItem value="member" active={userItem.role !== "admin"} onClick={e => updateUser(userItem, e)}>
                  Member
                </DropdownItem>
                {user && user.role === "admin" && user.email !== userItem.email && (
                  <DropdownItem onClick={() => removeUser(userItem.email, userItem.full_name)}>Remove</DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </td>
        </tr>
      )
    })
  return (
    <Container className="body-container mt-3">
      <div className="settings-view">
        <Label className="font-weight-bold">Organization Account</Label>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner size="lg" className="spinner" />
          </div>
        ) : (
          <>
            <div className="input-group">
              <Input
                type="text"
                name="organization"
                id="organization"
                defaultValue={user?.organization?.name}
                placeholder="Enter organization name"
                onChange={onOrganizationChangeHandler}
                className="col-xs-12 col-md-4 mb-5"
              />
              <span className="input-group-btn">
                <Button
                  onClick={updateOrganization}
                  className="btn btn-primary mb-2 ml-1"
                  disabled={updatedOrg.length < 1 || updatedOrg === organization}
                  bgColor="#5E216B"
                >
                  Update Organization
                </Button>
              </span>
            </div>
            {success && <Alert color="success">{success}</Alert>}
            {error && <div className="ml-5 my-2 text-danger">{error}</div>}
            <Label className="font-weight-bold">Your Team</Label>
            <ListGroup className="col-xs-12 p-0">
              {user && user.role === "admin" && (
                <Form inline onSubmit={handleSubmit} className="invite-user p-3">
                  <FormGroup className="my-2 invite-form">
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      placeholder="Email Address"
                      onChange={onChangeHandler}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="my-2 invite-form">
                    <Input
                      type="text"
                      name="fullName"
                      id="fullName"
                      value={formData.fullName}
                      placeholder="Full Name"
                      onChange={onChangeHandler}
                      required
                    />
                  </FormGroup>
                  <UncontrolledButtonDropdown className="dropdown-btn mr-2">
                    <DropdownToggle className="role-toggle" caret>
                      {role === "member" ? "Member" : "Admin"}
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-role" name="role">
                      <DropdownItem onClick={() => setRole("admin")} active={role === "admin"}>
                        Admin
                      </DropdownItem>
                      <DropdownItem onClick={() => setRole("member")} active={role === "member"}>
                        Member
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <div className="switch-field my-2">
                    <div className="switch-container p-0 m-0">
                      <Input
                        type="radio"
                        id="member"
                        name="switch-role"
                        onClick={() => setRole("member")}
                        defaultChecked={role === "member"}
                      />
                      <Label className="my-0" for="member">
                        Member
                      </Label>
                      <Input type="radio" id="admin" name="switch-role" onClick={() => setRole("admin")} />
                      <Label className="my-0" for="admin">
                        Admin
                      </Label>
                    </div>
                  </div>
                  <Button type="submit" className="my-2" bgColor="#5E216B">
                    Invite User
                  </Button>
                </Form>
              )}
            </ListGroup>
            <Table borderless className="setting-table col-xs-12 mt-2">
              <tbody>{usersDisplay || []}</tbody>
            </Table>
          </>
        )}
      </div>
    </Container>
  )
}

export default Settings
