import React, { useState } from "react"
import { 
  Button, 
  Form, 
  Label, 
  FormGroup, 
  Col, 
  Row,
  Input,
  InputGroup,
  UncontrolledAlert,
  InputGroupText
} from "reactstrap"
import Select from "react-select"
import { 
  useStripe,
  useElements, 
  CardNumberElement, 
  CardCvcElement, 
  CardExpiryElement 
} from "@stripe/react-stripe-js"
import { useStateValue } from "../../contexts/StateProvider"
import { queryClient } from "../../utils/common"
import PaymentMethod from "../../api/PaymentMethodAPI"
import SpinnerOverlay from "../ExtraComponents/SpinnerOverlay"
import "./subscription.css"

const options = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      letterSpacing: "0.025em",
      fontFamily: "Source Code Pro, monospace",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#9e2146"
    }
  },
}

const UpdatePaymentMethod = () => {
  const { state, getUserInfo } = useStateValue(),
    { user } = state,
    [status, setStatus] = useState("idle"),
    [error, setError] = useState(""),
    [isValidCardNumber, setIsValidCardNumber] = useState(false),
    [isValidCVC, setIsValidCVC] = useState(false),
    [isValidExpiry, setIsValidExpiry] = useState(false),
    [accountType, setAccountType] = useState({ value: "company", label: "Company"}),
    [bankAccountForm, setBankAccountForm] = useState({
      country: "US",
      currency: "usd",
      routing_number: "",
      account_number: "",
      account_holder_name: ""
    }),
    [verifyForm, setVerifyForm] = useState({
      amount1: 0,
      amount2: 0
    }),
    stripe = useStripe(),
    elements = useElements(),
    [paymentMethod, setPaymentMethod] = useState(null)

  const userData = user && user.organization || null

  const paymentSource = user && user.stripe_info.sources || null

  const invalidateQuery = () => {
    ["subscription", "invoice"].forEach(queryKey =>queryClient.invalidateQueries(queryKey))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (paymentMethod.value === "creditCard") {
      try {
        setStatus("pending")
        const response = await stripe.createToken(elements.getElement(CardNumberElement))
        if (response.error) {
          setStatus("rejected")
          setError(response.error.message)
        } else {
          const body = {
            collection_id: userData.collection_id,
            item_id: userData.item_id,
            stripe_customer_id: userData.stripe_customer_id,
            token_id: response.token.id,
            payment_method: paymentMethod
          }
          userData.last4 ? await PaymentMethod.update(body) : await PaymentMethod.create(body)
          setPaymentMethod(null)
          invalidateQuery()
          await getUserInfo()
          setTimeout(() => {
            ["subscription", "subscription-status", "invoice"].forEach(
              queryKey => queryClient.invalidateQueries(queryKey)
            )
            setStatus("resolved")
          }, 3000)
        }
      } catch (error) {
        setStatus("rejected")
        setError(error.message)
      }
    } else if (paymentMethod.value === "ach_debit") {
      bankAccountForm.account_holder_type = accountType.value
      try {
        setStatus("pending")
        const response = await stripe.createToken("bank_account", bankAccountForm)
        if (response.error) {
          setStatus("rejected")
          setError(response.error.message)
        } else {
          const body = {
            collection_id: userData.collection_id,
            item_id: userData.item_id,
            stripe_customer_id: userData.stripe_customer_id,
            token_id: response.token.id,
            payment_method: paymentMethod
          }
          userData.last4 ? await PaymentMethod.update(body) : await PaymentMethod.create(body)
          setPaymentMethod(null)
          invalidateQuery()
          await getUserInfo()
          setStatus("resolved")
        }
      } catch (error) {
        setStatus("rejected")
        setError(error.message)
      }
    }
  }

  const handleChange = event => {
    const { complete, elementType } = event
    if (elementType === "cardNumber") setIsValidCardNumber(complete)
    else if (elementType === "cardCvc") setIsValidCVC(complete)
    else setIsValidExpiry(complete)
  }

  const onSubmitVerify = async event => {
    event.preventDefault()
    const body = {
      collection_id: userData.collection_id,
      item_id: userData.item_id,
      stripe_customer_id: userData.stripe_customer_id,
      amounts: [verifyForm.amount1, verifyForm.amount2]
    }
    try {
      setStatus("pending")
      await PaymentMethod.verify(body)
      await getUserInfo()
      setStatus("resolved")
    } catch (error) {
      setStatus("rejected")
      setError(error.message)
    }
  }

  const methodOptions = [
    { value: "creditCard", label: "Credit Card"},
    { value: "ach_debit", label: "ACH Direct Debit"}
  ]

  const accountTypeOptions = [
    { value: "company", label: "Company"},
    { value: "individual", label: "Individual"}
  ]

  const onChangeBankForm = event => {
    const { name, value } = event.target
    setBankAccountForm({
      ...bankAccountForm,
      [name]: value
    })
  }

  const onChangeAmount = event => {
    const { name, value } = event.target
    setVerifyForm({
      ...verifyForm,
      [name]: value
    })
  }

  const submitCardDisable = !stripe || !elements || !isValidCardNumber || !isValidCVC || !isValidExpiry
  const submitAccountDisable = Object.values(bankAccountForm).some(e => e.length === 0)

  return (
    <Col className="px-2 px-sm-5 py-5">
      <h3 className="font-avenir-bold">Update Payment Information</h3>
      {status === "pending" && (
        <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-25">
          <SpinnerOverlay show />
        </div>
      )}
      {status === "rejected" && <UncontrolledAlert color="danger">{error}</UncontrolledAlert>}
      {status === "resolved" && <UncontrolledAlert color="success">Successfully updated!</UncontrolledAlert>}
      {userData?.payment_method && (
        <div className="py-2">
          Current payment method: <strong className="pl-2">{userData?.payment_method.label}</strong>
        </div>
      )}
      {userData?.last4 && (
        <div className="py-2">
          Currently Using: <strong className="pl-2">**** **** **** {userData?.last4}</strong>
        </div>
      )}
      {paymentSource?.data[0]?.status === "new" && (
        <Form onSubmit={onSubmitVerify}>
          <h5 className="mt-3">Verify your bank account</h5>
          <p>
            Two small deposits are sent to your bank account within 1-2 business day,
            please enter these two amounts to verify the account.
          </p>
          <FormGroup row className="align-items-center ml-0 py-2">
            <Label className="pl-0" sm={3} for="amount1">1st credit amount</Label>
            <Col sm={3}>
              <InputGroup>
                <InputGroupText
                  className="verify-amount"
                >
                  $0.
                </InputGroupText>
                <Input 
                  placeholder="00" 
                  min={0} 
                  max={100} 
                  type="number" 
                  step="1"
                  name="amount1"
                  id="amount1"
                  onChange={onChangeAmount}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center ml-0 py-2">
            <Label className="pl-0" sm={3} for="amount2">2nd credit amount</Label>
            <Col sm={3}>
              <InputGroup>
                <InputGroupText
                  className="verify-amount"
                >
                  $0.
                </InputGroupText>
                <Input 
                  placeholder="00" 
                  min={0} 
                  max={100} 
                  type="number" 
                  step="1"
                  name="amount2"
                  id="amount2"
                  onChange={onChangeAmount}
                />
              </InputGroup>
            </Col>
          </FormGroup>
          <Button 
            className="form-control subscribe-btn verify-btn"
            disabled={!verifyForm.amount1 || !verifyForm.amount2}
          >
            Verify
          </Button>
        </Form>
      )}
      <p className="mt-3">Choose your payment method:</p>
      <Col className="pl-0" sm={6}>
        <Select
          defaultValue={paymentMethod}
          onChange={setPaymentMethod}
          options={methodOptions} 
        />
      </Col>
      {paymentMethod && paymentMethod.value === "creditCard" &&  
        <Form onSubmit={handleSubmit} className="mt-3 py-2">
          <FormGroup row className="align-items-center ml-0 py-2">
            <Label for="cardNumber">Card Number:</Label>
            <Col>
              <CardNumberElement options={options} className="form-control" id="cardNumber" onChange={handleChange} />
            </Col>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup row className="align-items-center ml-0 py-2">
                <Label for="cvc">CVC / CVV:</Label>
                <Col>
                  <CardCvcElement id="cvc" options={options} className="form-control" onChange={handleChange} />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row className="align-items-center ml-0 py-2">
                <Label for="exp_date">Expiration Date:</Label>
                <Col>
                  <CardExpiryElement id="exp_date" options={options} className="form-control" onChange={handleChange} />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <div className="py-2">
            <Button color="primary" className="form-control subscribe-btn" disabled={submitCardDisable}>
              Update Credit Card
            </Button>
          </div>
        </Form>
      }
      {paymentMethod && paymentMethod.value === "ach_debit" &&
        <Form className="mt-3 py-2" onSubmit={handleSubmit}>
          <FormGroup row className="align-items-center ml-0 py-2">
            <Label className="pl-0" sm={3} for="accountName">Account Name</Label>
            <Col sm={9}>
              <Input 
                type="text"
                name="account_holder_name"
                id="accountName"
                placeholder="Account holder name"
                onChange={onChangeBankForm} 
              />
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center ml-0 py-2">
            <Label className="pl-0" sm={3} for="accountNumber">Account Number</Label>
            <Col sm={9}>
              <Input 
                type="text" 
                name="account_number" 
                id="accountNumber" 
                placeholder="Account number"
                onChange={onChangeBankForm} 
              />
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center ml-0 py-2">
            <Label className="pl-0" sm={3} for="routingNumber">Routing Number</Label>
            <Col sm={9}>
              <Input 
                type="text"
                name="routing_number"
                id="routingNumber"
                placeholder="Routing number"
                onChange={onChangeBankForm} 
              />
            </Col>
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup row className="align-items-center ml-0 py-2">
                <Label for="country">Country</Label>
                <Col>
                  <Input 
                    type="text" 
                    name="country" 
                    id="country" 
                    value="US"
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup row className="align-items-center ml-0 py-2">
                <Label for="currency">Currency</Label>
                <Col>
                  <Input 
                    type="text" 
                    name="currency" 
                    id="currency" 
                    value="usd"
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row className="align-items-center ml-0 py-2">
            <Label className="pl-0" sm={3}>Account Type</Label>
            <Col sm={9}>
              <Select 
                options={accountTypeOptions}
                defaultValue={accountType}
                onChange={setAccountType}
              />
            </Col>
          </FormGroup>
          <div className="py-2">
            <Button color="primary" className="form-control subscribe-btn" disabled={submitAccountDisable}>
              Add Bank Account
            </Button>
          </div>
        </Form>
      }
    </Col>
  )
}

export default UpdatePaymentMethod
