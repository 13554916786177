import React, { useEffect, useState } from "react"
import { Alert } from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment"

const currentDate = moment().unix()

const PastDueAlert = ({ subscription }) => {
  const [leftDays, setLeftDays] = useState("")
  const [pastDays, setPastDays] = useState("")

  useEffect(() => {
    const period_start = subscription.current_period_start
    const pastDays = Math.ceil((currentDate - period_start)/(24*60*60)) || 0
    const leftDays = Number(process.env.REACT_APP_STRIPE_RETRY_DAYS) - pastDays
    setPastDays(pastDays)
    setLeftDays(leftDays)
  }, [subscription])

  const isDue = subscription.status === "past_due"
  const isPaused = subscription && subscription.pause_collection 
  && subscription.pause_collection["behavior"] === "mark_uncollectible"

  return (
    <>
      {(leftDays > 0) && isDue && !isPaused && (
        <Alert color="warning">
          Your subscription is now {pastDays} {pastDays == 1 ? "day" : "days"} past due.
          Please update your payment details within {leftDays} days to avoid your account being terminated.
          <br/>
          <Link to="/subscription" className="trial-alert">
            Update your payment details.
          </Link>
        </Alert>
      )}
    </>
  )
}

export default PastDueAlert
