import React, { useState, useEffect } from "react"
import PropTypes, { func } from "prop-types"
import axios from "axios"
import Header from "../Header"
import Footer from "../Footer"
import { Row, Col, Container } from "reactstrap"
import "./chatbot.css"
import prettier from "prettier/standalone"
import parserBabel from "prettier/parser-babel"
import { useLocation } from "react-router-dom"
import AWS from "aws-sdk"
import { v4 as uuidv4 } from "uuid"
import Filter from "./Filter"
import ChatContainer from "./ChatContainer"
import Feedback from "./Feedback"

const cloudWatchLogs = new AWS.CloudWatchLogs({
  region: "us-west-2",
  accessKeyId: process.env.REACT_APP_CLOUDWATCH_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_CLOUDWATCH_SECRET_ACCESS_KEY,
})

AWS.config.update({
  region: "us-west-2",
  accessKeyId: process.env.REACT_APP_CLOUDWATCH_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_CLOUDWATCH_SECRET_ACCESS_KEY,
})
const cloudWatch = new AWS.CloudWatch()

const sendToCloudWatch = (executionTime) => {
  const params = {
    MetricData: [
      {
        MetricName: "DEXIExecutionTime_EndToEndMessage",
        Dimensions: [
          {
            Name: "Time",
            Value: "Function execution time"
          },
        ],
        Unit: "Seconds",
        Value: executionTime
      },
    ],
    Namespace: "Traindex"
  }

  cloudWatch.putMetricData(params, (err, data) => {
    if (err) {
      console.error("Error sending data to CloudWatch:", err)
    }
  })
}



export function sendLogToCloudWatch(logGroupName, logData) {
  const logStreamName = `log-stream-${uuidv4()}`

  const createLogStream = async () => {
    try {
      await cloudWatchLogs
        .createLogStream({
          logGroupName,
          logStreamName,
        })
        .promise()
    } catch (err) {
      if (err.code !== "ResourceAlreadyExistsException") {
        throw err
      }
    }
  }
  const putLogEvents = async () => {
    const params = {
      logEvents: [
        {
          message: JSON.stringify(logData),
          timestamp: new Date().getTime(),
        },
      ],
      logGroupName,
      logStreamName,
    }

    try {
      const data = await cloudWatchLogs.putLogEvents(params).promise()
    } catch (err) {
      console.log(err)
    }
  }

  ;(async () => {
    await createLogStream()
    await putLogEvents()
  })()
}
const P_VALUES = ["Before", "After", "Equal"]

const Chatbot = ({ authorize }) => {
  const [priorityDValue, setPriorityDValue] = useState("")
  const [publicationDValue, setPublicationDValue] = useState("")
  const [cpcValue, setcpcValue] = useState("")
  const [kindCodeValue, setkindCodeValue] = useState("")
  const [priorityCondition, setPriorityCondition] = useState(P_VALUES[0])
  const [publicationCondition, setPublicationCondition] = useState(P_VALUES[0])

  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY
  const [threadData, setThreadData] = useState(null)

  const handlePriorityDChange = (event) => {
    setPriorityDValue(event.target.value)
  }
  const handlePublicaionDChange = (event) => {
    setPublicationDValue(event.target.value)
  }
  const cpcHandleChange = (event) => {
    setcpcValue(event.target.value)
  }
  const kindCodeHandleChange = (event) => {
    setkindCodeValue(event.target.value)
  }

  const handleClearSearch = () => {
    setPriorityDValue("")
    setPublicationDValue("")
    setcpcValue("")
    setkindCodeValue("")
    setPriorityCondition(P_VALUES[0])
    setPublicationCondition(P_VALUES[0])
  }

  const handlePriorityConditionChange = (event) => {
    setPriorityCondition(event.target.value)
  }
  const handlePublicationConditionChange = (event) => {
    setPublicationCondition(event.target.value)
  }


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      runConversation()
    }
  }
  const HEADERS = {
    "OpenAI-Beta": "assistants=v2",
    "Content-Type": "application/json",
    Authorization: "Bearer " + OPENAI_API_KEY,
  }

  const createThread = async () => {
    const url = "https://api.openai.com/v1/threads"
    var payload = {
      messages: [
        {
          role: "assistant",
          content: "Hi! I am an assistant to help with Prior art search and analysis, Patent drafting and much more.",
        },
      ],
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data: JSON.stringify(payload),
      })
    } catch (error) {
      console.error("Error creating thread:", error)
      return null
    }
    return response.data
  }

  useEffect(() => {
    const fetchThread = async () => {
      const data = await createThread()
      setThreadData(data)
    }

    fetchThread()
  }, [])

  const [loadingForGen, setLoadingForGen] = useState(false)
  const [promptInputValue, setpromptInputValue] = useState("")
  const [messages, setMessages] = useState([
    {
      text: "Hi! I am an assistant to help with Prior art\
  search and analysis, Patent drafting and much more.",
      isUser: false,
    },
  ])

  //-------------------------------------------------------------------------
  //--------------------------OPEN AI FUNCTIONs------------------------------
  //-------------------------------------------------------------------------

  const sendMessage = async (threadId, query) => {
    const url = "https://api.openai.com/v1/threads/" + threadId + "/messages"
    const payload = {
      role: "user",
      content: query,
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data: JSON.stringify(payload),
      })
    } catch (error) {
      console.error("Error Sending Message:", error)
      return null
    }
    return response.data
  }

  const getMessages = async (threadId) => {
    const url = "https://api.openai.com/v1/threads/" + threadId + "/messages"
    try {
      var response = await axios({
        url: url,
        method: "get",
        headers: HEADERS,
      })
    } catch (error) {
      console.error("Error getting Messages:", error)
      return null
    }
    return response.data
  }

  const checkCompleteStatus = async (threadId, runId) => {
    const url = "https://api.openai.com/v1/threads/" + threadId + "/runs/" + runId
    try {
      var response = await axios({
        url: url,
        method: "get",
        headers: HEADERS,
      })
    } catch (error) {
      console.error("Error creating thread:", error)
      return null
    }
    return response.data
  }

  const traindex_API = async (args) => {
    const url = "https://api.traindex.io/openai/openai-gen/processWithFilters"
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY,
    }

    const payload = args

    var priority_sign = "<"
    var publication_sign = "<"

    if(priorityCondition == "Before"){
      priority_sign = "<"
    }
    else if(priorityCondition == "After"){
      priority_sign = ">"

    }
    else{
      priority_sign = "="

    }

    if(publicationCondition == "Before"){
      publication_sign = "<"
    }
    else if(publicationCondition == "After"){
      publication_sign = ">"

    }
    else{
      publication_sign = "="
    }

    if (priorityDValue != "" && payload["priority_date"] == undefined) {
      const pdValueWithoutDashs = priorityDValue.replace(/-/g, "")
      payload["priority_date"] = pdValueWithoutDashs
      payload["priority_date_criteria"] = priority_sign

    }
    if (publicationDValue != "" && payload["publication_date"] == undefined) {
      const pdValueWithoutDashs = publicationDValue.replace(/-/g, "")
      payload["publication_date"] = pdValueWithoutDashs
      payload["publication_date_criteria"] = publication_sign

    }
    if (cpcValue != "" && payload["cpc"] == undefined) {
      payload["cpc"] = cpcValue
    }
    if (kindCodeValue != "" && payload["kind_code"] == undefined) {
      payload["kind_code"] = kindCodeValue
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: headers,
        data: JSON.stringify(payload),
      })
    } catch (error) {
      return "Sorry aboout that. There is some internal error! Try again later"
    }
    return response.data
  }
  const get_full_patent_text = async (args) => {
    const { patent_number } = args
    var response = ""
    try {
      response = await axios({
        url: "https://api.traindex.io/openai/openai-gen/getUCIDtext",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_TRAINDEX_API_KEY,
        },
        data: JSON.stringify({ patent_number: patent_number }),
      })
    } catch (error) {
      return "Sorry aboout that. There is some internal error! Try again later"
    }

    return response.data
  }

  const submitToolOutput = async (threadId, runId, toolOutputs) => {
    const url = "https://api.openai.com/v1/threads/" + threadId + "/runs/" + runId + "/submit_tool_outputs"
    const payload = {
      tool_outputs: toolOutputs
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data: JSON.stringify(payload),
      })
    } catch (error) {
      console.error("Error creating thread:", error)
      return null
    }
    return response.data
  }

  const runAssistant = async (threadId) => {
    const url = "https://api.openai.com/v1/threads/" + threadId + "/runs"
    const payload = {
      assistant_id: "asst_Lbzrk9j8dnRMuBtYo1o6Bf2n",
    }
    try {
      var response = await axios({
        url: url,
        method: "post",
        headers: HEADERS,
        data: JSON.stringify(payload),
      })
    } catch (error) {
      console.error("Error Sending Message:", error)
      return null
    }
    return response.data
  }
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const runConversation = async (event) => {


    const textarea = document.querySelector(".chatbot-input")
    if (textarea) {
      textarea.style.height = "auto"
    }

    const cloudWatchStruct = {}

    setLoadingForGen(true)
    const prompt = promptInputValue
    cloudWatchStruct["1_user_query"] = prompt

    const startTime = performance.now()


    const userMessageUpdate = [...messages, { text: prompt, isUser: true }]
    setMessages(userMessageUpdate)
    setpromptInputValue("")

    const threadId = threadData["id"]

    await sendMessage(threadId, prompt)
    const run = await runAssistant(threadId)

    var response_status = ""
    var statusCompleteRes = ""
    var patents = ""

    var anyError = false

    while (response_status != "completed") {
      await delay(3000)
      statusCompleteRes = await checkCompleteStatus(threadId, run["id"])
      if (statusCompleteRes["required_action"] != null) {
        var function_props = statusCompleteRes["required_action"]["submit_tool_outputs"]["tool_calls"]
        var tool_outputs = []

        for (let i = 0; i < function_props.length ; i++){

          var function_name = function_props[i]["function"]["name"]
          var function_args = JSON.parse(function_props[i]["function"]["arguments"])
          var function_tool_id = function_props[i]["id"]
  
          if (function_name == "traindex_API") {
            cloudWatchStruct["2_openai_to_v7_req"] = function_args
  
            patents = await traindex_API(function_args)
            console.log(patents)

            if (Object.prototype.hasOwnProperty.call(patents, "Error")){

              tool_outputs.push({"tool_call_id":function_tool_id, "output":JSON.stringify(patents, null, 2)})
              cloudWatchStruct["2_openai_to_v7_res"] = JSON.stringify(patents, null, 2)

            }
            else{
              const updated_patents = patents.map(item => ({
                ...item,  
                patent_text: "For Devs: Visit Google Patents to get patent text" 
              }))
  
  
              const jsonString = JSON.stringify(patents, null, 2)
  
              console.log(jsonString)
  
              const formattedToolOutput = prettier.format(jsonString, {
                parser: "json",
                plugins: [parserBabel],
              })
  
              tool_outputs.push({"tool_call_id":function_tool_id, "output":formattedToolOutput})
    
              cloudWatchStruct["2_openai_to_v7_res"] = updated_patents

            }

          }
          if (function_name == "get_full_patent_text") {
            cloudWatchStruct["2_ucid_for_details_req"] = function_args
            patents = await get_full_patent_text(function_args)


            const jsonString = JSON.stringify(patents, null, 2)

            const formattedToolOutput = prettier.format(jsonString, {
              parser: "json",
              plugins: [parserBabel],
            })
            tool_outputs.push({"tool_call_id":function_tool_id, "output":formattedToolOutput})

            
            var patents_res = ""
            if (patents["patent_text"] == undefined){
              patents_res = patents["response"]
            }
            else{
              patents_res = patents["patent_text"].substring(0,200)
  
            }
  
            cloudWatchStruct["2_ucid_for_details_res"] = patents_res
          }
        }

        try {
          await submitToolOutput(threadId, run["id"], tool_outputs)

        } catch (error) {
          break
        }

      }
      response_status = statusCompleteRes["status"]
    }
    var res = ""
    if (!anyError){
      const thread_messages = await getMessages(threadId)
      res = thread_messages.data[0].content[0].text.value
    }
    else{
      res = "Sorry aboout that. There is some internal error! Try again later"
    }


    

    cloudWatchStruct["3_user_res"] = res
    const dexiMessageUpdate = [...userMessageUpdate, { text: res, isUser: false }]
    setMessages(dexiMessageUpdate)

    const endTime = performance.now()
    const executionTime = endTime - startTime
    
    const seconds_executionTime = executionTime / 1000
    sendToCloudWatch(seconds_executionTime)

    setLoadingForGen(false)
    sendLogToCloudWatch("/aws/traindex_ui_frontend/chatbot", cloudWatchStruct)
  }

  const handlePromptInputChange = (e) => {
    setpromptInputValue(e.target.value)
    const textarea = e?.target
    if (textarea) {
      if (textarea.value.trim() === "") {
        textarea.style.height = "auto"
      } else {
        textarea.style.height = "auto"
        textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`
      }
    }
  }

  return (
    <div className="px-1">
      {!authorize && <Header dark chatbot />}
      <div className="d-flex justify-content-center px-1 mt-5">
        <Container className="mt-4 mx-auto">
          <Row className="d-flex justify-content-between ">
            <Col className="d-flex flex-column col-12 col-md-3 mb-3">
              <Filter
                priorityDValue={priorityDValue}
                publicationDValue={publicationDValue}
                cpcValue={cpcValue}
                kindCodeValue={kindCodeValue}
                priorityCondition={priorityCondition}
                publicationCondition={publicationCondition}
                onChangePriorityD={handlePriorityDChange}
                onChangePublicationD={handlePublicaionDChange}
                cpcHandleChange={cpcHandleChange}
                kindCodeHandleChange={kindCodeHandleChange}
                handleClearSearch={handleClearSearch}
                onChangePriorityCondition={handlePriorityConditionChange}
                onChangePublicationCondition={handlePublicationConditionChange}
              />
              {messages && <Feedback messages={messages} />}
            </Col>
            <Col className="col-12 col-md-9">
              <ChatContainer
                messages={messages}
                loadingForGen={loadingForGen}
                promptInputValue={promptInputValue}
                onPromptInputChange={handlePromptInputChange}
                handleKeyDown={handleKeyDown}
                runConversation={runConversation}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {!authorize && <Footer home />}
    </div>
  )
}

Chatbot.propTypes = {
  history: PropTypes.object.isRequired,
}

export default Chatbot
