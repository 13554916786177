import EmailReminders from "../api/RemindersAPI"

const update_parameters = async (body) => {
  let response = {}
  let error = false
  try {
    response = await EmailReminders.update_parameters(body)
  } catch (err) {
    error = true
  }
  return { response, error }
}

export { update_parameters }
