/* eslint-disable indent */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import DescriptionIcon from "@material-ui/icons/Description"
import ViewColumnIcon from "@material-ui/icons/ViewColumn"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import StepConnector from "@material-ui/core/StepConnector"
import Typography from "@material-ui/core/Typography"
import {
  Container,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip,
  Form,
  Button,
  FormGroup,
  Collapse,
  Row,
  Table,
  Spinner,
  Label,
  Alert
} from "reactstrap"
import DatasetApi from "../../api/DatasetApi"
import IndexApi from "../../api/IndexAPI"
import { useStateValue } from "../../contexts/StateProvider"
import { readString } from "react-papaparse"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle, faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons"
import Select from "react-select"

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundColor: "rgb(95, 47, 107)"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "rgb(95, 47, 107)"
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundColor: "rgb(95, 47, 107)"
  },
  completed: {
    backgroundColor: "rgb(95, 47, 107)"
  }
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <DescriptionIcon />,
    2: <ViewColumnIcon />,
    3: <PlaylistAddCheckIcon />
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minHeight: "30vh"
  }
}))

function getSteps() {
  return ["Select Dataset", "Assign Columns", "Confirm Index"]
}

export default function CustomizedSteppers() {
  const classes = useStyles()
  const { state, dispatch } = useStateValue(),
    { user, files } = state,
    [columnsData, setColumnsData] = useState([]),
    [currentDataset, setCurrentDataset] = useState(""),
    [indexName, setIndexName] = useState(""),
    [activeStep, setActiveStep] = useState(0),
    [loading, setLoading] = useState(false),
    [error, setError] = useState(""),
    [collapse, setCollapse] = useState(null),
    [columnsRole, setColumnsRole] = useState(null),
    [datasetSize, setDatasetSize] = useState(0),
    history = useHistory()

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      if (user && files.length === 0) {
        try {
          setLoading(true)
          const data = await DatasetApi.fetchFiles(user.org_id)
          dispatch({
            type: "SET_FILES",
            files: data
          })
          setLoading(false)
        } catch (error) {
          dispatch({
            type: "SET_FILES_ERROR",
            error: error.message
          })
          setLoading(false)
        }
      }
    })()
  }, [user])

  const steps = getSteps()

  const handleNext = async () => {
    if (!currentDataset) {
      setError("Please select a dataset from the list.")
    } else {
      if (activeStep === 0 && !columnsData.length) {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
        try {
          setLoading(true)
          let preservedKey = currentDataset.value.replace("+", "%2B")
          const response = await DatasetApi.fetchFiles(preservedKey)
          const datasetData = readString(response.data)
          const dataArray = []
          const initialColumnsRole = {}
          if (datasetData.data && datasetData.data[0]) {
            for (let i = 0; i < datasetData.data[0].length; i++) {
              initialColumnsRole[datasetData.data[0][i]] = "ignore"
              const columnData = datasetData.data.map(function(x) {
                return x[i]
              })

              dataArray.push(columnData)
            }
          }
          setDatasetSize(response.size)
          setColumnsData(dataArray)
          setColumnsRole(initialColumnsRole)
          setLoading(false)
        } catch (error) {
          setError(error.message)
          setLoading(false)
        }
      } else {
        if (
          columnsRole &&
          Object.values(columnsRole).some(e => e === "index") &&
          Object.values(columnsRole).some(e => e === "metadata")
        ) {
          setActiveStep(prevActiveStep => prevActiveStep + 1)
          setCollapse(null)
          setError("")
        } else {
          setError("Please select one index column and at least one metadata column.")
        }
      }
    }
  }

  const onChangeIndexName = (e) => {
    const indexName = e.target.value
    setIndexName(indexName)
    if (indexName.length > 0) {
      if (indexName && /^[A-Za-z0-9]+$/.test(indexName)) {
        setError("")
      } else {
        setError("Index name shouldn't have space and special characters.")
      }
    } else {
      setError("Please enter the index name.")
    }
  }

  const handleSubmit = async () => {
    if (indexName.length > 0) {
      try {
        setError("")
        setLoading(true)
        await IndexApi.create({
          name: indexName,
          orgId: user.org_id,
          userId: user.item_id,
          email: user.email,
          columnsRole: columnsRole,
          columnsData: JSON.stringify(columnsData),
          datasetSize: datasetSize,
          dataset: currentDataset,
          status: "Processing"
        })
        const data = await IndexApi.list(user.org_id)
        dispatch({
          type: "SET_INDEXES",
          indexes: data.indexes
        })
        setLoading(false)
        history.push("/index")
      } catch (error) {
        setLoading(false)
        setError(error.message)
      }
    } else {
      setError("Please enter the index name.")
    }
  }

  const handleBack = () => {
    if (activeStep !== 0) {
      setError("")
      setActiveStep(prevActiveStep => prevActiveStep - 1)
      if (activeStep === 1) {
        setError("")
        setCurrentDataset("")
        setColumnsData([])
      }
    } else {
      history.push("/index")
    }
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  let datasetOptions = []
  if (files && files.length) {
    files.forEach(file => datasetOptions.push({ value: file.Key, label: file.Key.split("/").pop() }))
  }

  const onChangeCollapse = index => {
    if (index === collapse) {
      setCollapse(null)
    } else {
      setCollapse(index)
    }
  }

  const handleSelectDataset = datasetOption => {
    if (currentDataset !== datasetOption) {
      setCurrentDataset(datasetOption)
      setColumnsData([])
      setError("")
    }
  }

  const handleSelectRadio = (e, index) => {
    if (index === collapse) {
      setCollapse(null)
    } else {
      setCollapse(index)
    }
    const { name, value } = e.target
    const newColumnsRole = { ...columnsRole }
    Object.keys(newColumnsRole).forEach(key => {
      if (newColumnsRole[key] === "index" && value === "index") {
        newColumnsRole[key] = "ignore"
      }
    })
    newColumnsRole[name] = value
    if (
      newColumnsRole &&
      Object.values(newColumnsRole).some(e => e === "index") &&
      Object.values(newColumnsRole).some(e => e === "metadata")
    ) {
      setError("")
    } else {
      setError("Please select one index column and at least one metadata column.")
    }
    setColumnsRole(newColumnsRole)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Container className="mx-auto mt-5">
            <h6 className="mt-4">Select the dataset to be used in the training</h6>
            <div className="d-flex text-center">
              <Select
                className="default-select text-left"
                options={datasetOptions}
                onChange={datasetOption => handleSelectDataset(datasetOption)}
              />
              <div className="mt-2 ml-2">
                <FontAwesomeIcon icon={faQuestionCircle} id="QuestionMark" />
                <UncontrolledTooltip placement="right" target="QuestionMark">
                  Only previously uploaded datasets are displayed
                </UncontrolledTooltip>
              </div>
            </div>
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner size="lg" className="spinner" />
              </div>
            )}
            {error && <div className="mt-5 text-danger">{error}</div>}
          </Container>
        )
      case 1:
        return (
          <Container className="data_preview">
            <Form>
              <FormGroup>
                {columnsData.length && columnsRole
                  ? columnsData.map((columnData, index) => {
                      return (
                        <Container key={index}>
                          <Row
                            key={columnData[0]}
                            className={
                              (columnsRole[columnData[0]] === "index"
                                ? "index"
                                : columnsRole[columnData[0]] === "metadata"
                                ? "metadata"
                                : "ignored") + " mt-4 mx-auto"
                            }
                          >
                            <div className="order">{index}</div>
                            <div className="title pl-3 font-weight-bold">{columnData[0]}</div>
                            <div className="data_role">
                              <FormGroup className="mx-2" check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name={columnData[0]}
                                    value="index"
                                    checked={columnsRole[columnData[0]] === "index"}
                                    onChange={e => handleSelectRadio(e, index)}
                                  />{" "}
                                  Index
                                </Label>
                              </FormGroup>
                              <FormGroup className="mx-2" check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name={columnData[0]}
                                    value="metadata"
                                    checked={columnsRole[columnData[0]] === "metadata"}
                                    onChange={e => handleSelectRadio(e, index)}
                                  />{" "}
                                  Metadata
                                </Label>
                              </FormGroup>
                              <FormGroup className="mx-2" check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name={columnData[0]}
                                    value="ignore"
                                    checked={
                                      columnsRole[columnData[0]] !== "index" &&
                                      columnsRole[columnData[0]] !== "metadata"
                                    }
                                    onChange={e => handleSelectRadio(e, index)}
                                  />{" "}
                                  Ignore
                                </Label>
                              </FormGroup>
                            </div>
                          </Row>
                          <Collapse isOpen={collapse === index}>
                            <Table className="index-table">
                              <tbody>
                                {columnData.slice(1, 6).map((item, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Collapse>
                        </Container>
                      )
                    })
                  : ""}
              </FormGroup>
            </Form>
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner size="lg" className="spinner" />
              </div>
            )}
            {error && <div className="mt-5 text-danger">{error}</div>}
          </Container>
        )
      case 2:
        return (
          <Container className="data_preview">
            <Form>
              <FormGroup>
                <Container>
                  <h6>Index name</h6>
                  <InputGroup xs={6}>
                    <Input
                      type="text"
                      name="indexName"
                      placeholder="Index name"
                      onChange={onChangeIndexName}
                      value={indexName}
                    />
                  </InputGroup>
                  {error && <Alert className="mt-3" color="danger">{error}</Alert>}
                  <h6 className="mt-4 pt-2 mb-2">Version</h6>
                  <InputGroup className="version">
                    <InputGroupText>v</InputGroupText>
                    <Input value="1.0" disabled />
                  </InputGroup>
                  <h6 className="mt-4">Index dataset</h6>
                  <InputGroup xs={6}>
                    <Input type="text" name="datasetName" value={currentDataset.label} disabled />
                  </InputGroup>
                </Container>
                {columnsData.length && columnsRole
                  ? columnsData.map((columnData, index) => {
                      if (columnsRole[columnData[0]] !== "ignore") {
                        return (
                          <Container key={index}>
                            <Row
                              key={columnData[0]}
                              className={
                                (columnsRole[columnData[0]] === "index"
                                  ? "index"
                                  : columnsRole[columnData[0]] === "metadata"
                                  ? "metadata"
                                  : "ignored") + " mt-4 mx-auto"
                              }
                              onClick={() => onChangeCollapse(index)}
                            >
                              <div className="order">{index}</div>
                              <div className="title pl-3 font-weight-bold">{columnData[0]}</div>
                              <div className="data_role">
                                {columnsRole[columnData[0]]}
                                <FontAwesomeIcon className="ml-4" icon={collapse === index ? faAngleUp : faAngleDown} />
                              </div>
                            </Row>
                            <Collapse isOpen={collapse === index}>
                              <Table className="index-table">
                                <tbody>
                                  {columnData.slice(1, 6).map((item, index) => (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Collapse>
                          </Container>
                        )
                      }
                    })
                  : ""}
              </FormGroup>
            </Form>
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner size="lg" className="spinner" />
              </div>
            )}
          </Container>
        )
      default:
        return "Unknown step"
    }
  }

  return (
    <Container className="mt-3">
      <div className={`mb-5 ${classes.root}`}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="index-details">
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.content}>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <div className={classes.content}>{getStepContent(activeStep)}</div>
              <div className="text-right">
                <Button className="back-btn" onClick={handleBack}>
                  {activeStep === 0 ? "Cancel" : "Back"}
                </Button>
                <Button
                  className="step-btn ml-3"
                  onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                  disabled={loading}
                >
                  {activeStep === steps.length - 1 ? "Run Index" : "Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
