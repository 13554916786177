import client from "./APIClient"

export default class Subscription {
  static get = stripe_subscription_id => {
    return client(`subscription?stripe_subscription_id=${stripe_subscription_id}`)
  }

  static update = body => {
    return client("subscription", {
      method: "PATCH",
      body
    })
  }

  static applyCoupon = body => {
    return client("apply-coupon", {
      method: "PATCH",
      body
    })
  }

  static activateAndApplyCoupon = body => {
    return client("apply-coupon/activate", {
      method: "PATCH",
      body
    })
  }

  static delete = body => {
    return client("subscription", {
      method: "DELETE",
      body
    })
  }
}
