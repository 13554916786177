import React from "react"
import PropTypes from "prop-types"
import TopMenu from "../TopMenu"
import "./themeAdmin.css"
import Footer from "../../Footer"

const ThemeAdmin = ({ children, backgroundColor = "#fff" }) => {
  return (
    <div
      className="d-flex flex-column justify-content-between dashboard_layout_container"
      style={{ backgroundColor: backgroundColor }}
    >
      <TopMenu />
      <div className="d-flex flex-column flex-fill">{children}</div>
      <Footer home={false} />
    </div>
  )
}

ThemeAdmin.propTypes = {
  children: PropTypes.node.isRequired
}

export default ThemeAdmin
