import client from "./APIClient"

export default class Users {
  static get = userID => {
    return client(`users?user_id=${userID}`)
  }

  static list = organizationId => {
    return client(`list_users?org_id=${organizationId}`)
  }

  static update = body => {
    return client("users", { method: "PUT", body })
  }

  static create = body => {
    return client("users", { method: "POST", body })
  }

  static async remove(body) {
    return client("user/remove", { method: "POST", body })
  }
}
