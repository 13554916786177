import React, { useState } from "react"
import Clipboard from "react-clipboard.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTerminal, faCopy } from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  UncontrolledTooltip,
  Spinner,
  Label,
  Input
} from "reactstrap"
import DatasetApi from "../../api/DatasetApi"
import "./dataset.css"

const CSVUploadModal = ({ toggle, modal, user }) => {
  const [CLIResponse, setCLIResponse] = useState(),
    [loading, setLoading] = useState(false),
    [error, setError] = useState()

  const isCsv = fileName => {
    return fileName.endsWith(".csv")
  }

  const onUploadCLIFile = async e => {
    e.preventDefault()

    if (e.target.files[0]) {
      const fileDataCLI = e.target.files[0]
      if (isCsv(fileDataCLI.name)) {
        const Data = {
          filesize: fileDataCLI.size,
          filename: fileDataCLI.name,
          org_id: user.org_id,
          email: user.email
        }
        setLoading(true)

        try {
          const res = await DatasetApi.presignedURL(Data)
          setCLIResponse(res)
          setLoading(false)
        } catch (e) {
          setError("There was a problem with the server.")
          setLoading(false)
        }
      } else {
        setError("Only csv file is accepted.")
      }
    }
  }
  const exportToJson = objectData => {
    let filename = "presigned.json"
    let contentType = "application/json;charset=utf-8;"
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType })
      navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      var a = document.createElement("a")
      a.download = filename
      a.href = "data:" + contentType + "," + encodeURIComponent(JSON.stringify(objectData))
      a.target = "_blank"
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  return (
    <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>CLI Upload</ModalHeader>
      <ModalBody>
        Please follow the following steps to upload your CSV file using the CLI tool:
        <br />
        <br />
        <p>
          1. Install the Traindex CLI into your desktop environment:
          <a href="https://pypi.org/project/traindexcli/">https://pypi.org/project/traindexcli/</a> (Skip this step if
          it&#39;s already installed)
        </p>
        <p className="mb-1">
          2. Make sure Traindex CLI is installed correctly by running the command
        </p>
        <p>
          <code> $ traindexcli --help</code>
          <Clipboard className="copy" data-clipboard-text="traindexcli --help">
            <FontAwesomeIcon icon={faCopy} />
          </Clipboard>
        </p>
        <p>3. Select the CSV file that you want to upload:</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button className="dataset-btn btn-sm">
            <Label className="m-0 p-0" for="CLIFile">
              <FontAwesomeIcon icon={faTerminal} /> CLI Upload
            </Label>
            <Input
              type="file"
              id="CLIFile"
              name="customCLIFile"
              style={{ display: "none" }}
              accept=".csv"
              onChange={onUploadCLIFile}
            />
          </Button>
        </p>
        {loading && (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Spinner size="lg" className="spinner" />
          </div>
        )}
        <p>4. Download the resulting JSON Response by clicking on the download button</p>
        {CLIResponse && (
          <>
            <Card className="json-res">
              <UncontrolledTooltip placement="left" target="tooltip">
                Download JSON
              </UncontrolledTooltip>
              <button className="download-btn" id="tooltip">
                <img
                  // eslint-disable-next-line max-len
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABI0lEQVRIie3Uuy5FQRQG4C8nCsVJXCudhsRLEAkRCRERpUvhPc5b0NJRKHREoiDBCzilTiNxCwlO5Cj2FJM57OwRHX+yivnX5V9r1mT4xy9jC5s5CV2ZAqOZ8Wq5CX9P4KsdjGEK3RG3h+skbhjL0fkVR2iWCS7gHe3IPrAS/CfBYDX44tg3zJcJNHGOvm/8sUCKflzgKibTHYwoxrwv6+Ib3OFQ8pRTgZpi1BgTGP+i4GTwxWinNau8ojUcYynilkK36xXyOzpoJFwdp2jhNlgrcPUktiG5gSoTPGMGZxgMdonZ4CtF1b/oBXPYD+fFKsVzBISC0xnx6LyiJ8V7/ikG8BgT6QQH2FAs6iaz+FDI3S0L6sVO6KKdaQ/YRk9mY38dn+esSAB7vqwcAAAAAElFTkSuQmCC"
                  onClick={() => exportToJson(CLIResponse)}
                  style={{ cursor: "pointer" }}
                />
              </button>

              <pre>{JSON.stringify(CLIResponse, null, 2)}</pre>
            </Card>
          </>
        )}
        {error && <div className="mt-5 text-danger">{error}</div>}
        <p>5. Move the JSON file into the directory where your CSV file exists.</p>
        <p className="mb-1">
          6. Open terminal/CMD in the directory where your CSV and JSON files exist and run the command
        </p>
        <p>
          <code> $ traindexcli</code>
          <Clipboard className="copy" data-clipboard-text={"traindexcli"}>
            <FontAwesomeIcon icon={faCopy} />
          </Clipboard>
        </p>
        <p>
          7. The progress bar will display the upload progress of the CSV file. Do not close the terminal/CMD until the
          upload will be completed. Once it&#39;s done, your CSV file will be populated in the dataset menu.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button className="finish-btn" disabled={!CLIResponse} onClick={toggle}>
          I completed all steps
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CSVUploadModal
