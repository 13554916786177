import React, { useState, useEffect } from "react"
import { Container } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactJoyride from "react-joyride"
import { Link } from "react-router-dom"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import IndexApi from "../../api/IndexAPI"
import { useStateValue } from "../../contexts/StateProvider"
import { convertedDate } from "../../utils/common"
import { Button } from "../Reactstrap_Components/Button"
import OrganizationModal from "../OrganizationModal"
import "./indexPage.css"
import TrialAlert from "../TrialAlert"
import PastDueAlert from "../PastDueAlert"
import { DataGrid } from "@mui/x-data-grid"

const IndexPage = () => {
  const { state, dispatch, getUserInfo } = useStateValue(),
    { user, userError, indexes, indexesError } = state,
    [modal, setModal] = useState(true),
    [pageSize, setPageSize] = useState(10),
    [loading, setLoading] = useState(false),
    isSignUp = new URLSearchParams(window.location.search).get("signup-success") === "1" ? true : false

  const steps = [
    {
      title: "View Patents index",
      target: ".dataset-table",
      content: (
        <div>
          <p>Click on Patents to view our built-in Patents index</p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      spotlightClicks: true,
    },
  ]

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      if (user && indexes.length === 0) {
        try {
          if (isSignUp) {
            dispatch({
              type: "SET_IS_SIGN_UP",
              isSignUp: true,
            })
            dispatch({
              type: "SET_DATASET_JOYRIDE",
              datasetJoyride: true,
            })
          }
          setLoading(true)
          const data = await IndexApi.list(user.org_id)
          dispatch({
            type: "SET_INDEXES",
            indexes: data.indexes,
          })
          setLoading(false)
        } catch (error) {
          dispatch({
            type: "SET_INDEXES_ERROR",
            error: error.message,
          })
          setLoading(false)
        }
      }
    })()
  }, [user])

  const builtinIndexes = [
    {
      item_id: "us_patents",
      name: "Patents",
      created_at: "2020-02-04",
      modified_at: "2021-08-20",
      author: "Admin",
      status: "Active",
    },
    {
      item_id: "wikipedia",
      name: "Wikipedia",
      created_at: "2020-3-11",
      modified_at: "2021-8-27",
      author: "Admin",
      status: "Active",
    },
    {
      item_id: "arxiv",
      name: "ArXiv.org",
      created_at: "2021-3-11",
      modified_at: "2021-8-24",
      author: "Admin",
      status: "Active",
    },
  ]

  const sortedIndexes = indexes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  const joinedIndexes = sortedIndexes.concat(builtinIndexes)
  const indexesForGrid = joinedIndexes.map(
    item => ({
      ...item, 
      id: item.item_id,
      created_at: convertedDate(item.created_at),
      modified_at: convertedDate(item.modified_at)
    })
  )

  const disableTour = () => {
    dispatch({
      type: "SET_INDEX_JOYRIDE",
      indexJoyride: false,
    })
  }

  const columns = [
    { 
      field: "name", 
      headerName: "Index Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={
            ["us_patents", "wikipedia", "arxiv"].includes(params.row.item_id)
              ? `/data-catalog/${params.row.item_id}`
              : `/indexes/${params.row.item_id}`
          }
          onClick={disableTour}
        >{params.value}</Link>
      )
    },
    { 
      field: "created_at", 
      headerName: "Creation Date", 
      flex: 1 },
    { 
      field: "modified_at", 
      headerName: "Last Updated", 
      flex: 1 },
    { 
      field: "status", 
      headerName: "Status", 
      flex: 1 },
    { 
      field: "author", 
      headerName: "Created by", 
      flex: 1 }
  ]

  return (
    <div style={{ height: "100px !important" }}>
      <ReactJoyride
        steps={steps}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep
        run={state.isSignUp && state.indexJoyride}
        styles={{
          options: {
            height: 0,
            primaryColor: "#4c2e74",
          },
        }}
        locale={{
          last: "End",
        }}
      />
      <Container className="index-page mt-5">
        {user && user.subscription && <TrialAlert trial_end={user.subscription.trial_end} />}
        {user && user.subscription && <PastDueAlert subscription={user.subscription} />}
        <Link className="mt-5 pt-3" to="/index/create">
          <Button className="ml-2 create-index">
            <FontAwesomeIcon icon={faPlusCircle} /> Create Index
          </Button>
        </Link>
        <div
          className="mt-3"
          style={{ 
            display: "flex", 
            width: "100%"
          }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rows={indexesForGrid || []}
              columns={columns}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              loading={loading}
            />
          </div>
        </div>
        {indexesError && <div className="mt-5 text-danger">{indexesError}</div>}
        {userError && userError === "no_organization" && 
          <OrganizationModal 
            user={user} 
            modal={modal}
            setModal={setModal}
            getUserInfo={getUserInfo}
          />}
      </Container>
    </div>
  )
}

export default IndexPage
