import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import Amplify from "aws-amplify"
import config from "./aws-exports"
import ClipboardJS from "clipboard"
import App from "./App"
import { QueryClientProvider } from "react-query"
import { queryClient } from "./utils/common"


Amplify.configure(config)
new ClipboardJS(".copy_btn")

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
  , document.getElementById("root"))
