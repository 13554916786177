import { QueryClient } from "react-query"

export const validatePassword = (email, password, fullName) => {
  let nameParts = fullName ? fullName.split(" ") : ""
  let lowerPassword = password.toLowerCase()
  for (let i = 0; i < nameParts.length; i++) {
    let namePart = nameParts[i].toLowerCase()
    if (lowerPassword.includes(namePart)) {
      return "name"
    }
  }
  if (lowerPassword.includes(email.toLowerCase())) {
    return "email"
  } else {
    return "ok"
  }
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const convertedDate = (date) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" }
  return new Date(date).toLocaleDateString(undefined, options)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: 5 * 60 * 1000
    }
  }
})
