/* eslint-disable indent */
import React, { useState, useEffect } from "react"
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Row,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
  UncontrolledTooltip,
  CardSubtitle,
  InputGroupText,
  Collapse,
} from "reactstrap"
import ApiSettings from "../../../api/ApiSettings"
import Indexes from "../../../api/IndexAPI"
import { useStateValue } from "../../../contexts/StateProvider"
import { Message } from "../../ExtraComponents/Message"
import Clipboard from "react-clipboard.js"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatBytes } from "../../../utils/common"
import {
  faPlusCircle,
  faCopy,
  faQuestionCircle,
  faEye,
  faEyeSlash,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons"
import UnpaidModal from "../../UnpaidModal"
import "./indexItem.css"

const IndexItem = ({ match }) => {
  const { state } = useStateValue(),
    [loading, setLoading] = useState({
      getAll: false,
      addMore: false,
      deleteKey: false,
    }),
    { user } = state,
    [visible, setVisible] = useState(null),
    [error, setError] = useState(""),
    [modal, setModal] = useState(false),
    [keyToDelete, setKeyToDelete] = useState(""),
    [collapse, setCollapse] = useState(null),
    [indexItem, setIndexItem] = useState(null)

  const {
    params: { index_id },
  } = match

  useEffect(() => {
    async function getIndex() {
      setLoading(true)
      const resp = await Indexes.get(index_id)
      setIndexItem(resp)
      setLoading(false)
    }
    if (user) {
      getIndex()
    }
  }, [user])

  const handleAddMore = async () => {
    try {
      if (indexItem.api_keys?.length === 10) return setError("Maximum 10 API keys are allowed")
      setLoading((prevLoading) => ({ ...prevLoading, addMore: true }))
      await ApiSettings.addKey({ org_id: user.organization.item_id, index: "google_patents" })
    } catch (error) {
      setError("There is something wrong")
    } finally {
      await ApiSettings.all(user.organization.item_id)
      setLoading((prevLoading) => ({ ...prevLoading, addMore: false }))
    }
  }

  const handleDeleteKey = async () => {
    setModal(!modal)
    try {
      if (indexItem.api_keys?.length === 1)
        return setError("Operation not allowed, please add another key before deleting this one")
      error && indexItem.api_keys?.length > 1 && setError("")
      setLoading((prevLoading) => ({ ...prevLoading, deleteKey: true }))
      await ApiSettings.removeKey({
        api_id: keyToDelete,
        index: indexItem?.name,
        org_id: user.organization.item_id,
      })
    } catch (error) {
      setError(error.message)
    } finally {
      await ApiSettings.all(user.organization.item_id)
      setLoading((prevLoading) => ({ ...prevLoading, deleteKey: false }))
    }
  }

  const onToggle = () => setError("")

  const toggleModal = () => setModal(!modal)

  const onChangeVisible = (id) => {
    if (id === visible) {
      setVisible(null)
    } else {
      setVisible(id)
    }
  }

  const onChangeCollapse = (index) => {
    if (index === collapse) {
      setCollapse(null)
    } else {
      setCollapse(index)
    }
  }

  const onDeleteKey = (apiId) => {
    toggleModal()
    setKeyToDelete(apiId)
  }

  const columnsData = (indexItem?.columns_data && JSON.parse(indexItem.columns_data)) || []

  return (
    <Container className={`${user && user.subscription._isExpired ? "blur-bg" : "index-details mb-5"}`}>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to="/index">Index</Link>
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="#">
          {indexItem?.name}
        </BreadcrumbItem>
      </Breadcrumb>
      <h5 className="mt-5 font-weight-bold">{indexItem?.name}</h5>
      <Card className="index_overview">
        <CardHeader>
          <Row className="header-main mx-1">
            <h5 className="mb-0 py-2 font-weight-bold">Index Overview</h5>
            <Button className="full-documentation-button">
              <Link to={`/docs/${indexItem?.name}`}>Full documentation</Link>
            </Button>
          </Row>
        </CardHeader>
        {!indexItem ? (
          <CardBody>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Spinner size="lg" className="spinner" />
            </div>
          </CardBody>
        ) : indexItem.status === "Active" ? (
          <CardBody>
            <CardSubtitle className="my-2">Endpoint</CardSubtitle>
            <InputGroup>
              <Input value={indexItem.api_endpoint} disabled />
            </InputGroup>
            <CardText className="mt-2">
              <Clipboard className="copy pl-0 ml-0" data-clipboard-text={indexItem.api_endpoint}>
                <FontAwesomeIcon icon={faCopy} /> copy to clipboard
              </Clipboard>
            </CardText>
            <CardSubtitle className="mt-4 pt-2 mb-2">Version</CardSubtitle>
            <InputGroup className="version">
              <InputGroupText>v</InputGroupText>
              <Input value="5.0" disabled />
            </InputGroup>
          </CardBody>
        ) : (
          <CardBody>
            <Alert color="primary">Your index is being processed. We will email you once it is ready.</Alert>
          </CardBody>
        )}
      </Card>
      <Card className="mt-5">
        <CardHeader>
          <Row className="mx-1">
            <h5 className="mb-0 py-2 font-weight-bold">
              Index Secret Keys
              <FontAwesomeIcon className="mt-2 ml-1" size="sm" icon={faQuestionCircle} id="secret-tooltip" />
            </h5>
          </Row>
          <UncontrolledTooltip placement="right" target="secret-tooltip">
            Your API keys are like your passwords: make sure to always keep them hidden! Share them only with services
            you trust.
          </UncontrolledTooltip>
        </CardHeader>
        {!indexItem ? (
          <CardBody>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <Spinner size="lg" className="spinner" />
            </div>
          </CardBody>
        ) : indexItem.api_endpoint ? (
          <>
            <CardBody>
              <Message header="Error" content={error} isOpen={error ? true : false} onToggle={onToggle} />
              <Table responsive>
                <thead>
                  <tr>
                    <th>API Key</th>
                    <th></th>
                    <th>Creation Date</th>
                  </tr>
                </thead>
                <tbody>
                  {indexItem.api_keys?.map((apiKey) => (
                    <tr key={apiKey.id}>
                      <td className="key-row">
                        {visible === apiKey.api_key
                          ? apiKey.api_key
                          : "***************************************************"}
                      </td>

                      <td>
                        <Clipboard className="copy_key mr-3" data-clipboard-text={apiKey.api_key}>
                          <FontAwesomeIcon icon={faCopy} />
                        </Clipboard>
                        <Button className="copy_key" onClick={() => onChangeVisible(apiKey.api_key)}>
                          <FontAwesomeIcon icon={visible === apiKey.api_key ? faEyeSlash : faEye} />
                        </Button>
                      </td>

                      <td>{apiKey.creation_date}</td>

                      <td style={{ width: "120px" }}>
                        {loading.deleteKey && keyToDelete === apiKey.id && (
                          <Spinner type="grow" className="align-self-center" />
                        )}
                        <p type="button" className="delete-key float-right" onClick={() => onDeleteKey(apiKey.id)}>
                          Delete
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                disabled={loading.addMore}
                color="link"
                onClick={() => handleAddMore()}
                className="add_more_container align-self-start"
              >
                <span className="add_more">
                  Add more secret keys <FontAwesomeIcon icon={faPlusCircle} />
                </span>
              </Button>
              {loading.addMore && <Spinner type="grow" className="align-self-center" />}
            </CardBody>
            <Modal className="confirm_delete" isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Delete API Key?</ModalHeader>
              <ModalBody>
                <p className="py-2">Are you sure you want to delete this API key?</p>
              </ModalBody>
              <ModalFooter>
                <Button className="delete_btn text-white shadow-none" onClick={() => handleDeleteKey()}>
                  Yes
                </Button>{" "}
                <Button className="text-white shadow-none" onClick={toggleModal}>
                  No
                </Button>
              </ModalFooter>
            </Modal>
          </>
        ) : (
          <CardBody></CardBody>
        )}
      </Card>
      <Card className="mt-5">
        <CardHeader>
          <Row className="mx-1">
            <h5 className="mb-0 py-2 font-weight-bold">Index Data</h5>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="mt-3 ml-1 px-1">
            <thead>
              <tr className="font-avenir-bold">
                <th>Dataset Name</th>
                <th>Size</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{indexItem?.name}</td>
                <td>{indexItem?.dataset_size && formatBytes(indexItem?.dataset_size)}</td>
              </tr>
            </tbody>
          </Table>
          <Container className="data_preview">
            <CardSubtitle className="mt-4 pt-2">Preview</CardSubtitle>
            {columnsData.length && indexItem?.columns_role
              ? columnsData.map((columnData, index) => {
                  return (
                    <Container key={index}>
                      <Row
                        key={columnData[0]}
                        className={
                          (indexItem?.columns_role[columnData[0]] === "index"
                            ? "index"
                            : indexItem?.columns_role[columnData[0]] === "metadata"
                            ? "metadata"
                            : "ignored") + " mt-4 mx-auto"
                        }
                        onClick={() => onChangeCollapse(index)}
                      >
                        <div className="order">{index}</div>
                        <div className="title pl-3 font-weight-bold">{columnData[0]}</div>
                        <div className="data_role">
                          {indexItem?.columns_role[columnData[0]]}
                          <FontAwesomeIcon
                            className="ml-4"
                            icon={collapse === index ? faAngleUp : faAngleDown}
                            onClick={() => onChangeCollapse(index)}
                          />
                        </div>
                      </Row>
                      <Collapse isOpen={collapse === index}>
                        <Table className="index-table">
                          <tbody>
                            {columnData.slice(1, 6).map((item, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Collapse>
                    </Container>
                  )
                })
              : ""}
          </Container>
        </CardBody>
      </Card>
      {user && user.subscription._isExpired && <UnpaidModal modal={true} />}
    </Container>
  )
}

export default IndexItem
