import React, { useState, useEffect } from "react"
import { Col, Row } from "reactstrap"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import imageSliderBG from "../../images/image-slider-bg.png"
import imageSlider1 from "../../images/image-slider-1.png"
import imageSlider2 from "../../images/image-slider-2.png"
import "./landingPage.scss"


const ImageComponent = ({ src }) => (
  <img src={src} alt="Traindex APIs" className="w-100 slider-img mt-n2 pr-5 position-absolute" />
)

const sliderImages = [
  {
    uid: 0,
    image: <ImageComponent src={imageSlider1} />,
  },
  {
    uid: 1,
    image: <ImageComponent src={imageSlider2} />,
  },
]

const BuiltInMind = () => {
  const [activeSliderImage, setActiveSliderImage] = useState(sliderImages[0])

  const onChangeSlide = (uid) => {
    const sliderImage = sliderImages.find((item) => item.uid === uid)
    setActiveSliderImage(sliderImage)
  }

  const cssTransitionAddEndListener = (node, done) => node.addEventListener("transitionend", done, false)

  useEffect(() => {
    const interval = setInterval(() => setActiveSliderImage((img) => sliderImages[Number(!img.uid)]), 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])


  return (
    <Row
      className={`mt-3 pt-3 mt-md-5 pt-md-5 mb-2 pb-2 mb-md-5 pb-md-5 align-items-center flex-column-reverse 
    flex-md-row`}
    >
      <Col xs="12" md="6" className="pt-4 pt-md-0 d-flex flex-column align-items-center justify-content-center">
        <img src={imageSliderBG} alt="slider background image" className="w-100 ml-n3 slider-img" />
        <SwitchTransition>
          <CSSTransition
            key={activeSliderImage.uid}
            addEndListener={cssTransitionAddEndListener}
            classNames="fade"
          >
            {activeSliderImage.image}
          </CSSTransition>
        </SwitchTransition>

        <ol className="carousel carousel-indicators position-relative justify-content-center pt-4">
          {sliderImages.map((sliderImage) => (
            <li
              onClick={() => onChangeSlide(sliderImage.uid)}
              key={sliderImage.uid}
              className={activeSliderImage.uid === sliderImage.uid ? "active" : ""}
            ></li>
          ))}
        </ol>
      </Col>
      <Col xs="12" md="6">
        <h2 className="font-weight-bold font-avenir-bold text-secondary">Built with engineers in mind</h2>
        <p className="lead text-left pt-2">
          We built fast APIs that development teams can integrate right away. Our extensive documentation, and
          24/7 support gives you a delightful developer experience.
        </p>
      </Col>
    </Row>
  )
}

export default BuiltInMind
