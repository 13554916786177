import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button, Input, InputGroup } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEnvelope, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import logo from "../../images/trindex-final-logo-white.png"
import "./footer.css"
import LinkedIn from "../../images/LinkedIn.png"
import Instagram from "../../images/Instagram.png"
import Twitter from "../../images/Twitter.png"

const emailRegex = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ /* eslint-disable-line */
)

const Footer = ({ home }) => {
  const [success, setSuccess] = useState("")
  const CustomForm = ({ status, onValidated }) => {
    status === "success" && setSuccess(true)
    status === "error" && setSuccess(false)
    let email
    const submit = () =>
      email &&
      emailRegex.test(email.value) &&
      onValidated({
        EMAIL: email.value,
        "group[166513][2]": "2",
      })

    return (
      <InputGroup className="input-group">
        <Input
          className="landing-page-form-input mb-2 mr-2"
          innerRef={(node) => (email = node)}
          type="email"
          placeholder="Email"
        />
        <Button color="danger" className="landing-page-send-button mr-2" onClick={submit}>
          <FontAwesomeIcon icon={faEnvelope} color="white" className="mr-2" />
          Join
        </Button>
        {success === true && (
          <div className="successMessage">
            <FontAwesomeIcon icon={faCheck} className="mr-1" />
            Please check your inbox to confirm your subscription.
          </div>
        )}
        {success === false && (
          <div className="errorMessage">
            <FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> You are already subscribed to the newsletter.
          </div>
        )}
      </InputGroup>
    )
  }
  return (
    <>
      {home && (
        <div className="footer-container">
          <ul className="mt-3 first-coloumn">
            <Link to="/">
              <img src={logo} alt="Foretheta" className="traindex-logo" />
            </Link>
            <p className="mt-3 description">
              AI-powered search without hiring a data science team. Search what they mean, not what they say. Search
              with an understanding of meaning and not just the literal keywords.
            </p>
            <a
              href="https://www.linkedin.com/company/traindex-io/"
              className="mr-2 social-media-icons"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedIn} className="social-media-icons" alt="Traindex LinkedIn" />
            </a>
            <a
              href="https://twitter.com/traindexio"
              className="mr-2 social-media-icons"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Twitter} className="social-media-icons" alt="Traindex Twitter" />
            </a>
            <a
              href="https://www.instagram.com/traindex.io/"
              className="mr-2 social-media-icons"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} className="social-media-icons" alt="Traindex Instagram" />
            </a>
          </ul>
          <ul className="mt-4">
            <h4 className="mb-3 font-weight-bold">Product</h4>
            <li>
              <a href="/#why-traindex">Why Traindex?</a>
            </li>
            <li>
              <a href="/#prior-art-search">Free Prior Art Search</a>
            </li>
            <li>
              <a href="/#semantic-search">Semantic Search</a>
            </li>
            <li>
              <a href="/#done-for-you-service">Features</a>
            </li>
            <li className="d-none">
              <Link to="#">Pricing</Link>
            </li>
          </ul>
          <ul className="mt-4">
            <h4 className="mb-3 font-weight-bold">Company</h4>
            <li className="d-none">
              <Link to="#">About Us</Link>
            </li>
            <li>
              <a href="mailto:help@traindex.io">Contact Us</a>
            </li>
            <li>
              <a href="/blog" className="text-white">
                Blog
              </a>
            </li>
            <li>
              <Link to="/privacy/">Privacy</Link>
            </li>
            <li>
              <Link to="/terms/">Terms</Link>
            </li>
          </ul>
          <ul className="mt-4">
            <h4 className="mb-3 font-weight-bold text-lg-left">Sign up for the newsletter</h4>
            <p className="font-weight-bold mr-1">We&apos;ll inform you of updates, offers and more</p>
            <MailchimpSubscribe
              url={process.env.REACT_APP_MAILCHIMP_URL}
              render={({ subscribe, status, message }) => (
                <CustomForm status={status} message={message} onValidated={(formData) => subscribe(formData)} />
              )}
            />
          </ul>
        </div>
      )}
      <div className="footer-bottom">
        <p>Bellevue, WA 98008 © {new Date().getFullYear()} Traindex. All rights reserved</p>
      </div>
    </>
  )
}

export default Footer
