import React from "react"
import Header from "./Header"
import PrivacyText from "./Privacy/PrivacyText"
import Footer from "./Footer"
import "../index.css"

const Privacy = () => {
  return (
    <div>
      <Header />
      <PrivacyText />
      <Footer />
    </div>
  )
}

export default Privacy
