import React, { useState } from "react"
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
  Button
} from "reactstrap"
import { Link } from "react-router-dom"
import { Auth } from "aws-amplify"
import traindexLogo from "../../images/traindex-logo.png"
import "./resetPassword.css"

const renderSuccessMessage = () => (
  <Alert color="success">
    <h4 className="alert-heading">Success!</h4>
    <p>Your password has been reset.</p>
    <hr />
    <p>
      <Link to="/sign-in">Click here to sign in with your new credentials.</Link>
    </p>
  </Alert>
)

const ResetPassword = () => {
  const [formData, setFormData] = useState({
      code: "",
      email: "",
      password: "",
      confirmPassword: ""
    }),
    [error, setError] = useState(""),
    [codeSent, setCodeSent] = useState(false),
    [isSendingCode, setIsSendingCode] = useState(false),
    [confirmed, setConfirmed] = useState(false),
    [isConfirming, setIsConfirming] = useState(false)

  const handleSendCodeClick = async event => {
    event.preventDefault()

    try {
      error && setError("")
      setIsSendingCode(true)
      await Auth.forgotPassword(formData.email)
      setIsSendingCode(false)
      setCodeSent(true)
    } catch (error) {
      setError(error.message)
      setIsSendingCode(false)
    }
  }

  const handleConfirmClick = async event => {
    event.preventDefault()

    try {
      if (formData.code.length < 6) {
        throw Error("Invalid confirmation code!")
      }
      if (formData.password.length < 10) {
        throw Error("Password must have at least 10 characters!")
      }
      if (formData.password !== formData.confirmPassword) {
        throw Error("Password and Confirm Password does not match!")
      }
      error && setError("")
      setIsConfirming(true)
      await Auth.forgotPasswordSubmit(formData.email, formData.code, formData.password)
      setIsConfirming(false)
      setConfirmed(true)
    } catch (error) {
      setError(error.message)
      setIsConfirming(false)
    }
  }

  const onChangeHandler = event => {
    const { name, value } = event.target
    return setFormData({ ...formData, [name]: value })
  }

  const renderRequestCodeForm = () => (
    <Form onSubmit={handleSendCodeClick} className="d-flex flex-column">
      <FormGroup>
        <Label for="email" className="font-weight-bold input-label text-dark">
          EMAIL <span>*</span>
        </Label>
        <Input
          type="email"
          name="email"
          id="email"
          className="rounded text-secondary"
          placeholder="e.g. example@domain.com"
          onChange={onChangeHandler}
          value={formData.email}
          required
        />
      </FormGroup>
      <Button
        type="submit"
        disabled={isSendingCode}
        className="mt-4 p-2 font-weight-bold text-white rounded align-self-center confirm-btn"
        block
      >
        SEND COMFIRMATION
      </Button>
    </Form>
  )

  const renderConfirmationForm = () => (
    <Form onSubmit={handleConfirmClick} className="d-flex flex-column">
      <FormGroup>
        <Label for="code" className="font-weight-bold input-label text-dark">
          CODE <span>*</span>
        </Label>
        <Input
          type="text"
          name="code"
          id="code"
          className="rounded text-secondary"
          placeholder="e.g. 123456"
          onChange={onChangeHandler}
          value={formData.code}
          required
        />
        <FormText color="muted">Please check your email for the confirmation code.</FormText>
      </FormGroup>
      <FormGroup>
        <Label for="password" className="font-weight-bold input-label text-dark">
          NEW PASSWORD <span>*</span>
        </Label>
        <Input
          type="password"
          name="password"
          id="password"
          className="rounded text-secondary"
          onChange={onChangeHandler}
          minLength="10"
          required
        />
        <FormText>Must have at least 10 characters</FormText>
      </FormGroup>
      <FormGroup>
        <Label for="confirmPassword" className="font-weight-bold input-label text-dark">
          CONFIRM PASSWORD <span>*</span>
        </Label>
        <Input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          className="rounded text-secondary"
          onChange={onChangeHandler}
          minLength="10"
          required
        />
      </FormGroup>
      <Button type="submit" disabled={isConfirming} className="mt-4 p-2 text-white rounded confirm-btn" block>
        CONFIRM
      </Button>
    </Form>
  )

  return (
    <div className="d-flex justify-content-center align-items-center full_height purple_background">
      <Card className="card-container p-2 my-3">
        <Link to="/" className="align-self-center mt-2 mb-3">
          <CardImg className="card_img" top src={traindexLogo} alt="Traindex logo" />
        </Link>
        <CardTitle className="card_title font-weight-bold align-self-center mt-2">
          RESET PASSWORD
        </CardTitle>
        {error ? <CardSubtitle className="align-self-center mt-2 text-danger">{error}</CardSubtitle> : null}
        <CardBody className="card_body align-self-center mx-5">
          {!codeSent ? renderRequestCodeForm() : !confirmed ? renderConfirmationForm() : renderSuccessMessage()}
        </CardBody>
      </Card>
    </div>
  )
}

export default ResetPassword
