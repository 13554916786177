import client from "./APIClient"

export default class DatasetApi {
  static fetch = () => {
    return client("datasets")
  }

  static fetchFiles = key => {
    return client(`datasets/files?key=${key}`)
  }

  static create = name => {
    return client("datasets", { method: "POST", body: { dataset_name: name } })
  }

  static signed_policy = data => {
    return client("datasets/csv", { method: "POST", body: data })
  }

  static presignedURL = data => {
    return client("cli-presigned", { method: "POST", body: data })
  }

  static remove = async ids => {
    await client(`datasets?ids=${ids}`, { method: "DELETE" })
    return this.fetch()
  }

  static removeFile = key => {
    return client(`datasets/files?key=${key}`, { method: "DELETE" })
  }
}
