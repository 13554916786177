import React, { useState, useEffect } from "react"
import { useStateValue } from "../../contexts/StateProvider"
import OrganizationsAPI from "../../api/OrganizationsAPI"
import { Container, Alert } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import { DataGrid } from "@mui/x-data-grid"
import Switch from "@mui/material/Switch"
import SideBar from "../SideBar"
import "./pausePayment.scss"

export default function PausePayment() {
  const { state, dispatch } = useStateValue(),
    { user, orgs } = state,
    [disabledItem, setDisabledItem] = useState(),
    [loading, setLoading] = useState(false),
    [pageSize, setPageSize] = useState(5),
    [success, setSuccess] = useState(""),
    [error, setError] = useState(""),
    history = useHistory()

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      if (user) {
        if (user.foretheta_admin) {
          try {
            setLoading(true)
            const data = await OrganizationsAPI.list(user.item_id)
            dispatch({
              type: "SET_ORGS",
              orgs: data,
            })
            setLoading(false)
          } catch (error) {
            dispatch({
              type: "SET_ORGS_ERROR",
              error: error.message,
            })
            setLoading(false)
          }
        } else {
          history.push("/index")
        }
      }
    })()
  }, [user])

  function findPath() {
    const location = useLocation()
  
    return location.pathname
  }

  let path = findPath()

  const handleSwitch = async (id, subscription_id, paused_payment) => {
    setDisabledItem(id)
    try {
      const params = {
        auth_user_id: user.item_id,
        org_id: id,
        subscription_id: subscription_id,
        paused_payment: !paused_payment
      }
      setLoading(true)
      const res = await OrganizationsAPI.update(params)
      if (res["id"] === subscription_id) {
        if (user.foretheta_admin) {
          try {
            setLoading(true)
            const data = await OrganizationsAPI.list(user.item_id)
            dispatch({
              type: "SET_ORGS",
              orgs: data,
            })
            setLoading(false)
          } catch (error) {
            dispatch({
              type: "SET_ORGS_ERROR",
              error: error.message,
            })
            setLoading(false)
          }
        } else {
          history.push("/index")
        }
      }
      setSuccess("Organization updated successfully!")
      setLoading(false)
      setDisabledItem()
      setTimeout(() => {
        setSuccess("")
      }, 3000)
    } catch (error) {
      if (error.message == "Unauthorized request!") {
        setError("You are not allowed to modify the organization!")
        setTimeout(() => {
          setError("")
        }, 3000)
      } else {
        setError(error.message)
        setTimeout(() => {
          setError("")
        }, 3000)
      }
      setLoading(false)
      setDisabledItem()
    }
  }

  const label = { inputProps: { "aria-label": "Switch demo" } }

  const columns = [
    { field: "id", headerName: "ID", width: 300 },
    { field: "name", headerName: "Name", width: 300 },
    { field: "subscription_id", headerName: "Subscription ID", width: 250 },
    { 
      field: "paused_payment", 
      headerName: "Paused Payment", 
      width: 150,
      renderCell: (params) => {
        return (
          <Switch 
            {...label}
            disabled={params.row.id === disabledItem}
            checked={params.row.paused_payment !== false}
            onClick={() => handleSwitch(
              params.row.id, 
              params.row.subscription_id,
              params.row.paused_payment
            )}
          />
        )
      },
    },
  ]

  return (
    <Container className="payment-container mt-3">
      <SideBar path={path} />
      <div className="payment ml-4">
        <h3>Pause Payment Collection (via Stripe)</h3>
        {success ? <Alert color="success">{success}</Alert> : (error ? 
          <Alert color="danger">{error}</Alert> : <div className="empty_div"></div>)}
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rows={orgs || []}
            columns={columns}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            loading={loading}
          />
        </div>
      </div>
    </Container>
  )
}
