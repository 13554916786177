import client from "./APIClient"

export default class PriorArtApi {
  static getData = (ucid) => {
    return client(`prior-art?ucid=${ucid}`)
  }

  static sendEmail = (body) => {
    return client("prior-art", { method: "POST", body })
  }

  static exists = (ucid) => {
    return client(`prior-art/exists?ucid=${ucid}`)
  }
}
