import React from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

const Start15DayFreeTrialButton = ({ className }) => {
  return (
    <Button
      tag={Link}
      to="/sign-up"
      className={`text-white px-3 py-2 rounded-pill font-weight-bold ${className}`}
      color="secondary"
    >
      Start your free trial
    </Button>
  )
}

export default Start15DayFreeTrialButton
