import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import "./App.scss"
import LandingPage from "./components/LandingPage"
import Dataset from "./components/Dataset"
import Terms from "./components/Terms"
import Privacy from "./components/Privacy"
import SignIn from "./components/SignIn"
import SignUp from "./components/SignUp"
import { PublicRoute } from "./utils/PublicRoute"
import { PrivateRoute } from "./utils/PrivateRoute"
import { PublicPrivateRoute } from "./utils/PublicPrivateRoute"
import Profile from "./components/Profile"
import Settings from "./components/Settings"
import IndexPage from "./components/IndexPage"
import AddIndex from "./components/IndexPage/AddIndex"
import GA from "./utils//GoogleAnalytics"
import ResetPassword from "./components/ResetPassword"
import DemoAPI from "./components/DemoAPI"
import Chatbot from "./components/Chatbot"
import PriorArt from "./components/PriorArt"
import DownloadPriorArt from "./components/PriorArt/download"
import Subscription from "./components/Subscription"
import { StateProvider } from "./contexts/StateProvider"
import ApiDocumentation from "./components/ApiDocs"
import USPatentsIndex from "./components/IndexPage/IndexItem/USPatentsIndex"
import WikipediaIndex from "./components/IndexPage/IndexItem/WikipediaIndex"
import ArXivIndex from "./components/IndexPage/IndexItem/ArXivIndex"
import IndexItem from "./components/IndexPage/IndexItem/IndexItem"
import USPatentsSwaggerAPI from "./components/Swagger/USPatentsAPI"
import WikiSwaggerAPI from "./components/Swagger/WikiAPI"
import ArxivSwaggerAPI from "./components/Swagger/ArxivAPI"
import EmailReminders from "./components/EmailReminders"
import PausePayment from "./components/PausePayment"

const App = () => {
  return (
    <Router>
      {GA.init() && <GA.RouteTracker />}
      <Switch>
        <PublicRoute exact path="/" component={LandingPage} />
        <PublicRoute path="/sign-in" component={SignIn} />
        <PublicRoute path="/sign-up" component={SignUp} />
        <PublicRoute path="/reset-password" component={ResetPassword} />
        <PublicRoute path="/prior-art/:ucid" component={PriorArt} />
        <PublicRoute path="/download-prior-art/:ucid" component={DownloadPriorArt} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path={process.env.PUBLIC_URL + "/blog"} />
        <StateProvider>
          <PrivateRoute path="/swagger/us_patents" component={USPatentsSwaggerAPI} />
          <PrivateRoute path="/swagger/wikipedia" component={WikiSwaggerAPI} />
          <PrivateRoute path="/swagger/arxiv" component={ArxivSwaggerAPI} />
          <PrivateRoute path="/docs/:index_name" component={ApiDocumentation} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PublicPrivateRoute exact path="/chatbot" component={Chatbot} />
          <Elements stripe={loadStripe(process.env.REACT_APP_TRAINDEX_STRIPE_PKEY)}>
            <PrivateRoute exact path="/subscription" component={Subscription} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <PrivateRoute exact path="/admin" component={EmailReminders} />
            <PrivateRoute path="/admin/reminders" component={EmailReminders} />
            <PrivateRoute path="/admin/pause_payment" component={PausePayment} />
            <PrivateRoute exact path="/index" component={IndexPage} />
            <PrivateRoute path="/index/create" component={AddIndex} />
            <PrivateRoute path="/data-catalog/us_patents" component={USPatentsIndex} />
            <PrivateRoute path="/data-catalog/wikipedia" component={WikipediaIndex} />
            <PrivateRoute path="/data-catalog/arxiv" component={ArXivIndex} />
            <PrivateRoute path="/indexes/:index_id" component={IndexItem} />
            <PrivateRoute exact path="/search" component={DemoAPI} />
            <PrivateRoute exact path="/dataset" component={Dataset} />
          </Elements>
        </StateProvider>
        <Route path="" component={() => <div>404 not found!</div>} />
      </Switch>
    </Router>
  )
}

export default App
