import { Auth } from "aws-amplify"
import "unfetch/polyfill"

export const headers = { "content-type": "application/json" }
const client = async (endpoint, { method = "GET", body, ...customConfig } = {}) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const idToken = user.signInUserSession.idToken.jwtToken
    /* istanbul ignore else */
    if (idToken) {
      headers.Authorization = `Bearer ${idToken}`
    }
  } catch (error) {
    headers.Authorization = undefined
  }
  const config = {
    method: method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  /* istanbul ignore else */
  if (body) {
    config.body = JSON.stringify(body)
  }

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then(async (response) => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      if (data && "Code" in data && "Message" in data) return Promise.reject({ Code: data.Code, message: data.Message })
      return Promise.reject(data)
    }
  })
}

export default client
