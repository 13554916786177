import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { Button, Form, FormGroup, Input, Label, UncontrolledAlert, Spinner } from "reactstrap"
import "./updatePassword.css"

const UpdatePassword = () => {
  const [formData, setFormData] = useState({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    }),
    [isSuccess, setIsSuccess] = useState(false),
    [loading, setLoading] = useState(false),
    [errorMessage, setErrorMessage] = useState(""),
    { currentPassword, newPassword, confirmPassword } = formData

  const onChangeHandler = (event) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const validateForm = () => {
    if (newPassword.length < 10) {
      return "Password must have at least 10 characters!"
    } else if (newPassword !== confirmPassword) {
      return "Password and Confirm Password does not match!"
    } else {
      return false
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const errorMessage = validateForm()
    if (errorMessage) {
      setErrorMessage(errorMessage)
    } else {
      try {
        errorMessage && setErrorMessage("")
        setLoading(true)
        const user = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(user, currentPassword, newPassword)
        setIsSuccess(true)
        clearForm()
        setErrorMessage("")
        setLoading(false)
        setTimeout(() => {
          setIsSuccess(false)
        }, 3000)
      } catch (error) {
        setLoading(false)
        setErrorMessage(error.name === "LimitExceededException" ? 
          "Attempt limit exceeded, please try again later!" : "Your current password is incorrect!"
        )
      }
    }
  }

  const clearForm = () => {
    setFormData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    })
  }

  return (
    <div className="mt-4 py-2 font-avenir update-password-container">
      <h3 className="mb-3 font-avenir-bold">Update Password</h3>
      <Form className="col-md-6 p-0" onSubmit={onSubmit}>
        {isSuccess && (
          <UncontrolledAlert color="success">Password has been updated.</UncontrolledAlert>
        )}
        {errorMessage && <UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert>}
        {loading && (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner size="lg" className="spinner" />
          </div>
        )}
        <FormGroup>
          <Label className="font-weight-bold">Current password</Label>
          <Input type="password" name="currentPassword" value={currentPassword} onChange={onChangeHandler} required />
        </FormGroup>

        <FormGroup>
          <Label className="font-weight-bold">New password</Label>
          <Input type="password" name="newPassword" value={newPassword} onChange={onChangeHandler} required />
        </FormGroup>

        <FormGroup>
          <Label className="font-weight-bold">Confirm password</Label>
          <Input type="password" name="confirmPassword" value={confirmPassword} onChange={onChangeHandler} required />
        </FormGroup>
        <Button type="submit" color="primary" size="md" className="mb-5 text-white update-password-btn">
          Update Password
        </Button>
      </Form>
    </div>
  )
}

export default UpdatePassword
